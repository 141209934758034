import React, {useEffect, useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import {GetAllUnitsCoursesQueryResult } from '../../generated/graphql';
import { useDeleteUnitCourseMutation } from '../../generated/graphql';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


type UnitsCourseMatchTableProps = {
  onUpdate: () => void;
  Query: GetAllUnitsCoursesQueryResult;
}
const UnitsCourseMatchTable = ({onUpdate, Query}:UnitsCourseMatchTableProps) => {
    const allUnitsCourses = Query;
    const [deleteUnitCourse, setDeleteUnitCourse] = useState(['','']);
    const [deleteUnitCourseMutation,] = useDeleteUnitCourseMutation();

    useEffect(() => {
        if (deleteUnitCourse[0] !== '' && deleteUnitCourse[1] !== ''){
            try{
                deleteUnitCourseMutation({variables: {unit: deleteUnitCourse[0], course: deleteUnitCourse[1]}}).then((res)=>{
                    onUpdate();
                    toast.success('Unit "'+deleteUnitCourse+'" deleted')})
            } catch (e) {
                console.log('error', e)
                toast.error('Error deleting unit "'+deleteUnitCourse+'"')
            }

            setDeleteUnitCourse(['','']);
        }
    }, [deleteUnitCourse, deleteUnitCourseMutation ])

  return (
    <Accordion>
        <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
            <span className="text-base font-bold">Units/Courses match({allUnitsCourses.data?.units_courses_aggregate?.aggregate?.count||'...'})</span>
        </AccordionSummary>
        <AccordionDetails>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Unit</TableCell>
                        <TableCell>Course</TableCell>
                        <TableCell>Matching quality</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allUnitsCourses.data?.units_courses?.map((v,i) => (
                        <TableRow key={i}>
                            <TableCell>{v.unit}</TableCell>
                            <TableCell>{v.course}</TableCell>
                            <TableCell>{v.matching_quality.toFixed(3)}</TableCell>
                            <TableCell>
                                <Button 
                                variant='naked' 
                                onClick={()=>setDeleteUnitCourse([v.unit, v.course])} startIcon={<DeleteIcon/>} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </AccordionDetails>
    </Accordion>
)
}

export default UnitsCourseMatchTable