import styles from '../../styles/components/SignUp.module.css';
import MicrosoftIcon from '@mui/icons-material/Microsoft';

import { FormEvent, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Input from '../Input';
import { useNhostClient, useSignUpEmailPassword } from '@nhost/react';

import ClipLoader from 'react-spinners/ClipLoader';
// import { APP_TITLE, license_terms_url, privacyPolicyURL } from 'globalVariables';
import React from 'react';
import { SingleColumnContainer } from 'components/Containers';
import Verify from './Verify';
import { FullWidthButton } from 'styles/styled';
import GoogleIcon from '@mui/icons-material/Google';
import Button from '@mui/material/Button';
import { Checkbox } from '@mui/material';
import { license_terms_url, privacyPolicyURL } from 'globalVariables';
const SignUp = () => {
  const nhost = useNhostClient();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {
    signUpEmailPassword,
    isLoading,
    isSuccess,
    needsEmailVerification,
    isError,
    error,
  } = useSignUpEmailPassword();
  const disableForm = isLoading || needsEmailVerification;
  const [acceptLicenceTerms, setAcceptLicenceTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const handleEmailPwdSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (acceptLicenceTerms){
      setTermsError(false);
      signUpEmailPassword(email, password);
    }
    else {
      setTermsError(true);
    }
  };
  const handleGoogleSSO = () => {
    if (acceptLicenceTerms ) {
      setTermsError(false);
      nhost.auth.signIn({provider: 'google'});
    } else {
      setTermsError(true);
    }
  }
  const handleMicrosoftSSO = () => {
    if (acceptLicenceTerms) {
      setTermsError(false);
      nhost.auth.signIn({provider: 'windowslive', });

    } else {
      setTermsError(true);
    }
  }

  if (isSuccess) {
    return <Navigate to="/" replace={true} />;
  }

  return needsEmailVerification ? <Verify/>:(
    <SingleColumnContainer title="Sign Up" descriptions={['']}>
       <p className="text-custom-black text-center -mt-9 mb-5">
        Already have an account?{' '}
        <Link to="/sign-in" className="font-semibold hover:text-brand text-center">
          {isLoading ? <ClipLoader /> : 'Log in'}
        </Link>
      </p>
      {isError ? (
              <p className="text-warning text-base text-center self-stretch">{error?.message}</p>
              ) : null}
      {termsError ? (
            <p className="text-warning text-base text-center self-stretch">Please check the box below if you agree to proceed.</p>
            ) : null}
      <div className='w-[450px] flex flex-col gap-10'>
        {/* Email and password */}
        <form onSubmit={handleEmailPwdSubmit} className='flex flex-col w-full gap-5'>
          <Input
            type="email"
            label="Email"
            placeholder="Email address*"
            value={email}
            onChange={(e: FormEvent<HTMLFormElement>) =>
              setEmail((e.target as HTMLTextAreaElement).value)
            }
            required
            disabled={disableForm}
            />
          <Input
            type="password"
            label="Password"
            placeholder="Create Password*"
            value={password}
            onChange={(e: FormEvent<HTMLFormElement>) =>
              setPassword((e.target as HTMLTextAreaElement).value)
            }
            required
            disabled={disableForm}
            />
          <FullWidthButton
          variant='contained'
            type="submit"
            disabled={disableForm}
            >
            {isLoading ? <ClipLoader /> : 'I agree and Sign Up'}
          </FullWidthButton>
          {/* Terms and conditions */}
          <div className={('flex flex-col gap-2 p-2 border-warning'+(termsError?' border ':''))}>
          <div className="w-full flex flex-row justify-start items-start text-left gap-2.5">
            <input type='checkbox' className={("mt-1 "+(termsError?" appearance-none border-2 rounded-sm border-warning w-4 h-4 checked:bg-blue-800 checked:border-0 relative peer shrink-0 focus:outline-none focus:ring-offset-0 focus:ring-2 focus:ring-blue-100 disabled:border-steel-400 disabled:bg-steel-400":" appearance-default"))} 
            onChange={(e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; })=>setAcceptLicenceTerms(e.target.checked)} />
            {/* check icon if user forgot to check */}
            {termsError?
            <svg
              className="
                absolute 
                w-4 h-4 mt-1
                hidden peer-checked:block pointer-events-none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
             :<></>}
            <span>Your use of the software and services is subject to the <a className="font-semibold hover:text-brand text-center" href={license_terms_url}> licence terms </a>.</span>
          </div>
          {/* <div className="flex flex-row justify-start items-start text-left gap-2.5"> */}
            <span>By clicking on the "I agree and Sign Up" button above you are agreeing that your use of the Software and Services will be on the terms set out in the licence. 
                  Personal data you provide in completing this form will be used in accordance with Privacy Notice. 
                  <a className="font-semibold hover:text-brand text-center" href={privacyPolicyURL}> Privacy and Cookies</a>
            </span>
          {/* </div> */}

          </div>
        {/* SSO */}
        <p className="text-base font-medium text-center pt-5">Or sign up with</p>
        <FullWidthButton variant='outlined' startIcon={<GoogleIcon/>} onClick={handleGoogleSSO}>
            Continue with Google 
        </FullWidthButton>
        <FullWidthButton variant='outlined' startIcon={<MicrosoftIcon/>} onClick={handleMicrosoftSSO}>
            Continue with Microsoft
        </FullWidthButton>
        </form>

   
    </div>
      
    </SingleColumnContainer>
  );
};

export default SignUp;
