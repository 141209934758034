import { atom } from "recoil";
import type { AnimationOptions } from 'mapbox-gl';
import { GetUserQuery } from "generated/graphql";
import {
  Viewport,
  ViewportChangeMethod,
} from '@urbica/react-map-gl/src/components/MapGL';
const queryOptions = ['create course', 'find course', 'compare course']
export type QueryOption = {name: typeof queryOptions[number], label: string, description: String, queryMethod: 'Search'|'Select', resultType: 'UnitDisplay'|'UnitCompare'|'Course'}
export type QueryOptions = QueryOption[];
// export type Unit = {name:String} ; 
// export type Capability = {id: number, name:String} ;
// export type Topic = {id:number, name:String};
export const recoilUser = atom({
  key: 'user',
  default: null as GetUserQuery['user']
});

export const recoilSelectedQuery = atom({
  key: 'selectedQuery',
  default: undefined as QueryOption | undefined
});

export const recoilShowInputs = atom({
  key: 'showInputs',
  default: true as boolean
});

export const recoilInputLabels = atom({
  key: 'inputLabel',
  default: 'No input' as String
});

export const recoilUnit = atom({
  key: 'unit',
  default: undefined as string | undefined
})
export type rankedCourse = {title: string, ranking: number|undefined} | undefined
export const recoilSelectedCourse = atom({
  key: 'displayedCourse',
  default: undefined as rankedCourse
})

export const recoilCursor = atom({
  key: 'cursor',
  default: 'default' as 'pointer' | 'default',
})

export const recoilResultMode = atom({
  key: 'resultMode',
  default: 'List' as 'Map' | 'List',
})

export const roles = [{label:'Employer', value:'employer'},
{label: 'Training Provider', value: 'training provider'},
{label: 'Skills Broker/Intermediary', value: 'skill broker'}];

export const recoilZoomedIn = atom({
  key: 'zoomedIn',
  default: false as boolean
})

export const recoilComparisonInputText = atom({
  key: 'comparisonInputText',
  default: '' as string
})

// for the check my course LO <-> Input text matching
export const recoilInputTextLOMatch = atom({
  key: 'inputTextLOMatch',
  default: [] as {lo: string, score: number}[]
})

//for little dialog to select a course on a shared location
export const recoilCoursesOnSharedLocation = atom({
  key: 'coursesOnSharedLocation',
  default: [] as rankedCourse[]
})

//to refresh map size when sidebar is toggled
export const recoilIsNavBarCollapsed= atom({
  key: 'isNavBarExpanded',
  default: false as boolean
});

export const defaultViewport = {
  latitude: 54.55,
  longitude: -2.81, 
  zoom: 5,
  viewportChangeMethod: 'flyTo',
} as Viewport & {
  viewportChangeMethod?: ViewportChangeMethod;
  viewportChangeOptions?: AnimationOptions;
};

export const recoilViewport = atom({
  key: 'mapViewport',
  default: defaultViewport,
});

export const recoilTourStep = atom({
  key: 'tourStep',
  default: -1,
});
export const recoilTotalTourSteps = atom({
  key: 'totalTourSteps',
  default: 0,
});

export const recoilTourID = atom({
  key: 'tourID',
  default: 'find',
});

export const recoilClearInputFields = atom({
  key: 'clearInputFields',
  default: false,
});