import { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { Tab, } from '@headlessui/react';
import styles from '../styles/components/Input.module.css';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Autocomplete, TextField } from '@mui/material';
const Input = ({ type = 'text', label = '', ...props }) => {
  return (
    <div className="h-full w-full flex flex-col justify-center gap-1">
      {label ? <label className={styles.label}>{label}</label> : null}
      <input type={type} className={styles.input} {...props}/>
    </div>
  );
};

export const BooleanCheckbox = ({value: initialValue, label, onChange}:{value: boolean|null, label: string, onChange: (value: boolean | null)=>void})=>{
  const [stateValue, setStateValue] = useState<boolean|null>(initialValue)
  const handleYesCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const newValue = event.target.checked?true:null;
    setStateValue(newValue)
    onChange(newValue)
  };
  const handleNoCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const newValue = event.target.checked?false:null;
    setStateValue(newValue)
    onChange(newValue)
  }
  return (
    <FormGroup>
      <div className="flex flex-row gap-5 items-center w-full justify-between">
        <span className='w-1/3'>{label}</span>
        <FormControlLabel className=''
          control={
            <Checkbox
              checked={(stateValue === true)}
              onChange={handleYesCheckboxChange}
              value="true"
            />
          }
          label="Yes"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={(stateValue === false)}
              onChange={handleNoCheckboxChange}
              value="false"
            />
          }
          label="No"
        />

      </div>
    </FormGroup>
  )
}

export const BinaryCheckbox = ({value, label, options, onChange}:{value: string|null, label:string, options: [string, string], onChange: (value: string | null)=>void})=>{
  const [stateValue, setStateValue] = useState<string|null>(value);
  const handleCheckboxChange1 = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const newValue = event.target.checked?options[0]:null;
    setStateValue(newValue)
    onChange(newValue)
  };
  const handleCheckboxChange2 = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const newValue = event.target.checked?options[1]:null;
    setStateValue(newValue)
    onChange(newValue)
  };
  return (
    <FormGroup>
      <div className="flex flex-row gap-5 items-center w-full justify-between">
        <span className='w-1/3'>{label}</span>
        <FormControlLabel
          control={
            <Checkbox
              checked={(stateValue === options[0])}
              onChange={handleCheckboxChange1}
              value={options[0]}
            />
          }
          label={options[0]}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={(stateValue === options[1])}
              onChange={handleCheckboxChange2}
              value={options[1]}
            />
          }
          label={options[1]}
        />
      </div>
    </FormGroup>
  )
}


export function CustomComboBox({
  label = undefined,
  filterValue,
  filterValueSetter,
  options,
}: {
  label: string | undefined;
  filterValue: any;
  filterValueSetter: (value: any) => void;
  options: any[];
}) {
  return (
    <div className="relative gap-1 flex flex-col">
      {label !== undefined && 
      <label className="text-custom-black text-base font-medium leading-snug">{label}</label>}
      <Autocomplete 
        id={(label||'combo')}
        options={options}
        onChange={(e,v) => filterValueSetter(v||'')}
        value={filterValue}
        defaultValue={filterValue||''}
        renderInput={(params) => <TextField {...params} required  />}
      />

    </div>
  );
}

export const TextAreaInput = ({
  //this isn't actually used
  className = 'flex',
  label = '',
  rows = 1,
  placeholder ='',
  ...props
}
// : {
//   label?: String;
//   className: String;
//   rows: number;
//   placeholder: String;
// }
) => {
  const height = 20 * rows;
  return (
    <div className="h-full  w-full flex flex-col justify-center gap-1">
    {label ? <label className={styles.label}>{label}</label> : null}
    <textarea
      className={`${className} border rounded border-1 w-full border-gray-300 p-3 min-h-[${height}px]`}
      rows={rows}
      placeholder={placeholder}
      {...props}
    ></textarea>
    </div>
  );
};

export function SubmitButton(props: ButtonHTMLAttributes<Element>) {
  return (
    <button
      type="button"
      className="submit text-custom-white px-8 bg-brand rounded-full 
      hover:bg-gradient-to-br focus:ring-4 focus:outline-none 
      focus:ring-blue-300 dark:focus:ring-blue-800 font-medium py-4 text-center"
      {...props}
    ></button>
  );
}

export function BorderedSection({
  icon = undefined,
  title,
  className = '',
  children,
}: {
  icon?: ReactNode;
  title: String;
  className?: String;
  children: ReactNode;
}) {
  return (
    <div className={`${className}`}>
      <div className="z-10 relative">
        <div className={styles.headerBorderBefore}></div>
        {(icon || title) && (
          <div className="bg-white ml-2 -mb-2 w-fit px-1">
            {icon}
            {title && <span className="text-sm">{title}</span>}
          </div>
        )}
        <div className={styles.headerBorderAfter}></div>
      </div>
      <div className="z-0 relative">{children}</div>
    </div>
  );
}

// export function IconButton({
//   icon,
//   className = '',
//   ...props
// }: {
//   icon: SvgIconTypeMap; //ReactNode;
//   className?: string;
//   props: ButtonHTMLAttributes<Element>;
// }) {
//   return (
//     <button type="button" {...props}>
//       {icon}
//     </button>
//   );
// }
export function ToggleTab(props: any): JSX.Element {
  //q: what should i write for the argument of Tabprops? a:
  return (
    <Tab
      className="w-full py-2.5 px-2.5 text-base font-medium leading-5 
      rounded-lg
      ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 
      focus:outline-none
      ui-selected:bg-brand 
      ui-selected:text-white
      ui-not-selected:bg-white 
      ui-not-selected:text-brand"
    >
      {props.children}
    </Tab>
  );
}

// export function IconToggleButton(props: any): JSX.Element {
//   return <>

//   </>;
// }

// export function ExpandButton(
//   props: ButtonHTMLAttributes<Element>
// ): JSX.Element {
//   return <button type="button" {...props}></button>;
// }

export default Input;
