import React, { ReactNode, useEffect, useState } from 'react'
import Button from '@mui/material/Button';

import { To, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';


const IconLink = ({label="", collapsed=false, icon, to="/", href, onClick } : {label?: String; collapsed?: boolean, icon:ReactNode, to?: To, onClick?:VoidFunction, href?:string} ) => {
    const navigate = useNavigate();

    //if it is a button
    const button= <Button onClick={onClick?onClick:()=>navigate(to)} variant={(collapsed)?'text_collapsed':'text'}>
        {icon}
        {!collapsed && 
        <div className="pl-1 text-center text-base font-medium leading-snug">{label}</div>
        }
    </Button>


    // if it's a link (href is assignated)
    const external_link = <a href={href} target="_blank" rel="noreferrer">
        {icon}
        {!collapsed && 
        <span className="pl-1 text-center text-base font-medium leading-snug">{label}</span>
        }
        </a>

    return (
        <div className={"py-2 items-center gap-1 inline-flex text-neutral-500 flex-row justify-start self-stretch"}>
        {collapsed?
        <Tooltip title={label}>
            {href?external_link:button}
        </Tooltip>:(href?external_link:button)
        }
        </div>

    )
}


export default IconLink;