import React from 'react'
import { useGetVisibleFaqQuery } from '../generated/graphql'
import { SingleColumnContainer} from '../components/Containers'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconLink from '../components/IconLink';
import FAQComponent from 'components/FAQComponent';
import { Button } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';

const FAQ = () => {
    const faq = useGetVisibleFaqQuery();
    const navigate = useNavigate();
  return (
    <div className="h-[calc(100vh-64px)] pt-0 flex flex-col items-center w-full mix-blend-multiply overflow-auto">
        <div className="h-full w-full bg-neutral-color-20 p-2.5 flex-row justify-start items-start gap-2.5 inline-flex">
          <Button startIcon={<ArrowBackIosNewIcon/>} onClick={()=>{navigate('/dashboard')}} className="justify-start items-center inline-flex">
              <div className="text-center font-medium">back to dashboard</div>
          </Button>
        </div>
        <FAQComponent/>
    </div>
  )
}

export default FAQ