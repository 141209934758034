import styles from '../styles/components/SignIn.module.css';
import React, { ReactNode } from 'react'


export const SingleColumnContainer = ({title, descriptions: description, max_width="2xl", children, visible=true}:{title:String, descriptions: String[], max_width?: String, children:ReactNode, visible?:boolean}) => {


  return (
    //max_width has to be tailwind style (2xl, 3xl, etc). this doesn't work
    // <div className=""h-full flex flex-col justify-center py-1"">
    <div className={visible?'w-full h-full ':'h-0 w-full'}>

      <div className={"mx-auto flex flex-col w-[542px] py-5 px-5 items-stretch"}>
        <span className="text-center text-black text-2xl font-bold py-6">{title}</span>
        <div className="flex-col justify-start items-center gap-5 inline-flex w-full">
          <div className="text-center text-black text-base font-medium leading-snug flex flex-col gap-2">
            {description.map((text, index) => {
              return <p key={index}>{text}</p>;
            })}
          </div>
          {children}          
        </div>
      </div>
    </div>
  )
}

