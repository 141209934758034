import styles from '../styles/components/Layout.module.css';
import '../styles/globals.css';
import { Fragment } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import {

  HomeIcon,
  LogoutIcon,
  UserIcon,
} from '@heroicons/react/outline';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useSignOut, useUserId } from '@nhost/react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useGetUserQuery } from '../generated/graphql';
import { APP_TITLE } from 'globalVariables';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { recoilTotalTourSteps, recoilTourStep, recoilUser } from 'atoms';

const Layout = () => {
  const id = useUserId();
  const userQuery = useGetUserQuery({ variables: { id } });
  const [user ,setUser] = useRecoilState(recoilUser);
  const { signOut } = useSignOut();
  const navigate = useNavigate();
  const menuItems = [
    {
      label: 'Dashboard',
      href: '/dashboard',
      icon: HomeIcon,
    },
    {
      label: 'Profile',
      href: '/profile',
      icon: UserIcon,
    },
    {
      label: 'Log out',
      onClick: () => signOut(),
      icon: LogoutIcon,
    },
  ];
  if (userQuery.data?.user) setUser(userQuery?.data?.user);

  return (
    <div className="layout h-screen w-screen bg-gray-50 flex flex-col font-body text-custom-black">
      <header className="fixed w-full flex flex-row justify-between bg-brand z-50 h-[56px]">
        <Button onClick={()=>navigate('/')} variant='text'>
          <h1 className="title flex flex-col text-left pl-5 pt-3 pb-4 text-custom-white gap-0">
            <span className="text-lg font-semibold">{APP_TITLE}</span>
          </h1>

        </Button>
        <Menu as="div" className={`{styles.menu} p-5 z-60`}>
          <Menu.Button className={styles['menu-button']}>
            <div className="text-custom-white">
              <AccountCircleOutlinedIcon color="inherit" />
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter={styles['menu-transition-enter']}
            enterFrom={styles['menu-transition-enter-from']}
            enterTo={styles['menu-transition-enter-to']}
            leave={styles['menu-transition-leave']}
            leaveFrom={styles['menu-transition-leave-from']}
            leaveTo={styles['menu-transition-leave-to']}
          >
            <Menu.Items className={styles['menu-items-container']}>
              <div className={styles['menu-header']}>
                <div className={styles['user-details']}>
                  <span>{user?.email}</span>
                  <span className={styles['user-email']}>{user?.email}</span>
                </div>
              </div>

              <div className={styles['menu-items']}>
                {menuItems.map(({ label, href, onClick, icon: Icon }) => (
                  <div key={label} className={styles['menu-item']}>
                    <Menu.Item>
                      {href ? (
                        <Link to={href}>
                          <Icon />
                          <span>{label}</span>
                        </Link>
                      ) : (
                        <button onClick={onClick}>
                          <Icon />
                          <span>{label}</span>
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
        {/* </div> */}
      </header>
      {/* debug */}
      <div className="org-dashboard w-full hidden flex-grow flex-row">
        <div className="org-nav h-full w-44 flex flex-col  bg-slate-400">
          <div className="w-full flex-grow bg-slate-200"></div>
        </div>
        <div className="org-main h-full flex-grow flex flex-col ">
          <div className="tab-list w-full h-20 bg-white"></div>
          <div className="filters w-full h-20 bg-blue-200"></div>
          <div className="ticker w-full h-16 bg-pink-200"></div>
          <div className="visual w-full flex-grow relative overflow-hidden bg-green-50">
            <div className="absolute w-full h-full overflow-auto bg-orange-400 p-5">
              <div className="h-screen w-screen bg-purple-300"></div>
            </div>
          </div>
        </div>
      </div>
      <main className="w-full h-screen overflow-clip pt-[56px]">
        {userQuery?.error ? (
          <div className="w-full h-full text-center text-warning">
            <p>Something went wrong. Try to refresh the page.</p>
            <p>{`${userQuery?.error}`}</p>
          </div>
        ) : !userQuery?.loading ? (
          <Outlet context={{ user }} />
        ) : (
          <ClipLoader />
        )}
      </main>
    </div>
  );
};

export default Layout;
