import React, { useEffect, useRef, useState } from "react"
import { Button, Checkbox } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { eliminateDuplicateNumber } from "./Utils";
const FilteringModal = ({options, prefix="", selected, setter}:
    {options:number[], prefix?:string, selected: number[], setter: React.Dispatch<React.SetStateAction<number[]>>
    }) => {
    options = eliminateDuplicateNumber(options).sort((a, b) => a - b);
    const [openModal, setOpenModal] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    useOutsideAlerter([modalRef, buttonRef]);
    function useOutsideAlerter(refs: React.RefObject<HTMLButtonElement|HTMLDivElement>[]) {
        useEffect(() => {
          const handleClickOutside = (event: { target: any; }) => {
            //if click outside one of the refs
            if ( refs.filter(r=>r.current!==null).filter(r=>r.current?.contains(event.target)).length===0) {
                // if (openModal)
                    setOpenModal(false);
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [...refs]);
      }
    if (options.length<=1) return <></>
    else return  <>
        <Button 
            ref={buttonRef}
            onClick={()=>{
                setOpenModal(!openModal);
                // if (!openModal)setOpenModal(true);
            }}
            variant="naked">
                <ExpandMoreIcon />
        </Button>
        <div ref={modalRef} className={`absolute bg-white left-0 rounded-bl-lg rounded-br-lg shadow top-full w-full flex flex-col gap-4 p-5 ${openModal?'visible':'hidden'}`}>
            {options.map((option, i) => 
            <div className="flex flex-row gap-1 text-neutral-600 items-center justify-start" key={option}>
                <Checkbox 
                sx={{padding: 0}}
                checked={selected.includes(option)||selected.length===0} 
                onChange={(e)=>{
                    if (selected.length===0)
                        //it means everything is checked and user is unchecking
                        //so we'll select everything but the targetted option
                        setter(options.filter(o=>o!==option));
                    else if (e.target.checked)
                        setter([...selected, option]);
                    else 
                        setter(selected.filter((v)=>v!==option));
            }
                }/>
                <span>{option===0?'N/A':prefix+option}</span>
            {/* <FormControlLabel control={<Checkbox/>} */}
            </div>)}
        </div>
    </>
  }

export default FilteringModal;