import React, {ReactNode} from 'react'
import DownArrowIcon from '../assets/icons/downarrow.png'

import { Accordion, AccordionDetails, AccordionSummary,  } from '@mui/material'
import SupportEmailLink from './SupportEmailLink';

const FAQComponent = () => {
    const faqs = [
        {q:"What can employers and skills broker / intermediaries do on the platform?",
          a:<div>Employers, employees or skills brokers can find courses that address topics of interest according to the capabilities their employees or they need.
            <ol className='list-decimal pl-5 flex flex-col gap-1 pt-1'>
              <li>Initially they choose a sector theme from the "Topic" box.</li>
              <li>Then they scroll through the range of capabilities and select as required.</li>
              <li>For each capability that they select, they will be able to see one or more units of training in the dropdown box.</li>
              <li>They select each unit in turn and view the learning objectives that it covers.</li>
              <li>When they click the "Find a course" button, they will be taken to a menu of existing training provision with details of providers and their locations.</li>
              <li>For each unit that meets a capability need within their business, they will be able to see the available courses and training provision ranked according to how they match their requirements.</li>
            </ol>
          </div>},
        {q:"How is learning content matched?", a: <div>
          Matches are completed by Artificial Intelligence (AI) and are assessed in accordance with future skills needs determined by the Workforce Foresighting Hub. For each course selected, a list of learning objectives that deliver the best match will be shown. These are ranked according to a traffic light system with objectives highlighted in green representing the best match. Weakly matched courses and unmatched learning objectives are highlighted in orange and red respectively, enabling a user to quickly identify training courses with the best match for the capabilities selected.
        </div>},
        {q:"Where does the course information come from?", a:<div>
          Courses are identified by using Artificial Intelligence (AI) to scrape the internet. Only courses that are run by approved and registered training providers will be displayed on the platform.
        </div>},
        {q:"What can a training provider do?", a:<div>
          Training providers can use the platform to create a new course, check a new course that is in development or search for existing courses currently being delivered:
          <ol className='list-decimal pl-5 flex flex-col gap-1 pt-1'>
            <li><b>Create a New Course</b> - The platform enables training providers to create a new course based on specifications identified by the Workforce Foresighting Hub. To create a new course, the training provider must select a topic they wish to address and a related business capability. Next, they choose one of the units verified by the Workforce Foresighting Hub. Once they have selected a unit, they can view and export the unit's learning objectives, specification and teaching materials, and use the materials to start delivering a course. In the future, this course will then be referenced by the online platform.</li>
            <li><b>Check My Course</b> - The platform also enables training providers to check how the courses offered by their organisation match the skills requirements defined by the Workforce Foresighting Hub. It helps them to understand how their course provision compares with the competition. They can simply paste a course descriptor into the platform when prompted to do so and see how well it matches with verified units. After checking how well their courses match, they can adapt their course to ensure greater coverage of the objectives identified by the Workforce Foresighting Hub. Once they have published their updated training offer, it will appear in the platform as a better match and show employers where to find their provision.</li>
            <li><b>Search for Existing Courses</b> - Training providers can use the platform to see training provision that is currently available in the market from all training providers. They can also identify gaps in training provision as for each course selected, the platform shows which learning objectives match best in accordance with future skills needs determined by the Workforce Foresighting Hub.</li>
          </ol>
        </div>},
        {q:"Where does the unit specifications and teaching material come from?", a:<div>
          The platform provides access to unit specifications and teaching material from the Department for Education's Emerging Skills Project (ESP) units. These are are available to everyone under Open Government and are maintained by the Workforce Foresighting <a target="_blank" rel="noreferrer" href="https://iuk.ktn-uk.org/programme/workforce-foresighting/#goto-discover-the-hub">Hub</a>.
        </div>},
        {q:"Who built the online platform?", a:<div>
          The tool is brought to you by Enginuity and Oxford Data Technologies, on behalf of the Automotive Council UK Skills Working Group.
        </div>},
      ] as {q:string, a:ReactNode}[];
  return (
    <div id="faq" className="w-full flex flex-col gap-20 text-center px-5 py-16 max-w-[808px] md:pt-28 md:pb-0">
            <div className="flex flex-col gap-7">
              <h2 className="text-4xl font-bold">Frequently asked questions</h2>
              {/* <p className="text-lg">Frequently asked questions ordered by popularity. </p> */}
              <div className="flex flex-col border-t border-black">
                {faqs.map((faq, index) => (
                  <div key={index} className="border-b border-black">
                    <Accordion  className="text-left border-b border-black">
                      <AccordionSummary expandIcon={<div className='w-8 grow-0'><img src={DownArrowIcon} alt="expand" width={24}/></div>} className="text-2xl font-bold">
                        <p>{faq.q}</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="text-lg">{faq.a}</div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-5">
              <h3 className="text-2xl font-bold">Still have a question?</h3>
              <p className='text-base'>Please send an email to <SupportEmailLink who="enginuity"/>
              </p>  

            </div>

          </div>
  )
}

export default FAQComponent