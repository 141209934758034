import { FormEvent, useEffect } from 'react'
import {Switch} from '@headlessui/react'
import { Autocomplete, AutocompleteRenderInputParams,  Button, Modal, TextField } from '@mui/material'
import { useState } from 'react'
import { useAddUnitToHistoryMutation, useGetCapabilitiesByUnitQuery, useGetCapabilitiesLikeByTopicQuery, useGetLearningObjectivesQuery, useGetTopicsLikeQuery, useGetUnitsLikeByCapabilityQuery, useGetUnitsLikeByTopicQuery} from 'generated/graphql';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useRecoilState, useRecoilValue } from 'recoil';
import { recoilComparisonInputText, recoilShowInputs, recoilUnit, recoilInputTextLOMatch, recoilSelectedQuery, recoilResultMode, recoilClearInputFields } from 'atoms';
import { useNhostClient } from '@nhost/react';
import { TextAreaInput } from './Input';
import OneIcon from '../assets/1.png'
import TwoIcon from '../assets/2.png'
import ThreeIcon from '../assets/3.png'
import CPUIcon from '../assets/Cpu.png'
import GraphIcon from '../assets/Graph.png'
import CertificateIcon from '../assets/Certificate.png'
import { useGetAllUnitsDescriptionsQuery, useGetUserQuery} from 'generated/graphql';
import { shortenText } from 'components/Utils';
import ClipLoader from 'react-spinners/ClipLoader';
import { eliminateDuplicates, escapingSpecialChars, formatLO } from './Utils';
import UnitResult from './UnitResult';
import CloseIcon from '@mui/icons-material/Close';
import CoverImg from '../assets/Cover Image.png'
const CourseSearch = ({heading='Find a course', searchMode=false, visible}:{searchMode?: boolean, heading?:String, visible:boolean}) => {
  let title =heading;
  let text = [] as String[];
  let submitLabel = heading;

  if (heading==='Search for existing courses') {
    title = 'Find courses';
    submitLabel = 'Search for existing short courses'
    text = ['Identify your training needs and align them to Emerging Skills Project units to find available short courses.',
    'Drill down to select an Automotive Sector technology, then select from one of the forecasted training needs or jump straight to an Emerging Skills Project Unit!']
  }
  if (heading==='Create a new Course') {
    title = 'Create a course';
    text = ['Identify your training provision area and align it to Emerging Skills Project units to find guidance on how to develop a new short course and download the teaching materials.',
    'Drill down to select an Automotive Sector technology, then select one of the forecasted training provision areas or just jump straight to an Emerging Skills Project Unit!']
  }
  if (heading==='Check my Course') {
    title = 'Check my course';
    text = ['Paste the text of a short course specification into the AI Assistant and see how it compares to the Future Skills Needs.']
  }
  return (
    <>
    {searchMode?
      <UnitSearch visible={visible} submitLabel={submitLabel} title={title} text={text}/>:
      <UnitSelectionForm visible={visible} submitLabel={submitLabel} title={title} text={text}/>}
    </>
  
   )
}

const UnitSearch = ({visible, submitLabel, title, text}:{visible:boolean, submitLabel:String, title:String, text: String[]}) => {

  const maxResults = 5;
  const { auth } = useNhostClient();
  const user = auth.getUser();
  type unitListItem = {name: string, learning_objectives:string[], shortDesc: String, score: number}
  const [input, setInput] = useState('');
  const allUnits = useGetAllUnitsDescriptionsQuery();
  const [resultList, setResultList] = useState ([] as unitListItem[])
  const [loadingResults, setLoading] = useState(false);
  const [,setQueriedUnit] = useRecoilState(recoilUnit);
  const [addUnitToHistory, ]= useAddUnitToHistoryMutation();
  const [, setShowInput] = useRecoilState(recoilShowInputs);
  const [comparisonInputText, setComparisonInputText] = useRecoilState(recoilComparisonInputText);
  const [, setInputTextLOMatch] = useRecoilState(recoilInputTextLOMatch);
  const selectedQueryLbl = useRecoilValue(recoilSelectedQuery);
  const [highlightFirstItem, setHighlightFirstItem] = useState(false);
  const handleSearch = (e:FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setResultList([]);
    const text_a = escapingSpecialChars(input);
    setComparisonInputText(text_a);

    if (text_a)
      allUnits.data?.units.forEach( async(unit) => {
        const text_b = escapingSpecialChars(unit.specification_txt||'');
        if (text_b) {
          try {
            setLoading(true);
            fetch('https://search.foresighter.ai/automotive-comparer', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              body: ('text_a='+text_a+'&text_b='+text_b)
            }).then(async (res)=>{
              setLoading(false);
              const responseScore = await res.json()
              setResultList(resultList=> [...resultList, {name: unit.name, learning_objectives: unit.units_learning_objectives.map(lo=>lo.name), shortDesc: shortenText(text_b, 200), score:responseScore}].sort((a,b)=>(b.score-a.score)))
            })
          }
          catch (e) {
            console.log('error', e)
          }
        }
      }) 
    
  }

  const selectUnit = async (selectedUnit: string, selectedLearningObjectives: string[]) => {
    setShowInput(false); 
    setQueriedUnit(selectedUnit); 
    
    try {
      
      addUnitToHistory({variables: {unit: selectedUnit, user: user?.id, user_input: comparisonInputText, query_label: (selectedQueryLbl?.label||'')}});
      //do the comparison between the input text and the learning objectives
      setInputTextLOMatch([]);//clears the list
      selectedLearningObjectives.forEach(async(lo)=>{
        fetch('https://search.foresighter.ai/automotive-comparer', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: 'text_a='+comparisonInputText+'&text_b='+escapingSpecialChars(lo)
        }).then(async (res)=>{
          const responseScore = await res.json()
          setInputTextLOMatch(inputTextLOMatch=> [...inputTextLOMatch, {lo: lo, score:responseScore}])
        })
      })
    }
    catch (e) {
      console.log('error', e)
    }
  }
  return (<div className={visible?'flex flex-col w-full gap-5 items-center relative':'hidden'}>

  <div className="flex flex-col gap-8 mb-5 pt-24 pb-10 max-w-screen-sm">
    <div className="flex flex-col gap-2">
      <h2 className="text-xl font-bold">
        {title}
      </h2>
      {text.map((t, index)=>(
        <p key={index} className="text-base font-normal">
          {t}
        </p>
      ))}

    </div>
  <form onSubmit={handleSearch} className='w-full gap-5 flex flex-col items-stretch pb-5'>
    <TextAreaInput placeholder='Paste Course Specification Here' rows={15} value={input} onChange={(e:FormEvent)=>setInput((e.target as HTMLTextAreaElement).value)} />
    <Button type="submit" variant='contained'>Search</Button>
  </form>
  {resultList.map((unit, index)=>(
    index<maxResults?<div className='relative'>
      <Button onClick={()=>selectUnit(unit.name, unit.learning_objectives)} key={unit.name}>
        <div className={`w-full p-3 border-b border-neutral-300 text-left text-base ${(index===0&&highlightFirstItem)?'bg-neutral-color-10':''}`} key={unit.name}>
          <span className="font-bold">{unit.name}</span>
          <p className='font-normal'>{unit.shortDesc}</p>
        </div>

      </Button>      
    </div>
    :null
    ))}
  {loadingResults?<ClipLoader/>:null}
  </div>

  </div>)
}

const UnitSelectionForm = ({visible, submitLabel, title, text=[]}:{visible:boolean, submitLabel:String, title:String, text?:String[]}) => {

  const { auth } = useNhostClient();
  const user = auth.getUser();
  const userQuery = useGetUserQuery({variables: {id: user?.id}});
  const [, setShowInput] = useRecoilState(recoilShowInputs);
  const [searchTopicTerm, setSearchTopicTerm ]= useState('')
  const [selectedTopic, selectTopic ]= useState('');
  // tmp: disbale db topics because we don't want electrification to show up
  const topicList = useGetTopicsLikeQuery({variables: {expr: '%'+searchTopicTerm+'%'}});
  // const topicList = {data: {units: [{topic:"Batteries"}, {topic:"Industrial Digitisation Technologies"}]}, loading:false}
  
  const [searchCapabilityTerm, setSearchCapabilityTerm ]= useState('')
  const [selectedCapability, selectCapability ]= useState('');
  const capabilityList = useGetCapabilitiesLikeByTopicQuery({variables: {topic: selectedTopic, expr: '%'+searchCapabilityTerm+'%'}});

  const [searchUnitTerm, setSearchUnitTerm ]= useState('')
  const [selectedUnit, selectUnit ]= useState('');
  const unitListByTopic = useGetUnitsLikeByTopicQuery({variables: {topic: selectedTopic, expr: '%'+searchUnitTerm+'%'}});
  const unitListByCapability = useGetUnitsLikeByCapabilityQuery({variables: {capability: selectedCapability, expr: '%'+searchUnitTerm+'%'}});

  const [openLO, setOpenLO] = useState(false);
  const [openCapabilities, setOpenCapabilities] = useState(false);
  const [showMoreCaps, setShowMoreCaps] = useState(false);
  const [openUnit, setOpenUnit] = useState(false);
  const capabilities = useGetCapabilitiesByUnitQuery({variables: {unit: selectedUnit??''}});
  const learningObjectives = useGetLearningObjectivesQuery({variables: {unit: selectedUnit??''}});
  const [,setQueriedUnit] = useRecoilState(recoilUnit);
  const selectedQueryLbl = useRecoilValue(recoilSelectedQuery);

  const [addUnitToHistory,  ]= useAddUnitToHistoryMutation();

  const [searchWithCapabilities, setSearchWithCapabilities] = useState(true);//for all users
  const [, setResultMode] = useRecoilState(recoilResultMode);

  const [clearInput, setClearInput] = useRecoilState(recoilClearInputFields)
  const handleSubmit = async () => {
    setShowInput(false); 
    setResultMode('List')
    
    try {
      addUnitToHistory({variables: {unit: selectedUnit, user: user?.id, user_input: '', query_label: (selectedQueryLbl?.label||'')}});
    }
    catch (e) {
      console.log('error', e)
    }
  }
  const clearFields = () => {
    selectTopic('');
    selectCapability('');
    selectUnit('');
    setSearchTopicTerm('');
    setSearchCapabilityTerm('');
    setSearchUnitTerm('');
  }
  useEffect(() => {
    if (clearInput){
      clearFields();
      setClearInput(false);
    }
  },[clearInput])
  //for enginuity users
  // if (userQuery.data?.user?.users_user_data?.enginuity === true && searchWithCapabilities) 
  //     setSearchWithCapabilities(false);
  // interface AcOption {code: string, description: string}

  const alternatingBlueDropDownRender = (props:any, option: any) => {
    return (
      <li {...props} className={"hover:text-brand hover:bg-blue-100 px-5 py-2 cursor-pointer "+(props['data-option-index']%2===0?'bg-blue-50':'bg-white')}> <span> {option}</span></li>
    );
  }

  return (
  <div className={visible?'flex flex-col w-full gap-5 items-center':'hidden'}>
    <img src={CoverImg} alt="cover" className="w-full h-auto max-h-32"/>
    <div className="flex flex-col gap-8 py-10 max-w-screen-sm">
      {/* header */}
      <div className="flex flex-col gap-2">
        <h2 className="text-xl font-bold">
          {title}
        </h2>
        {text.map((t, index)=>(
          <p key={index} className="text-base font-normal">
            {t}
          </p>
        ))}
      </div>
      
      {/* form */}
      <div className="flex flex-col gap-5">
        <div className="flex flex-row gap-3">
          <img src={CPUIcon} alt="CPU" className='w-12 h-12'/>
          <div className="flex flex-col gap-1 grow">
            <div className="flex flex-row gap-1.5 items-start">
              <img src={OneIcon} alt="1" className="w-5 h-5"/>
              <span className="text-custom-black text-base font-medium leading-snug">Select my technology area</span>
            </div>
            <Autocomplete 
              id='topic'             
              openOnFocus
              autoComplete
              loading={topicList.loading}
              options={topicList.data?.units.map(topic=>topic.topic)??[]}
              className='w-full'
              value={selectedTopic}
              onChange={(e, v) => {selectTopic(v||''); selectCapability(''); selectUnit(''); setSearchTopicTerm(''); setSearchCapabilityTerm(''); setSearchUnitTerm('');}}
              renderInput={(params: AutocompleteRenderInputParams)=>(
                <TextField {...params} placeholder="Select or enter a keyword" value={searchTopicTerm}  
                  onChange={(e) => {
                    setSearchTopicTerm(e.target.value);
                  }}/>
                  )}
              />
          </div>
        </div>

        {/*if topic is selected, show capability selection  */}
        <div className={"self-stretch flex-row justify-start items-start gap-3 "+
          ((searchWithCapabilities && selectedTopic !== '')?"flex":"hidden")}>
          <img src={GraphIcon} alt="Graph" className='w-12 h-12'/>
          <div className="flex flex-col gap-1 grow ">
            <div className="flex flex-row gap-1.5 items-start">
              <img src={TwoIcon} alt="2" className="w-5 h-5"/>
              <span className="text-custom-black text-base font-medium leading-snug">Select from training needs</span>
            </div>
            <Autocomplete 
              id='capability'             
              openOnFocus
              autoComplete
              loading={capabilityList.loading}
              options={eliminateDuplicates(capabilityList.data?.units.flatMap(u=>u.capabilities).map(cap=>cap.name)??[])}
              className='w-full '
              value={selectedCapability}
              onChange={(e, v) =>{ selectCapability(v||''); selectUnit('');setSearchCapabilityTerm('');setSearchUnitTerm('');}}
              renderOption={alternatingBlueDropDownRender}
              renderInput={(params: AutocompleteRenderInputParams)=>(
                <TextField {...params} placeholder="Select your training need or enter a keyword" value={searchCapabilityTerm}  
                  onChange={(e) => {
                    setSearchCapabilityTerm(e.target.value);
                  }}/>
                  )}
              />
            </div>
        </div>

        {/* unit */}
        {/* if we are not using capabilities and topic selected OR capability is selected, show unit selection */}
        <div className={"self-stretch flex-row justify-start items-start gap-3 "+
          (((!searchWithCapabilities && selectedTopic !== '') || selectedCapability !== '')?"flex":"hidden")}>

          <img src={CertificateIcon} alt="Certificate" className='w-12 h-12'/>
          <div className="flex flex-col gap-1 grow">
            <div className="flex flex-row gap-1.5 items-start">
              <img src={ThreeIcon} alt="3" className="w-5 h-5"/>
              <span className="text-custom-black text-base font-medium leading-snug">Select one of the following ESP Units</span>
            </div>
          <Autocomplete
            id='unit'             
            openOnFocus
            autoComplete
            loading={(searchWithCapabilities?unitListByCapability:unitListByTopic).loading}
            options={(searchWithCapabilities?
              unitListByCapability.data?.capabilities.map(c=>c.capabilities_unit.name)
              :unitListByTopic.data?.units.map(u=>u.name))??[]}
            className='w-full'
            value={selectedUnit}
            onChange={(e, v) => {selectUnit(v||''); setSearchUnitTerm('');setQueriedUnit(v||undefined); 
          }}
            renderInput={(params: AutocompleteRenderInputParams)=>(
              <TextField {...params} placeholder="Select unit or type in a keyword" value={searchUnitTerm}  
                onChange={(e) => {
                  setSearchUnitTerm(e.target.value);
                }}/>
                )}
            />
          </div>
        </div>
        
        {/* if unit is selected, show learning objectives and capabilities button*/}
        <div className={"text-text-light self-stretch mb-10 items-start "+(selectedUnit?"flex flex-col":"hidden")}>
        {(capabilities.data?.capabilities.length!==undefined && capabilities.data?.capabilities.length!==0) &&
          <Button  onClick={(()=>setOpenCapabilities(true))} className="justify-start items-end gap-2">
            <InfoOutlinedIcon/>
            <span className="text-base text-left pt-1 font-medium flex-grow ">View all capabilities delivered in this unit</span>
          </Button>
        }
        {/* {(learningObjectives.data?.learning_objectives.length!==undefined && learningObjectives.data?.learning_objectives.length!==0) &&
          <Button onClick={(()=>setOpenLO(true))} className="justify-start items-end gap-2">
            <InfoOutlinedIcon/>
            <span className="text-base text-left pt-1 font-medium flex-grow ">View unit learning objectives</span>
          
          </Button> 
        } */}
        {(selectedUnit && (selectedQueryLbl?.name === 'find course')) &&
        // selectedQueryLbl?.resultType==='Course' &&
          <Button onClick={(()=>setOpenUnit(true))} className="justify-start items-end gap-2">
            <InfoOutlinedIcon/>
            <span className="text-base text-left pt-1 font-medium flex-grow ">View Full Unit specification</span>
          </Button>
        }  

        {/* Learning objectives list */}
        <Modal
          open={openLO}
          onClose={(()=>setOpenLO(false))}
          aria-labelledby="learning-objectives"
          className='justify-center items-center flex'
        >
          <div className="p-5 bg-white rounded-lg shadow flex-col justify-start items-start gap-1 inline-flex mx-auto max-w-[743px]">
            <Button sx={{justifyContent: 'flex-end'}} onClick={()=>setOpenLO(false)}>
              <CloseIcon/>
            </Button>
            <div className="text-custom-black text-lg font-semibold">Learning Objectives</div>
            <ul className='list-disc pl-5'>

              {learningObjectives.data?.learning_objectives.map((lo, index)=>(
                <li key={index} className="text-custom-black text-base font-normal leading-snug">
                  {formatLO(lo.name)}
                </li>
              ))}
              </ul>
          </div>
        </Modal>
        
        <Modal
          open={openCapabilities}
          onClose={(()=>setOpenCapabilities(false))}
          aria-labelledby="capabilities"
          className='justify-center items-center flex'
        >
          <div className="p-5 bg-white rounded-lg shadow flex-col justify-start items-start gap-1 inline-flex mx-auto max-w-[743px] ">
          <Button sx={{justifyContent: 'flex-end'}} onClick={()=>setOpenCapabilities(false)}>
            <CloseIcon/>
          </Button>
            <div className="text-custom-black text-lg font-semibold pb-2">Capabilities</div>
            {/* <div className="text-custom-black text-base font-normal leading-snug"> */}
            <ul className='max-h-[calc(100vh-250px)] overflow-auto'>

              {capabilities.data?.capabilities.slice(0, showMoreCaps?undefined:10).map((cap, index)=>(
                <li key={index} className={("px-3 py-2 text-base font-normal leading-snug "+(index%2===0?"bg-blue-50":""))}>
                  {cap.name}
                </li>
              ))}
              </ul>
              {(capabilities.data?.capabilities||[]).length>10 &&
            <div className="flex flex-row justify-start p-3 text-brand text-base">
              <Button variant="text" onClick={()=>setShowMoreCaps(!showMoreCaps)}>{showMoreCaps?"Show less":"Show more"}</Button>
            </div>}
          </div>
        </Modal>
        <Modal
          open={openUnit}
          onClose={(()=>setOpenUnit(false))}
          aria-labelledby="specification"
          className='justify-center items-center flex overflow-y-auto'
        >
          <div className="p-5 bg-white rounded-lg shadow flex-col justify-start items-start gap-2 inline-flex mx-auto max-w-[1000px] max-h-[calc(100%-40px)] overflow-clip">
          <Button sx={{justifyContent: 'flex-end'}} onClick={()=>setOpenUnit(false)}>
            <CloseIcon/>
          </Button>
            <div className="text-custom-black text-lg font-semibold">Unit Specification</div>
            <div className="text-custom-black text-base font-normal leading-snug flex flex-col gap-2 specification px-4 overflow-y-auto">
              <UnitResult/>
              {/* {format(unitDetails.data?.units?.[0]?.specification_txt||'')} */}
            </div>
          </div>
        </Modal>
        </div>

      </div>

      <Button disabled={selectedUnit===''||selectedUnit===undefined} variant='contained' className="justify-center items-center gap-5 inline-flex" onClick={handleSubmit}>
        <span className="text-base font-medium">{submitLabel}</span>
        {/* <ArrowForwardIcon/> */}
      </Button>

      {(userQuery.data?.user?.users_user_data?.admin === true) &&
      <div className="flex flex-row gap-5 items-center">
        <span className={searchWithCapabilities?'text-brand':'text-neutral-500'}>Search all ESP Units</span>
        <div className="">
          <Switch
            checked={searchWithCapabilities}
            onChange={setSearchWithCapabilities}
            className={`${searchWithCapabilities ? 'bg-brand' : 'bg-neutral-500'}
            pt-0.5 relative inline-flex h-[40px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${searchWithCapabilities ? 'translate-x-9' : 'translate-x-0'}
                pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div>
      </div>}

    </div>
  </div>);

}



export default CourseSearch