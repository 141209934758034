import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { NhostClient, NhostProvider } from '@nhost/react';
import { NhostApolloProvider } from '@nhost/react-apollo';
import Layout from './components/Layout';
import {SignInPage, VerifyPage, ActivatePage, SignUpPage, ForgotPasswordPage, ChangePasswordPage } from './pages/AuthPages';
import Dashboard from './pages/Dashboard';
import AdminTables from './pages/AdminTables';
import ProtectedRoute from './components/ProtectedRoute';
import Privacy from 'pages/Privacy';
import Profile from 'pages/Profile';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material';
import AdminDashboard from 'pages/Admin';
import AdminMatches from 'pages/AdminMatches';
import Home from 'pages/Home';
import FAQ from 'pages/FAQ';
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    squared: true;
    text: true;
    text_collapsed: true;
    naked: true;
    filelink: true;
    history: true;
    panel: true;
    small_squared: true;
    not_outlined: true;
    list: true;
  }
}

const nhost = new NhostClient({
  subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN || '',
  region: process.env.REACT_APP_NHOST_REGION || '',
  // backendUrl: process.env.REACT_APP_NHOST_BACKEND_URL || '',
  // authUrl: 'https://local.auth.nhost.run',
  // storageUrl: 'https://local.storage.nhost.run',
  // graphqlUrl: 'https://local.graphql.nhost.run',
  // functionsUrl: 'https://local.functions.nhost.run'

});


const theme = createTheme({
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'var(--color-brand)',
          '&.Mui-checked': {
            color: 'var(--color-brand)',
          },
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow: 'none',
          padding: '12px',
          border: 'none',
          '&::before': {
            display: 'none',
          },
          '&.Mui-collapsed': {
            borderRadius: '8px',
          },

        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          border: 'none',
          
          '&.Mui-expanded': {
            minHeight: '0px',
            padding: '7px ',
          },
        },
        content: {
          // flexGrow: 0,
          margin: '0px',
          '&.Mui-expanded': {
            margin: '0px',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '7px',
          margin: '0px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: 'inherit',
          padding: '0 0',
          alignSelf: 'stretch',
          '&:hover': {
            backgroundColor: 'transparent',
            color: 'var(--color-brand)',
          }
        },
        
        outlined:{
          color: 'var(--color-brand)',
          backgroundColor: 'var(--color-custom-white)',
          border: '1px solid var(--color-brand)',
          borderRadius: '30px',
          padding: '12px 32px',
          fontSize: '16px',
          fontWeight: '500',
          alignItems: 'flex-start',
          alignContent: 'center',
          justifyContent: 'center',
          lineHeight: '150%',
          '&:hover': {
            color: 'var(--color-brand-dark)',
            backgroundColor: 'var(--color-custom-white)',
            border: '1px solid var(--color-brand-dark)',
          },
          '&:pressed': {
            backgroundCOlor: 'var(--color-brand-light)',
          }
        }, 

        contained:{
          backgroundColor: 'var(--color-brand)',
          border: '1px solid var(--color-brand)',
          borderRadius: '30px',
          padding: '12px 32px',
          fontSize: '16px',
          fontWeight: '500',
          alignItems: 'flex-start',
          alignContent: 'center',
          justifyContent: 'center',
          lineHeight: '150%',
          color: 'var(--color-custom-white)',
          '&:hover': {
            color: 'var(--color-custom-white)',
            backgroundColor: 'var(--color-brand-light)',
            border: '1px solid var(--color-brand-light)',
          },
          '&:pressed': {
            backgroundColor: 'var(--color-brand-dark)',
            border: '1px solid var(--color-brand-dark)',
          },
          '&.Mui-disabled': {
            color: 'var(--color-custom-white)',
            // backgroundColor: '#000',
            backgroundColor: '#b6b6b6',
            border: '1px solid #b6b6b6',
          
          }
        }
      },
      variants: [

        {
          props: { variant: 'squared' },
          style: {
            color: 'var(--color-brand)',
            border: '1px solid var(--color-brand)',
            borderRadius: '8px',
            padding: '10px',
            backgroundColor: 'white',
            minWidth: '40px',
            '&:disabled': {
              color: 'var(--color-neutral-50)',
              border: '1px solid var(--color-neutral-50)',
            },
            '&:hover': {
              color: 'var(--color-brand-dark)',
              backgroundColor: 'white',
              border: '1px solid var(--color-brand-dark)',
            },
            '&:pressed': {
              backgroundColor: 'var(--color-brand-light)',
              border: '1px solid var(--color-brand-light)',
            }
          },
        }, 
        {
          props: { variant: 'not_outlined' },
          style:{

            color: 'var(--color-brand)',
            backgroundColor: 'var(--color-custom-white)',
            border: 'none',
            borderRadius: '30px',
            padding: '12px 24px',
            fontSize: '16px',
            fontWeight: '500',
            alignItems: 'flex-start',
            alignContent: 'center',
            justifyContent: 'center',
            lineHeight: '150%',
            '&:hover': {
              color: 'var(--color-custom-white)',
              backgroundColor: 'var(--color-neutral-400)',
              border: 'none',
            },
            '&:pressed': {
              backgroundCOlor: 'var(--color-brand-light)',
            }
          },
        },
        {
          props: { variant: 'small_squared' },
          style: {
            color: 'var(--color-brand)',
            border: '1px solid var(--color-neutral-50)',
            borderRadius: '8px',
            // padding: '4px',
            backgroundColor: 'white',
            width: '32px',
            minWidth: '32px',
            height: '32px',
            '&:disabled': {
              color: 'var(--color-neutral-50)',
              border: '1px solid var(--color-neutral-50)',
            },
            '&:hover': {
              color: 'var(--color-brand-dark)',
              backgroundColor: 'var(--color-neutral-70)',
              border: '1px solid var(--color-brand-dark)',
            },
            '&:pressed': {
              backgroundColor: 'var(--color-brand-light)',
              border: '1px solid var(--color-brand-light)',
            }
          },
        }, 
        {
          props: { variant: 'naked' },
          style: {
            color: 'var(--color-brand)',
            border: 'none',
            borderRadius: '2px',
            padding: '0px',
            backgroundColor: 'transparent',
            width: '24px',
            minWidth: '24px',
            height: '24px',
            '&.MuiButton-startIcon':{
              marginLeft: '0px',
            },
            '&:disabled': {
              color: 'var(--color-neutral-50)',
            },
            '&:hover': {
              color: 'var(--color-brand-dark)',
            },
            '&:pressed': {
              backgroundColor: 'var(--color-brand-light)',
            }
          },
        },
      {
        props: { variant: 'text' },
        style: {
          fontSize: '18px',
          fontWeight: '500',
          letterSpacing: '0',
          // color: 'var(--color-brand)',
        },
      },
      {
        props: { variant: 'text_collapsed' },
        style: {
          border: 'none',
            borderRadius: '2px',
            padding: '0px',
            backgroundColor: 'transparent',
            width: '24px',
            minWidth: '24px',
            height: '24px',
        },
      },
      {
        props: { variant: 'history' },
        style: {
          letterSpacing: '0',
          borderRadius: '0px',
          padding:'10px 5px',
          borderBottom: '1px solid var(--color-neutral-100)',
          '&:hover': {
            // color: 'var(--color-custom-white)',
            backgroundColor: 'var(--color-neutral-color-10)',
            // borderB: '1px solid var(--color-brand-light)',
          },
        }
      },
      {
        props: { variant: 'list' },
        style: {
          letterSpacing: '0',
          borderRadius: '0px',
          padding:'0px 0px',
          borderBottom: '1px solid var(--color-neutral-100)',
          '&:hover': {
            // color: 'var(--color-custom-white)',
            backgroundColor: 'var(--color-neutral-color-10)',
            // borderB: '1px solid var(--color-brand-light)',
          },
        }
      },
      {
        props: { variant: 'filelink' },
        style: {
          gap: 0,
          textAlignment: 'left',
          justifyContent: 'flex-start',
          fontSize: '15px',
          fontWeight: 400,
          letterSpacing: '0',
          // color: 'var(--color-brand)',
        },
      
          

      },
      {
        props: { variant: 'panel' },
        style: {
          backgroundColor: 'var(--color-neutral-color-20)',
          '&:hover': {
            backgroundColor: 'var(--color-neutral-color-20)'
          },
        },
      
          

      }
    ]
    },

  }
});

function App() {
  
  return (
      <ThemeProvider theme={theme}>
    <NhostProvider nhost={nhost}>
      <NhostApolloProvider nhost={nhost}>
        <BrowserRouter>
          <Routes>
            {/* <Route path="sign-up" element={<SignUp />} /> */}
            <Route path="privacy" element={<Privacy />} />
            <Route path="sign-in" element={<SignInPage />} />
            <Route path="sign-up" element={<SignUpPage />} />
            <Route path="verify" element={<VerifyPage />} />
            <Route path="dashboard/change-password" element={<ChangePasswordPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            {/* <Route path="set-up" element={<SetUpPage />} /> */}
            <Route path="activate" element={<ActivatePage />} />
            <Route index element={<Home/>} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Layout />
                </ProtectedRoute>
              }
            >
              <Route path="dashboard" element={<Dashboard/>} />
              
              <Route path="admin" element={<AdminDashboard />} />
              <Route path="admin-matches" element={<AdminMatches/>} />
              <Route path="admin-table" element={<AdminTables/>} />
              <Route path="profile" element={<Profile />} />
              <Route path="faq" element={<FAQ />}/>

            </Route>
          </Routes>
        </BrowserRouter>
        <Toaster />
      </NhostApolloProvider>
    </NhostProvider>
        </ThemeProvider>  
  );
}

export default App;
