import React, {useEffect, useState} from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {Accordion, AccordionDetails, AccordionSummary, Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetAllLearningObjectivesCoursesMatchesQueryResult, useDeleteLearningObjectiveCourseMutation } from '../../generated/graphql';
// import {  useDeleteLearningObjectiveCourseMutation } from '../../generated/graphql';
type LOCoursesMatchTableProps = {
    onUpdate: () => void;
    Query: GetAllLearningObjectivesCoursesMatchesQueryResult;
    }
const LOCoursesMatchTable = ({onUpdate, Query}:LOCoursesMatchTableProps) => {
    const allLearningObjectivesCoursesMatches = Query;
    const [deleteLearningObjectiveCourse, setDeleteLearningObjectiveCourse] = useState(['','']);
    const [deleteLearningObjectiveCourseMutation,] = useDeleteLearningObjectiveCourseMutation();

    useEffect(() => {
        if (deleteLearningObjectiveCourse[0] !== '' && deleteLearningObjectiveCourse[1] !== ''){
            try{
                deleteLearningObjectiveCourseMutation({variables: {learning_objective: deleteLearningObjectiveCourse[0], course: deleteLearningObjectiveCourse[1]}}).then((res)=>{
                    onUpdate();
                    toast.success('Learning objective "'+deleteLearningObjectiveCourse+'" deleted')})
            } catch (e) {
                console.log('error', e)
                toast.error('Error deleting learning objective "'+deleteLearningObjectiveCourse+'"')
            }

            setDeleteLearningObjectiveCourse(['','']);
        }
    }, [deleteLearningObjectiveCourse, deleteLearningObjectiveCourseMutation ])
  return (
    <Accordion>
        <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
            <span className="text-base font-bold">Learning objectives / Courses Match({allLearningObjectivesCoursesMatches.data?.learning_objectives_courses_matches_aggregate?.aggregate?.count||'..'})</span>
        </AccordionSummary>
        <AccordionDetails>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Learning objective</TableCell>
                        <TableCell>Course</TableCell>
                        <TableCell>Score</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allLearningObjectivesCoursesMatches.data?.learning_objectives_courses_matches?.map((v,i) => (
                        <TableRow key={i}>
                            <TableCell>{v.learning_objective}</TableCell>
                            <TableCell>{v.course}</TableCell>
                            <TableCell>{v.score.toFixed(3)}</TableCell>
                            <TableCell>
                                <Button 
                                variant='naked' 
                                onClick={()=>setDeleteLearningObjectiveCourse([v.learning_objective, v.course])} startIcon={<DeleteIcon/>} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </AccordionDetails>
    </Accordion>

    )
}

export default LOCoursesMatchTable