import React from 'react'
import { verySimilarThreshold, similarThreshold } from '../components/Utils'

const verySimilarColor = "very-similar"
const similarColor = "similar"
const dissimilarColor = "dissimilar"
const transparent = "transparent"

const SimilarityDots = ({score}:{score:number}) => {

  const dotColors = score>=verySimilarThreshold?[verySimilarColor, verySimilarColor, verySimilarColor]: 
                score>=similarThreshold?[similarColor, similarColor, transparent]:
                [dissimilarColor, transparent, transparent]
    return (
    <div className="flex flex-row gap-1 pt-1">
        {dotColors.map((dotColor, index) => (
            <div key={index} className={` w-4 h-4 rounded-full bg-${dotColor}`}></div>
        ))}

    </div>
  )
}

export const similarityBorder = (score:number) => {
    const lineColor = score>=verySimilarThreshold?verySimilarColor:
                score>=similarThreshold?similarColor:
                dissimilarColor
    return (" border-b border-"+lineColor+" ")
}

export default SimilarityDots