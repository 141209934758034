export const verySimilarThreshold = .6
export const similarThreshold = .515

export const LoScaleFactor = 1;

export const scaleScoreCourse = (score: number) => {
    //all scores appear to be close to .55
    return score;
    // return score*1.3; //tmp
    const maxVal = .7;
    const minVal = .5;
    const scaled = (score-minVal)/(maxVal-minVal);
    return scaled>1?1:scaled<0?0:scaled;
}
export const reverseScaleScoreCourse = (score: number) => {
    return score; 
}

export const scaleScoreLO = (score: number) => {
    return score*LoScaleFactor; //tmp
    // const maxVal = .72;
    // const minVal = .5;
    // const scaled = (score-minVal)/(maxVal-minVal);
    // return scaled>1?1:scaled<0?0:scaled;
}
export const reverseScaleScoreLO = (score: number) => {
    return score/LoScaleFactor; 
}


export const eliminateDuplicates = (arr: string[]) => {
    const uniquesArray: string[] = [];
    arr.forEach((str) => {
        if (!uniquesArray.includes(str)) {
        uniquesArray.push(str);
        }
    });
    //sort array
    uniquesArray.sort((a,b) => {
        return a.localeCompare(b);
    })
    return uniquesArray;
};

export const eliminateDuplicateNumber = (arr: number[])=>{
    const uniquesArray: number[]= []
    arr.forEach((num)=>{
        if(!uniquesArray.includes(num))
            uniquesArray.push(num)
    })
    return uniquesArray;
}
export const escapingSpecialChars = (str: string) => {

    return str.replaceAll('&', ' and ')
    .replaceAll('•','')
    .replaceAll('*','')
    .replaceAll('/',', ')
    .replaceAll('+',' and ')
    .replaceAll('=',' equals ')
    .replaceAll('"','')
    .replaceAll(/£(\d+.*\d*)/g, "$1 pounds")
    .replaceAll(/[£$€¥₹]/g, '')
    .replaceAll('\n','. ')
    .replaceAll('\r',' ')
    .replaceAll('\t',' ')
    .replaceAll('|','')
    .replaceAll('–','')
    .replaceAll(':', ', ')
    .replaceAll('(','')
    .replaceAll(')','')
    .replaceAll('-',' ')
    .replaceAll('’',' ')

}
//put the first letter in capital
export const formatLO = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const shortenText = (text:string, length:number) => {
    if (text.length > length) {
        return text.substring(0, length)+'...';
    }
    else {
        return text;
    }
}