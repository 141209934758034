import { Button, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useUserId } from '@nhost/react';
import { roles } from '../atoms';
import { SingleColumnContainer } from '../components/Containers';
import { CustomComboBox } from '../components/Input';
import { useGetUserQuery, useUpdateUserAccountDataMutation } from '../generated/graphql';
import { cityList } from '../globalVariables';
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { redirect, useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';


const ProfileSettings = ({submitLabel}:{submitLabel:string}) => {
    const id = useUserId();
    const getUserQuery = useGetUserQuery({ variables: { id } });
    const user = getUserQuery.data?.user || undefined;
    const [selectedRole, setSelectedRole] = useState((user && (roles.find(r=>r.value===user.users_user_data?.role)||roles[0]))||roles[0])
    const [selectedLocation, setLocation] = useState((user && (user.users_user_data?.address||''))||'');
    const [updateUser, ] = useUpdateUserAccountDataMutation();
    const navigate = useNavigate();
  
    const submitUserData = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        await updateUser({
          variables: {
            id: user?.id,
            role: selectedRole.value,
            address: selectedLocation
          }
        });
        toast.success("Profile updated \n You'll be redirected to the dashboard");
        // wait for 1 second
        await new Promise(r => setTimeout(r, 1200));
        navigate('/dashboard');
        window.location.reload();
      }
      catch (e) {
        console.log('error', e)
      }
  
    }
  return (
    <form onSubmit={submitUserData}>
            <div className="gap-8 relative flex flex-col w-72 pt-5">
                
                <CustomComboBox filterValue={selectedRole} filterValueSetter={setSelectedRole} options={roles} label="Your role" />
                <div className="relative gap-1 flex flex-col">
                    <label className="text-custom-black text-base font-medium leading-snug">Your location</label>

                    <Autocomplete 
                        id='location'
                        options={cityList.sort((a,b)=>a.localeCompare(b))}
                        value={selectedLocation}
                        onChange={(e,v) => setLocation(v||'')}
                        renderInput={(params) => <TextField {...params} required  />}                        
                    />
                </div>
                    <Button
                        variant='contained'
                        type="submit"
                        disabled={getUserQuery.loading}
                        >
                        {getUserQuery.loading ? <ClipLoader /> : submitLabel}
                    </Button>

            </div>
            <p className="text-warning text-base text-center self-stretch">{getUserQuery.error?.message}</p>
          </form>
  )
}

export default ProfileSettings