import {useNhostClient} from '@nhost/react'
import { useGetAllCoursesQuery, useGetAllLearningObjectivesCoursesMatchesQuery, useGetAllUnitsCoursesQuery, useGetUserQuery} from '../generated/graphql';
import UnitCourseMatchTable from '../components/admin/UnitsCourseMatchTable';
import LOCoursesMatchTable from '../components/admin/LOCoursesMatchTable';
import React from 'react';

const AdminMatches = () => {
    const {auth} = useNhostClient();
    const user = auth.getUser();
    const userQuery = useGetUserQuery({variables: {id: user?.id}});
    const allUnitsCourses = useGetAllUnitsCoursesQuery();
    const allLearningObjectivesCoursesMatches = useGetAllLearningObjectivesCoursesMatchesQuery();
    const allCourses = useGetAllCoursesQuery();

    if( userQuery.data?.user?.users_user_data?.admin === true) {
        return <div className='w-full h-screen relative flex flex-col gap-10 p-5 pt-12 pb-24 overflow-auto bg-slate-100'>

        <UnitCourseMatchTable
        onUpdate={()=>{allUnitsCourses.refetch(); allCourses.refetch();}}
        Query={allUnitsCourses} />
    
        <LOCoursesMatchTable
        onUpdate={()=>{allLearningObjectivesCoursesMatches.refetch(); allCourses.refetch();}}
        Query={allLearningObjectivesCoursesMatches} />

     </div>

        
    }
    else {
        return <div> You're not allowed to access this page</div> 
    }

}

export default AdminMatches;