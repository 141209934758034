import { lazy, Suspense } from 'react';
import { GeolocateControl, MapContext, NavigationControl } from  '@urbica/react-map-gl'
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import React from 'react';
import { GetMatchedCoursesQuery, GetMatchedCoursesQueryHookResult } from 'generated/graphql';
const Sources = lazy(()=>import('./Sources'));
const Layers = lazy(()=>import('./Layers'));

export function waitForFeatures(func: ()=>MapboxGeoJSONFeature[]|null){
  return new Promise<MapboxGeoJSONFeature[]>((resolve) => {
    (function iter(f: ()=>MapboxGeoJSONFeature[]|null, prev:MapboxGeoJSONFeature[]|null, iterations){
      const features = f();
      if((JSON.stringify(prev)!==JSON.stringify(features) || !features?.length) && iterations<20){
        setTimeout(()=>{if ((features?.length||0)>0)iter(f, features, iterations+1)}, 250)
      }else{
        // console.log('b')
        resolve(features||[])
      }
    })(func, [], 0)
  })
}

export function waitForLoaded(func: ()=>boolean){

  return new Promise<boolean>((resolve) => {
    (function iter(f: ()=>boolean, iterations:number){
      const isLoaded = f();
      if(!isLoaded && iterations<20){
        setTimeout(()=>iter(f, iterations+1), 250)
      }else{
        resolve(true)
      }
    })(func, 0)
  })
}

export default function MapContents({courseList}: {courseList: GetMatchedCoursesQuery['matched_courses']}){
  return <>
    <Suspense fallback={null}>
      <MapContext.Consumer>
        {(map) => map && <>
          <Suspense fallback={null}>
            <Sources {...{map}} courseList={courseList}/>
          </Suspense>
          <Suspense fallback={null}>
            <Layers {...{map}}/>
          </Suspense>
        </> }
      </MapContext.Consumer>
    </Suspense>
  </>
}