import React from 'react'
import { Tooltip } from '@mui/material';
import toast from 'react-hot-toast'

const SupportEmailLink = ({who="odt", method='mailto'}:{who?:'odt'|'enginuity', method?:'mailto'|'clipboard'}) => {
const supportemail = (who==='enginuity')?"automotiveplatform@enginuity.org":"support@oxdatatech.com";
const actionLabel = method==='mailto'?'Send us an email':'Copy to clipboard';
const copyToClipboard = (evt: React.MouseEvent<HTMLAnchorElement>) => {
    evt.preventDefault(); 
    navigator.clipboard.writeText(supportemail)
    toast.success('Copied to clipboard', { position: 'bottom-center' });
  }
const mailTo = (evt: React.MouseEvent<HTMLAnchorElement>) => {
  evt.preventDefault();
  window.location.href = "mailto:"+supportemail;
  navigator.clipboard.writeText(supportemail)
  toast.success('Copied to clipboard', { position: 'bottom-center' });
} 
  return (
    <Tooltip title={actionLabel} arrow>
        <a href={"mailto:"+supportemail} onClick={method==='mailto'?mailTo:copyToClipboard} className="px-1.5 text-blue-500 hover:font-semibold">
        {supportemail}
        </a>
    </Tooltip>
  )
}

export default SupportEmailLink