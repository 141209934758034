import React, {useEffect, useState} from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {Accordion, AccordionDetails, AccordionSummary, Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { GetAllCoursesQueryResult, GetUnitsCountQueryResult, GetAllUnitsQueryResult, useCheckUnitQuery, GetAllUnitsCoursesQueryResult } from '../../generated/graphql';
import { useDeleteCourseMutation, useInsertUnitCourseMutation, useInsertLearningObjectiveCourseMutation, useCheckCourseQuery } from '../../generated/graphql';
import { shortenText } from 'components/Utils';

//variable #top10 #topX
const amountOfUnitsToCompareToCourseForLOCourseMatch = 6;
export const simplifyUrl = (url: string) => {
    const splitted =  url.replace('http://', '').replace('https://', '').replace('www.', '').split('/')
    if( splitted.length > 1)
        return splitted[0]+'/...'
    else
        return splitted[0]
}
type CoursesTableProps = {
    onUpdate: () => Promise<any>;
   AllCoursesQuery: GetAllCoursesQueryResult;
   AllUnitsQuery: GetAllUnitsQueryResult;
    AllUnitsCourses: GetAllUnitsCoursesQueryResult;
    UnitCountQuery: GetUnitsCountQueryResult
}

const CoursesTable = ({onUpdate, AllCoursesQuery, AllUnitsQuery, AllUnitsCourses, UnitCountQuery}: CoursesTableProps) => {
    const allCourses =AllCoursesQuery;
    const allUnits = AllUnitsQuery;
    const unitsCount = UnitCountQuery;
    const allUnitsCourses = AllUnitsCourses;

    const [hoveredCourse, setHoveredCourse] = useState('');
    const checkHoveredCourse = useCheckCourseQuery({variables: {title: hoveredCourse}})

    const [comparedCourseTitleForUnitMatch, setComparedCourseTitleForUnitMatch] = useState(''); 
    const comparedCourse = useCheckCourseQuery({variables: {title: comparedCourseTitleForUnitMatch}})
    const [insertUnitCourseMatch, ] = useInsertUnitCourseMutation();
    const [insertLearningObjectiveCourseMatch, ] = useInsertLearningObjectiveCourseMutation();
    
    const [deleteCourse, setDeleteCourse] = useState('');
    const [deleteCourseMutation,] = useDeleteCourseMutation();


    const compareCourseToAllUnits = async (courseTitle: string, courseDesc: string) => {
        let APIRequestCount = 0;
        console.log('course:', courseTitle)
        console.log('description:', courseDesc)
        const text_a = courseDesc.replaceAll('&', ' and ');
        console.log('number of units:', allUnits.data?.units?.length)
        await Promise.all(allUnits.data?.units?.map(async (unit, index) => {
            const text_b = 'Learning objectives: '+unit.units_learning_objectives.map(v=>v.name).join(', ').replaceAll('&', ' and ')
            try {
                const res =await fetch('https://search.foresighter.ai/automotive-comparer', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                        // 'Content-Type': 'application/json'
                    },
                    //TO DO: sometimes we can use the unit specification instead of the learning objectives if we don't have them
                    // body: JSON.stringify({text_a: text_a, text_b: text_b})
                    body: ('text_a='+text_a+'&text_b='+text_b)
                })
                ++APIRequestCount;
                const responseScore = await res.json()
                console.log('inserting:'+index)
                console.log(shortenText(text_a, 100))
                console.log('unit:'+unit.name)
                console.log(text_b)
                console.log(responseScore)
                insertUnitCourseMatch({
                    variables: {
                        unit: unit.name,
                        course: courseTitle,
                        matching_quality: responseScore
                    }
                })
                
                
            }
            catch (e) {
                console.log('error', e)
            }
        })??[])
        await allUnitsCourses.refetch()

        //Now we want to match the Course with individual LOs
        //But let's match the course only with if it is in the units Top10

        //For every unit
        await Promise.all(allUnits.data?.units?.map(async (unit, index) => {
            // let's get the top 10 course of this unit
            const topXCourses = allUnitsCourses.data?.units_courses?.filter(v=>v.unit===unit.name).sort((a,b)=>b.matching_quality-a.matching_quality).slice(0,amountOfUnitsToCompareToCourseForLOCourseMatch) //Could be smarter
            console.log('top X Courses: ', topXCourses?.map(v=>v.course))
            // if the compared course is in the top 10
            if ((topXCourses?.filter(v=>v.course===courseTitle)?.length||0) >0){
                // let's compare the LOs of the unit with the course
                unit.units_learning_objectives.map(async(lo)=>{
                    try {
                        const res =await fetch('https://search.foresighter.ai/automotive-comparer', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            body: ('text_a='+text_a+'&text_b='+lo.name)
                        })
                        ++APIRequestCount;
                        const responseScore = await res.json()
                        console.log('inserting:'+index)
                        console.log(shortenText(text_a, 100))
                        console.log('lo:'+lo.name)
                        console.log(responseScore)
                        insertLearningObjectiveCourseMatch({
                            variables: {
                                learning_objective: lo.name,
                                course: courseTitle,
                                score: responseScore
                            }
                        })

                    } catch (e) {
                        console.log('error', e)
                    }
                })
            }
        })??[])

        // allLearningObjectivesCoursesMatches.refetch()
        await onUpdate()
        allCourses.refetch();
        toast.success('Course "'+courseTitle+'" compared to all units, and relevant learning objectives!\n' + APIRequestCount + ' requests')
    }



    // when we change compared course, let's compare it to all units
    useEffect(() => {
        if( comparedCourse.data?.check?.length === 1){
            const title = comparedCourse.data?.check?.[0]?.title
            const summaryAndDescription = ((comparedCourse.data?.check?.[0]?.summary||'')+(comparedCourse.data?.check?.[0]?.description_txt||'no content'))
            console.log('comparing course to all units:', title, summaryAndDescription)
            compareCourseToAllUnits(title, summaryAndDescription)
            //clear
            setComparedCourseTitleForUnitMatch('')
        }
    }, [comparedCourse])
    
    // when we delete a course
    useEffect(() => {
        if (deleteCourse !== ''){
            try{
                deleteCourseMutation({variables: {title: deleteCourse}}).then((res)=>{
                    onUpdate();
                    toast.success('Course "'+deleteCourse+'" deleted')})
            } catch (e) {
                console.log('error', e)
                toast.error('Error deleting course "'+deleteCourse+'"')
            }

            setDeleteCourse('');
        }
    }, [deleteCourse, deleteCourseMutation ])

  return (
    <Accordion>
      <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
        <span className="text-base font-bold">Courses ({AllCoursesQuery.data?.courses_aggregate?.aggregate?.count})</span>
      </AccordionSummary>
      <AccordionDetails>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Qualif. lvl</TableCell>
                    <TableCell>Guided Learning H.</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Provider</TableCell>
                    <TableCell>URL</TableCell>
                    <TableCell>Units compared</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {allCourses.data?.courses?.map((v,i) => (
                    <Tooltip key={i}  title={(v.title+': '+checkHoveredCourse.data?.check?.[0]?.description_txt??'no content')}>
                        <TableRow onMouseOver={()=>setHoveredCourse(v.title)}>
                            <TableCell>{v.title}</TableCell>
                            <TableCell>{v.type}</TableCell>
                            <TableCell>{v.qualification_level}</TableCell>
                            <TableCell>{v.guided_learning_hours}</TableCell>
                            <TableCell>{v.location}</TableCell>
                            <TableCell>{v.provider}</TableCell>
                            <TableCell>{simplifyUrl(v.url||'')}</TableCell>
                            <TableCell>
                                <div className="flex flex-row gap-2">

                                {/* how many units were compared to this course / total amount of units */}
                                <span>
                                    {v.courses_units_courses_aggregate.aggregate?.count}/{unitsCount.data?.units_aggregate.aggregate?.count}
                                </span> 
                                    <Button variant="naked" startIcon={<AutoFixHighIcon/>} onClick={()=>{setComparedCourseTitleForUnitMatch(v.title); console.log('MAGIC')}}/>
                                </div>
                            </TableCell>
                            <TableCell>
                                <Button 
                                variant='naked' 
                                onClick={()=>setDeleteCourse(v.title)} startIcon={<DeleteIcon/>} />
                            </TableCell>
                        </TableRow>
                    </Tooltip>
                ))}
            </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}

export default CoursesTable