
import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useDeleteLearningObjectivesUnitsMutation, useDeleteLearningObjectiveMutation  } from '../../generated/graphql';
import {Accordion, AccordionDetails, AccordionSummary, Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetAllLearningObjectivesQueryResult } from '../../generated/graphql';

type LearningObjectivesTableProps = {
    onUpdate: () => void;
    Query: GetAllLearningObjectivesQueryResult;
}

const LearningObjectivesTable = ({onUpdate, Query}: LearningObjectivesTableProps) => {
    const allLearningObjectives = Query;

    const [deleteLOUnit_LO, setDeleteLOUnit_LO] = useState('');
    const [deleteLOUnit_Unit, setDeleteLOUnit_Unit] = useState('');
    const [deleteLO, setDeleteLO] = useState('');
    const [deleteLearningObjectivesUnits,] = useDeleteLearningObjectivesUnitsMutation(); 
    const [deleteLearningObjective,] = useDeleteLearningObjectiveMutation();
    useEffect(() => {
        if (deleteLOUnit_LO !== ''){
            try{
                deleteLearningObjectivesUnits({variables: {name: deleteLOUnit_LO, unit: deleteLOUnit_Unit}}).then((res)=>{
                    onUpdate();
                    // allLearningObjectives.refetch();
                    // allLearningObjectivesCoursesMatches.refetch();
                    toast.success('Learning objective "'+deleteLOUnit_LO+'" deleted')})
            } catch (e) {
                console.log('error', e)
                toast.error('Error deleting learning objective "'+deleteLOUnit_LO+'"')
            }

            setDeleteLOUnit_LO('');
        }
    }, [deleteLOUnit_LO, deleteLearningObjectivesUnits ])
    useEffect(() => {
        if (deleteLO !== ''){
            try{
                deleteLearningObjective({variables: {name: deleteLO}}).then((res)=>{
                    onUpdate();
                    toast.success('Learning objective "'+deleteLO+'" deleted')})
            } catch (e) {
                console.log('error', e)
                toast.error('Error deleting learning objective "'+deleteLO+'"')
            }

            setDeleteLO('');
        }
    }, [deleteLO, deleteLearningObjective ])

  return (
    <Accordion>
        <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
            <span className="text-base font-bold">Learning objectives({allLearningObjectives.data?.learning_objectives_aggregate.aggregate?.count})</span>
        </AccordionSummary>
        <AccordionDetails>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Unit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allLearningObjectives.data?.learning_objectives?.map((v,i) => (
                        <TableRow key={i}>
                            <TableCell>{v.name}</TableCell>
                            <TableCell>
                                <ul>
                                    {v.learning_objectives_learning_objectives_units.map((u,i) => 
                                    <li key={i} className='flex flex-row gap-5'> <Tooltip title={u.unit} ><span>{u.unit?.split('-')[0]}</span></Tooltip>
                                    <Button 
                                    variant='naked' 
                                    onClick={()=>{setDeleteLOUnit_LO(v.name); setDeleteLOUnit_Unit(u.unit)}} startIcon={<DeleteIcon/>} />
                                    </li>)}
                                </ul>
                            </TableCell>
                            <TableCell>
                                <Button 
                                variant='naked' 
                                onClick={()=>{setDeleteLO(v.name)}} startIcon={<DeleteIcon/>} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </AccordionDetails>
    </Accordion>
  )
}

export default LearningObjectivesTable