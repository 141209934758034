import React from 'react'
import { useChangePassword } from '@nhost/react'
import Input from '../Input';
import { useNhostClient } from '@nhost/react';
import { SingleColumnContainer } from '../Containers';
import { FormEvent, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Button } from '@mui/material';

const ResetPwd = () => {
    const { changePassword, isSuccess, isError, error } =
    useChangePassword()
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
  console.log({ isSuccess, isError, error })
  
  const handleFormSubmit = async (e:FormEvent) => {
    e.preventDefault();
    await changePassword(password);
    navigate('/sign-in');
  }
  return (
    <SingleColumnContainer title="Change your password" descriptions={['']}>
        {/* <div className='w-[450px] flex flex-col gap-10'> */}
        <form onSubmit={handleFormSubmit}  className="self-stretch h-32 flex-col justify-start items-center gap-5 flex">
            <div className="self-stretch h-16 flex-col justify-start items-start gap-1 flex">

                <Input
                type="password"
                label="New password"
                placeholder="New Password*"
                value={password}
                onChange={(e: FormEvent<HTMLFormElement>) =>
                    setPassword((e.target as HTMLTextAreaElement).value)
                }
                required
                />
            </div>
            <Button variant="contained" type="submit">
                Change Password
            </Button>
            {isError ? (
                <p className="text-warning text-base text-center self-stretch">{error?.message}</p>
            ) : null}
        </form>
        {/* </div> */}

    </SingleColumnContainer>
  )
}

export default ResetPwd