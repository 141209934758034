import { useContext, useEffect, useRef, useState } from 'react'
import { Map as MapType, CustomLayerInterface } from 'mapbox-gl'
import Map from '../Map'
import BestMatchedPinIcon from '../../assets/icons/map_pins/best-stroke.png';
import WeaklyMatchedPinIcon from '../../assets/icons/map_pins/weakly-stroke.png';
import UnmatchedPinIcon from '../../assets/icons/map_pins/unmatched-stroke.png';
import DoublePinIcon from '../../assets/icons/map_pins/double.png';
import TriplePinIcon from '../../assets/icons/map_pins/triple.png';
import QuadruplePinIcon from '../../assets/icons/map_pins/quadruple.png';
import ManyPinIcon from '../../assets/icons/map_pins/many.png';

type MarkerSVG = CustomLayerInterface & {
  offset: number,
  width:number,
  height: number,
  data: any,
  context?: CanvasRenderingContext2D|null,
}

const loadMarker = (map:MapType, Marker:string, name:string, width?:number )=>{
  const img = new Image();
  img.onload = function() {
    map.addImage(name, img);

  }
  img.src = Marker;
}

export default function LoadIcons () {
  const loadedRef = useRef(false)
  const [loaded, setLoaded] = useState(false)
  const context = useContext(Map.Context) as any

  useEffect(()=>{
    if(context !== null && context.map){
      
      const map = context.map
      if(map && !loaded && !loadedRef.current ){
        loadMarker(map, BestMatchedPinIcon, 'bestmatched-marker')
        loadMarker(map, WeaklyMatchedPinIcon, 'weaklymatched-marker')
        loadMarker(map, UnmatchedPinIcon, 'unmatched-marker')
        loadMarker(map, DoublePinIcon, 'double-marker')
        loadMarker(map, TriplePinIcon, 'triple-marker')
        loadMarker(map, QuadruplePinIcon, 'quadruple-marker')
        loadMarker(map, ManyPinIcon, 'many-marker')
        setLoaded(true) 
        loadedRef.current = true
        // console.log("icons loaded")
      }
    }
  },[context.map])

  return null
}