import styles from '../../styles/components/SignUp.module.css';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { FormEvent, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Input from '../Input';
import { useNhostClient, useSignInEmailPassword } from '@nhost/react';
// import ClipLoader from 'react-spinners/ClipLoader';
import { APP_TITLE } from '../../globalVariables';
// import { useGetUserLazyQuery } from 'generated/graphql';
// import { useRecoilState } from 'recoil';
// import {user} from '../atoms';
import { useNavigate } from "react-router-dom";
import { SingleColumnContainer } from 'components/Containers';
import { FullWidthButton } from 'styles/styled';
import ClipLoader from 'react-spinners/ClipLoader';

const SignIn = () => {
  const nhost = useNhostClient();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {
    signInEmailPassword,
    isLoading,
    isSuccess,
    needsEmailVerification,
    isError,
    error,
  } = useSignInEmailPassword();
  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    signInEmailPassword(email, password);
  };

  const handleGoogleSSO = () => {
    nhost.auth.signIn({provider: 'google'});
  }
  const handleMicrosoftSSO = () => {
    nhost.auth.signIn({provider: 'windowslive'});
  }

  if (isSuccess) {
    return <Navigate to="/dashboard" replace />;
  }
  const disableForm = isLoading;// || needsEmailVerification;

  return (
    <SingleColumnContainer title="Log In" descriptions={['']}>
      
      <div className='w-[450px] flex flex-col gap-10'>
        {/* Email and password */}
        <form onSubmit={handleOnSubmit} className='flex flex-col w-full gap-5'>
          <Input
            type="email"
            label="Email address"
            placeholder="Email address*"
            value={email}
            onChange={(e: FormEvent<HTMLFormElement>) =>
              setEmail((e.target as HTMLTextAreaElement).value)
            }
            required
            disabled={disableForm}
            />
          <Input
            type="password"
            label="Password"
            placeholder="Password*"
            value={password}
            onChange={(e: FormEvent<HTMLFormElement>) =>
              setPassword((e.target as HTMLTextAreaElement).value)
            }
            required
            disabled={disableForm}
            />
          <FullWidthButton
          variant='contained'
            type="submit"
            disabled={disableForm}
            >
            {isLoading ? <ClipLoader /> : 'Log in'}
          </FullWidthButton>
          {isError ? (
            <p className="text-warning text-base text-center self-stretch">{error?.message}</p>
            ) : null}
          <Link to="/forgot-password" className="font-semibold hover:text-brand text-center">
            Forgot password?
          </Link>
          <p className="text-base font-medium text-center pt-5">Or log in with</p>

            {/* SSO */}
            <FullWidthButton variant='outlined' startIcon={<GoogleIcon/>} onClick={handleGoogleSSO}>
              Continue with Google 
            </FullWidthButton>
            <FullWidthButton variant='outlined' startIcon={<MicrosoftIcon/>} onClick={handleMicrosoftSSO}>
              Continue with Microsoft
            </FullWidthButton>
        </form>

        {/* sign up */}
        <p className="text-custom-black text-center">
          Don't have an account?{' '}
          <Link to="/sign-up" className="font-semibold hover:text-brand text-center">
            {isLoading ? <ClipLoader /> : 'Sign up'}
          </Link>
        </p>
      </div>
    </SingleColumnContainer>

  );
};

export default SignIn;

