import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import styled from 'styled-components';

export const PanelButton = styled(Button) `
    //w-96 self-stretch p-6 bg-blue-50 rounded flex-col justify-start items-start gap-1 inline-flex border-brand border-2 hover:border
    display: flex;
    flex-direction: column;
    padding: 24px;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    gap: 8px;
    border: 1px solid var(--color-neutral-color-20);
    background-color: var(--color-neutral-color-20);
    
    &:hover {
        /* background-color: ; */
        border: 1px solid var(--color-brand);
    }
`;

export const FullWidthButton = styled(Button) `
    display: flex;
    align-self: stretch; 

`;

export const OutlinedIconButton = styled(IconButton) `
    color: var(--color-brand);
    border: 1px solid var(--color-brand);
    border-radius: 8px;
    padding: 8px;
    &:hover {
        color: var(--color-brand-dark);
        border: 1px solid var(--color-brand-dark);
    }
`;