import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useState, useEffect } from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import toast from 'react-hot-toast';
import { GetAllCapabilitiesQueryResult, useDeleteCapabilityMutation } from '../../generated/graphql'
type CapabilitiesTableProps = {
    onUpdate: () => void;
    Query: GetAllCapabilitiesQueryResult;
}
const CapabilitiesTable = ({onUpdate, Query}:CapabilitiesTableProps) => {
  const allCapabilities = Query;
  const [deleteCapabilityName, setDeleteCapabilityName] = useState('');
  const [deleteCapabilityUnit, setDeleteCapabilityUnit] = useState('');
  const [deleteCapabilityMutation,] = useDeleteCapabilityMutation();

  useEffect(() => {
    if (deleteCapabilityName !== '' && deleteCapabilityUnit !== ''){
      try{
        deleteCapabilityMutation({variables: {name: deleteCapabilityName, unit:deleteCapabilityUnit}}).then((res)=>{
          onUpdate();
          toast.success('Capability "'+deleteCapabilityName+'/'+deleteCapabilityUnit+'" deleted')})
      } catch(e) {
        console.log('error', e)
        toast.error('Error deleting capability "'+deleteCapabilityName+'/'+deleteCapabilityUnit+'"')
      }
      setDeleteCapabilityName('');
      setDeleteCapabilityUnit('');
    }
  }, [deleteCapabilityName, deleteCapabilityUnit, deleteCapabilityMutation ])
  
  return (
      // <div className="flex flex-col gap-2 bg-white rounded-lg p-5">
    <Accordion>
      <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
        <span className="text-base font-bold">Capabilities ({allCapabilities.data?.capabilities_aggregate.aggregate?.count})</span>
      </AccordionSummary>
      <AccordionDetails>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Capability</TableCell>
            <TableCell>Unit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {allCapabilities.data?.capabilities?.map((v,i) => (
            <TableRow key={i}> 
              <TableCell>{v.name}</TableCell>
              <TableCell>{v.unit}</TableCell>
              <TableCell>
                <Button 
                  variant='naked' 
                  onClick={()=>{setDeleteCapabilityName(v.name); setDeleteCapabilityUnit(v.unit)}} startIcon={<DeleteIcon/>} />
              </TableCell>
            </TableRow>))}
        </TableBody>
      </Table>
      </AccordionDetails>
    </Accordion>
    // </div>
    )
}

export default CapabilitiesTable