import { Tab } from '@headlessui/react'
import { QueryOptions,  recoilSelectedQuery, recoilShowInputs, recoilUnit } from 'atoms';
import { APP_TITLE } from 'globalVariables'
import React, { createRef, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import CourseSearch from './CourseSearch'
import CourseResult from './CourseResult'
import UnitResult from './UnitResult'
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { Button, IconButton } from '@mui/material';
import {PanelButton} from 'styles/styled';
import { roles } from 'atoms';
import { useNhostClient } from '@nhost/react';
import { useGetUserQuery } from 'generated/graphql';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
const profileOptions = roles.map(p=>p.value);//['employer', 'training provider', 'skills broker']


export const queryOptionTree = {
'employer':[
      { name: 'find course', queryMethod:'Select', resultType: 'Course', label: 'Search for existing courses', description: 'Find Future Skills Needs on how to reskill and/or upskill your workforce, review sample teaching materials and find courses near you.'},
    ],
'skill broker':[
  { name: 'find course', queryMethod:'Select', resultType: 'Course', label: 'Search for existing courses', description: 'Find Future Skills Needs on how to reskill and/or upskill your workforce, review sample teaching materials and find courses near you.'},

],
'training provider':[
    { name: 'create course', queryMethod:'Select',  resultType: 'UnitDisplay',  label: 'Create a new Course', description: 'Find instruction on how to develop a new short course and download the teaching materials.'},
    { name: 'compare course', queryMethod:'Search', resultType: 'UnitCompare',  label: 'Check my Course', description: 'Paste the text of a short course specification into the AI Assistant and see how it compares to the Future Skills Needs.'},
    { name: 'find course', queryMethod:'Select', resultType: 'Course', label: 'Search for existing courses', description: 'Search for short courses that match the Future Skills Needs.'},
],
'all':[
  
]
} as {[key: string] : QueryOptions}

const Body = () => {
  const [selectedQuery, setSelectedQuery] = useRecoilState(recoilSelectedQuery);
  const [showInputs, setShowInputs]  = useRecoilState(recoilShowInputs);
  const {auth} = useNhostClient();
  const user = auth.getUser();
  const userQuery = useGetUserQuery({variables: {id: user?.id??''}});
  const profile = userQuery.data?.user?.users_user_data?.role||profileOptions[0];
  const topRef = createRef<HTMLDivElement>();


  const scrollToTitle = () => {
        topRef.current?.scrollIntoView({ behavior: "smooth", block: 'start' })
  }
  useEffect(() => {
    scrollToTitle()
  }, [selectedQuery, showInputs])
  useEffect(()=>{
    if (selectedQuery === undefined && queryOptionTree[profile].length === 1)
      setSelectedQuery(queryOptionTree[profile][0]);
  }, [selectedQuery, profile])
  return (
    <div  className="flex-grow flex-col flex overflow-auto">
      <span ref={topRef}/>
    {/* Go back button to show inputs*/}
    {!showInputs &&
    <div className="w-full bg-neutral-color-20 p-1.5 flex-row justify-start items-start gap-2.5 inline-flex">
      <Button size='small' startIcon={<ArrowBackIosNewIcon/>} onClick={()=>setShowInputs(true)} className="justify-start items-center inline-flex">
          <div className="text-center text-base font-medium">back to search</div>
      </Button>
    </div>
    }

    {/* search/results */}
    <div className="flex-grow flex justify-start items-center flex-col ">
      {selectedQuery===undefined 
      ?(
      <div className='bg-custom-neutral-50 px-10 py-5 flex-col justify-start items-center gap-8 flex '>

        <>

          <div className="justify-start items-start gap-5 flex flex-wrap flex-row">
          {
            queryOptionTree[profile].length>1 &&
          queryOptionTree[profile].map((query) => (
                <PanelButton key={query.name} className="w-96 self-stretch" variant='panel'
                onClick={()=>setSelectedQuery(query)}>
                  <div className="p-5 justify-center flex flex-col text-left gap-1">

                  <div className="text-custom-black text-base font-">{query.label}</div>
                  <div className="self-stretch text-custom-black text-base font-normal leading-snug">
                    {query.description}
                  </div>
                  </div>
                </PanelButton>)

                  )}
            
            </div>
            
        </>
      </div>
        )
      :(
        <div className='flex flex-col w-full flex-grow justify-start'>
          <CourseSearch heading={selectedQuery.label} visible={showInputs} searchMode={selectedQuery.queryMethod==='Search'}/>
          {!showInputs &&
          ((selectedQuery.resultType==='Course')?<CourseResult/>:<UnitResult comparisonMode={(selectedQuery.resultType==='UnitCompare')}/>) }    
        </div>
      )}
    </div>

    </div>
  )
}

export default Body