import MapGL, {
  AttributionControl,
  Layer,
  MapContext,
  NavigationControl,
  GeolocateControl,
  FullscreenControl,
} from '@urbica/react-map-gl';
import { WebMercatorViewport } from 'viewport-mercator-project';
import { LngLat, type LngLatBoundsLike, type Map as MapType } from 'mapbox-gl';
import { useRecoilState } from 'recoil';
import {recoilViewport, recoilCursor, recoilCoursesOnSharedLocation, recoilSelectedCourse, defaultViewport, recoilZoomedIn, rankedCourse} from 'atoms';
import LoadIcons from './MapContents/LoadIcons';
import 'mapbox-gl/dist/mapbox-gl.css';
import {
  ReactNode,
  startTransition,
  useContext,
  useEffect,
  useState,
} from 'react';
import CourseItem from './CourseItem';
import ContextProvider from './MapContents/ContentProvider';
import { GetMatchedCoursesQuery,  } from 'generated/graphql';
import { Accordion, AccordionSummary, AccordionDetails, Button, Dialog, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import toast from 'react-hot-toast';

export default function Map({ children, courseList }: { children?: ReactNode, courseList: GetMatchedCoursesQuery['matched_courses']}) {
  const [viewport, setViewport] = useRecoilState(recoilViewport);
  const [cursor] = useRecoilState(recoilCursor);
  const [selectedCourse, setSelectedCourse] = useRecoilState(recoilSelectedCourse);
  const [loaded, setLoaded] = useState(false);
  const isExpandedCourseOnline = (courseList.filter(match=>match.course===selectedCourse?.title)?.[0]?.course_data?.location==="online") as boolean
  useEffect(() => {
    //either zooms on expand to list onload
    console.log("mounting component")
    // if (loaded)
    // {
    //   console.log("loaded")
      if (selectedCourse!==undefined){
        console.log("zooming to expanded course on mount ", selectedCourse?.title)  
        zoomToSelectedCourse();
      }
      else
        expandViewToCourseList();
    // }
    // else
    //   console.log("not loaded")
    return () => {
      setViewport(defaultViewport);
      setLoaded(false);
      setSelectedCourse(undefined);
      console.log('cleanup')
    };
  }, []); //or without loaded //TO CHECK

  useEffect(()=>{
    if (selectedCourse!==undefined){
      console.log("zooming to expanded course on mount ", selectedCourse?.title)  
      zoomToSelectedCourse();
    }
  }, [selectedCourse])

  const zoomToSelectedCourse = () => {
    
    const lat = courseList.filter(match=>match.course===selectedCourse?.title)?.[0]?.course_data.courses_location?.lat||0 as number;
    const lng = courseList.filter(match=>match.course===selectedCourse?.title)?.[0]?.course_data.courses_location?.lng||0 as number;
    
    if (lat !==0 && lng !==0)
    {      
      console.log('zooming to selected course', lat, lng)
      setViewport({
              latitude: lat,
              longitude: lng,
              zoom: 17,
              viewportChangeMethod: 'flyTo',
          })
    }else{
      // toast.error('cant zoom to undefined location')
      console.log('cant zoom to undefined location')
    }
  }


  const expandViewToCourseList = () => {
    console.log('expanding view to course list')
    expandView(courseList.filter(c=>c.course_data.location!=='online').map(c=>({
      lat: c.course_data?.courses_location?.lat||0 as number, 
      lng: c.course_data?.courses_location?.lng||0 as number
      }))||[])
  }
  const handleClick = (e: any) => {
    if (!loaded){
      e.preventDefault();
      console.log('click blocked, map not loaded yet')
    }else{
      console.log('click', e);

    }

  }
  const expandView = (courseListLocations: {lat: number, lng: number}[]) => {
    console.log('expanding view')
    if (courseListLocations.length<1) return undefined;
    if (courseListLocations.length===1) {
      console.log('viewport ->',courseListLocations[0].lat, courseListLocations[0].lng,)
      setViewport({
        ...viewport,
        latitude: courseListLocations[0].lat,
        longitude: courseListLocations[0].lng,
        zoom: 17,
      });
      return undefined;
    }else{
      const lat = courseListLocations.map(loc=>loc.lat);
      const lng = courseListLocations.map(loc=>loc.lng);
      const minLat = Math.min(...lat);
      const maxLat = Math.max(...lat);
      const minLng = Math.min(...lng);
      const maxLng = Math.max(...lng);
  
      const newViewport = new WebMercatorViewport({...viewport, width: window.innerWidth-240, height: window.innerHeight-320}).fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: {top: 50, left: 100, right:380, bottom:180} }
      );
      setViewport({
        ...viewport,
        latitude: newViewport.latitude,
        longitude: newViewport.longitude,
        zoom: newViewport.zoom,
      });

    }
  }
  
  const onlineCourses = courseList?.map(
    (c,i)=>{
      if (c.course_data.location!=='online') return undefined; 
      return {rankedCourse: {title: c.course, ranking: (i+1)}, provider: c.course_data.provider}
    }).filter(x=>x!==undefined)||[] as {rankedCourse: rankedCourse, provider: string}[];
  
  return (
    <div className="flex-grow w-full bg-custom-white relative overflow-hidden" >
      {selectedCourse!==undefined &&
      <div className="absolute right-5 top-5 max-w-[calc(40%-50px)] h-[calc(100%-55px)] z-10 bg-white rounded-lg">
          <div className="flex flex-col w-full h-full gap-1">
            <div className="flex flex-row justify-end pt-2 px-2 w-full">
              <IconButton className="w-4 h-4" onClick={()=>setSelectedCourse(undefined)}>
                <CloseIcon/>
                </IconButton>
            </div>
            <div className="overflow-y-auto grow-1">
              <CourseItem mapMode ranking={selectedCourse?.ranking} course={selectedCourse?.title} matching_quality={courseList.filter(match=>match.matching_quality>.6)?.[0]?.matching_quality||0 as number}/>
            </div>

          </div>
      </div>
      }
      {onlineCourses.length>0 && <div className="absolute bottom-9 left-36 max-w-[480px] z-30 rounded-lg ">
        <Accordion >
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <span className='text-base font-semibold'>Matched Online Courses</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex flex-col gap-2 p-0 items-stretch justify-start max-h-[calc(100vh-470px)] overflow-y-auto">
              {onlineCourses.map((course, i)=>
                <Button variant='list' key={i} 
                onClick={()=>{setSelectedCourse(course?.rankedCourse)}}>
                  <div className="flex flex-col text-left w-full border-neutral-100 border-b ">
                    <span className="text-neutral-600 text-sm font-medium">
                      {"#"}{course?.rankedCourse?.ranking}{" Matched Course across all unit Learning Objectives"}
                    </span>
                    <span className="text-custom-black text-[15px] font-semibold">
                      {course?.rankedCourse?.title}
                    </span>
                    <span className="text-custom-black text-sm font-medium">
                      {course?.provider}
                    </span>
                  </div>
                </Button>
              )}
            </div>
          </AccordionDetails>
        </Accordion></div>}
      <SelectCourseOnSharedLocationDialog/>
      <div className="absolute bottom-9 left-14 z-20">

      <Button variant="squared" 
        onClick={()=>expandViewToCourseList()}>
          View all{/* <img src={UKIcon} alt="UK" className="h-6 w-4 opacity-90"/> */}
      </Button>
      </div>
      <MapGL
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          outline: 'none',
        }}
        className='h-[calc(100% - 40px)] bg-transparent'
        accessToken={process.env.REACT_APP_MAPBOX_API_KEY}
        mapStyle={process.env.REACT_APP_MAP_STYLE}
        onViewportChange={(v: any) => setViewport({ ...viewport, ...v })}
        attributionControl={false}
        cursorStyle={cursor}
        minZoom={2}
        refreshExpiredTiles={true}
        onClick={handleClick}
        onLoad={() => {
          startTransition(() => setLoaded(true));
        }}
        
        // hash
        {...viewport}
      >
        {loaded && (
          <>
            {/* expand zoom to england icon button */}
            <ContextSetter />
            <NavigationControl showCompass showZoom position="bottom-left" />
            {/* <GeolocateControl position="bottom-right"  /> */}
            <AttributionControl
              compact={true}
              position="bottom-left"
              customAttribution={`© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>`}
            />
            <>{children}</>
            
            <LoadIcons />
          </>
        )}
      </MapGL>

    </div>
  );
} 

Map.Context = ContextProvider;

function ContextSetter() {
  const map = useContext(MapContext);
  const context = useContext(Map.Context);
  // const [viewport] = useRecoilState(recoilViewport);
//   const [label, setLabel] = useRecoilState(atoms.label);


//following not clear, taken from wcty:
  useEffect(() => {
    if (map) {
      context.map = map;
    } else {
      // console.log('unmounting');
      context.map = undefined;
    }
    return () => {
      if (!map) {
        // console.log('unmounting');
        context.map = undefined;
      }
    };
  }, [map, context]);


  return null;
}


const SelectCourseOnSharedLocationDialog = () => {
  const [coursesOnSharedLocation, setCoursesOnSharedLocation] = useRecoilState(recoilCoursesOnSharedLocation)
  const [expandedCourse, setExpandedCourse] = useRecoilState(recoilSelectedCourse);

  const isOpen = coursesOnSharedLocation.length>0;
  return <Dialog  open={isOpen} onClose={()=>setCoursesOnSharedLocation([])}>
      <div className="flex flex-col gap-2 bg-white rounded-lg p-5">
          {/* <span className="text-base font-bold">Select course</span> */}
          {coursesOnSharedLocation.map((course, i)=>
              <Button variant='history' key={i} onClick={()=>{setExpandedCourse(course); setCoursesOnSharedLocation([])}}>
                {`#${course?.ranking} ${course?.title}`}</Button>
              )}
      </div>
      </Dialog>
}


