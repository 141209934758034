import { Button, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useUserId } from '@nhost/react';
import { roles } from 'atoms';
import { SingleColumnContainer } from 'components/Containers';
import { CustomComboBox } from 'components/Input';
import { useGetUserQuery, useUpdateUserAccountDataMutation } from 'generated/graphql';
import { cityList } from 'globalVariables';
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { redirect, useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import ProfileSettings from 'components/ProfileSettings';

const Profile = () => {

  return (
    <div className="h-screen pt-24 flex flex-col justify-around w-full">
      <SingleColumnContainer title={"User profile"} descriptions={[""]}>
          <ProfileSettings submitLabel="Update" />
      </SingleColumnContainer>
         </div>
  )
}

export default Profile