import React, {  } from 'react'
import { useGetAllCoursesQuery, useGetAllLearningObjectivesQuery, useGetAllUnitsQuery, 
    useGetAllLocationsQuery, useGetAllProvidersQuery, useGetAllUnitsCoursesQuery, 
    useGetAllCapabilitiesQuery, useGetAllLearningObjectivesCoursesMatchesQuery, useGetUnitsCountQuery, useGetFaqQuery, useGetCourseCountQuery, useGetAllCoursesDescriptionsQuery} from '../../generated/graphql';
import {Button} from '@mui/material';
import LearningObjectivesTable from './LearningObjectivesTable';
import LocationsTable from './LocationsTable';
import ProvidersTable from './ProvidersTable';
import CoursesTable from './CoursesTable';
import UnitsTable from './UnitsTable';
import CapabilitiesTable from './CapabilitiesTable';
import FaqTable from './FaqTable';
import UsersTable from './UsersTable';

// interface DBDisplayProps {}
// export interface DBDisplayRef {
//     refetchAll: () => void;
//     refetchAllUnits: () => void;
//     refetchAllCourses: () => void;
//     refetchAllProviders: () => void;
//     refetchAllLocations: () => void;
//     refetchAllUnitsCourses: () => void;
//     refetchAllLearningObjectivesCoursesMatches: () => void;
//     refetchAllMaterials: () => void;
//     refetchAllLearningObjectives: () => void;
//     refetchUnitsCount: () => void;
//     refetchAllFaq: () => void;
//     refetchAllCapabilities: () => void;
    
// }

const DBDisplay = (_: any, ref: React.Ref<unknown> | undefined) => {
    // table
    const allLearningObjectives = useGetAllLearningObjectivesQuery();
    const allUnits = useGetAllUnitsQuery();
    const allCourses = useGetAllCoursesQuery();
    const allCoursesDescriptions = useGetAllCoursesDescriptionsQuery();
    const allProviders = useGetAllProvidersQuery();
    const allLocations = useGetAllLocationsQuery();
    const allUnitsCourses = useGetAllUnitsCoursesQuery();
    const allLearningObjectivesCoursesMatches = useGetAllLearningObjectivesCoursesMatchesQuery();
    const allFaq = useGetFaqQuery();
    const allCapabilities = useGetAllCapabilitiesQuery();
    // const learningObjectivesCount = useGetLearningObjectivesCountQuery();
    const unitsCount = useGetUnitsCountQuery();
    const courseCount = useGetCourseCountQuery();

    // useImperativeHandle(ref, () => ({
    //     refetchAll: refetchAll,
    //     refetchAllUnits: allUnits.refetch,
    //     refetchAllCourses: allCourses.refetch,
    //     refetchAllProviders: allProviders.refetch,
    //     refetchAllLocations: allLocations.refetch,
    //     refetchAllUnitsCourses: allUnitsCourses.refetch,
    //     refetchAllLearningObjectivesCoursesMatches: allLearningObjectivesCoursesMatches.refetch,
    //     // refetchAllMaterials: allMaterials.refetch,
    //     refetchAllLearningObjectives: allLearningObjectives.refetch,
    //     refetchUnitsCount: unitsCount.refetch,
    //     refetchAllFaq: allFaq.refetch,
    //     refetchAllCapabilities: allCapabilities.refetch,
    //     refetchCourseCount: courseCount.refetch,
    // }));

    const refetchAll = () => {
        allLearningObjectives.refetch();
        allUnits.refetch();
        allCourses.refetch();
        allProviders.refetch();
        allLocations.refetch();
        allUnitsCourses.refetch();
        allLearningObjectivesCoursesMatches.refetch();
        // allMaterials.refetch();
        allFaq.refetch();
        unitsCount.refetch();
        allCapabilities.refetch();
        courseCount.refetch();
    }
        
  return (
    <div className='w-full relative flex flex-col gap-10 p-5 overflow-auto bg-slate-100'>

                <div className="sticky top-0 flex flex-col justify-center items-center  w-full z-10">
                    <div className="max-w-xs">
                    {/* Refresh button */}
                    <Button variant='outlined' onClick={refetchAll}>Refresh All</Button>
                    </div>
                </div>


                <LocationsTable
                    onUpdate={()=>allLocations.refetch()} 
                    Query={allLocations} />

                <ProvidersTable
                    onUpdate={()=>allProviders.refetch()} 
                    Query={allProviders} />
                
                <CoursesTable
                    onUpdate={async()=>{
                        await allCourses.refetch(); 
                        await allUnitsCourses.refetch(); 
                        await allLearningObjectivesCoursesMatches.refetch()
                        await unitsCount.refetch();
                    }} 
                    AllCoursesQuery={allCourses}
                    AllUnitsQuery={allUnits}
                    AllUnitsCourses={allUnitsCourses}
                    UnitCountQuery={unitsCount}
                    />
                    
                
                <UnitsTable
                    onUpdate={()=>{allUnits.refetch(); allUnitsCourses.refetch(); allCourses.refetch(); allUnits.refetch(); }} 
                    CourseCountQuery={courseCount}
                    AllCoursesDescQuery={allCoursesDescriptions}
                    // AllUnitsCourses={allUnitsCourses}
                    AllLOCoursesMatches={allLearningObjectivesCoursesMatches}
                    Query={allUnits} />

                <LearningObjectivesTable 
                    onUpdate={()=>{allLearningObjectives.refetch();allLearningObjectivesCoursesMatches.refetch()}} 
                    Query={allLearningObjectives} />

                <CapabilitiesTable
                    onUpdate={()=>{allUnits.refetch(); allCapabilities.refetch()}}
                    Query={allCapabilities} />
                {/* <FoldableTable title='Units/Courses match' count={allUnitsCourses.data?.units_courses_aggregate?.aggregate?.count?.toString()||'...'}>
                    <UnitCourseMatchTable
                        onUpdate={()=>{allUnitsCourses.refetch(); allCourses.refetch();}}
                        Query={allUnitsCourses} />
                </FoldableTable> */}
                {/* <UnitCourseMatchTable
                    onUpdate={()=>{allUnitsCourses.refetch(); allCourses.refetch();}}
                    Query={allUnitsCourses} />
                
                <LOCoursesMatchTable
                    onUpdate={()=>{allLearningObjectivesCoursesMatches.refetch(); allCourses.refetch();}}
                    Query={allLearningObjectivesCoursesMatches} /> */}
                <UsersTable/>

                <FaqTable
                    onUpdate={()=>allFaq.refetch()}
                    Query={allFaq} />
            </div>
  )
}

// const FoldableTable = ({title, count, children}:{title: string, count: string, children: ReactNode}) => {
//     const [expand, setExpand] = React.useState(false);
//     const toggleAcordion = () => {
//       setExpand((prev) => !prev);
//     };
//   return (
//     <Accordion expanded={expand} >
//         <AccordionSummary 
//         // expandIcon={<ExpandMoreIcon />}
//         aria-controls="panel1a-content"
//         onClick={toggleAcordion}
//         >
//             <span className="text-base font-bold">{title} ({count})</span>
//         </AccordionSummary>
//         <AccordionDetails>
//             {/* if is expanded render children */}
//             {expand && 
//             children}
//         </AccordionDetails>
//     </Accordion>
//   )
// }

export default React.forwardRef(DBDisplay)