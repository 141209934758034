import React, { useRef, createRef } from 'react'
import { PDFViewer, Document, Page, Text } from '@react-pdf/renderer';
import { BlobProvider } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import {createRoot} from 'react-dom/client';
import {Element,scroller }from 'react-scroll'
import jsPDF from 'jspdf';
// import pdfkit from 'pdfkit';
import {useGetUnitQuery, useIncrementUserFeedbackOnCustomInputMutation }from '../generated/graphql'
import { useRecoilState, useRecoilValue } from 'recoil';
import { recoilUnit, recoilInputTextLOMatch, recoilComparisonInputText, recoilShowInputs } from 'atoms';
import { Accordion, AccordionDetails, AccordionSummary, Button, Modal } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PDFIcon from  '../assets/icons/file_icons/PDF.png'
import DOCXIcon from  '../assets/icons/file_icons/DOCX.png'
import PPTXIcon from  '../assets/icons/file_icons/PPTX.png'
import IMGIcon from '../assets/icons/file_icons/IMG.png'
import DEFAULTIcon from '../assets/icons/file_icons/DEFAULT.png'
import XLSXIcon from '../assets/icons/file_icons/XLSX.png'
import { HasuraStorageClient } from '@nhost/nhost-js';
import { useAccessToken } from '@nhost/react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { IconButton } from '@mui/material';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { formatLO, scaleScoreLO } from './Utils';
import { APP_TITLE } from 'globalVariables';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from 'assets/icons/check-circle-outline.png';
import ExclamationIcon from 'assets/icons/exclamation-thick.png';
import SimilarityDots, { similarityBorder } from './SimilarityDots';

// import { useNavigate } from 'react-router-dom';
const isEven = (num: number) => {
    return num % 2 === 0;
}

export const format = (text: String) => {
    //return a hierarchy of <p> with bold or normal <span>
    //indent depending on 1. 1.2 a. ii. etc
    

    const shortHeaderLabels = [
        'Level:', 'Level (OfQual)',
        'Credit Size', 
        'Date / Ver No',
        "Indicative total study time ( Hrs )",
        "Indicative Guided Learning  time ( Hrs )",
        "Indicative Self-Directed Study ( Hrs )",
    ]

    const longHeaderLabels = [
        "Unit Overview",
        "Prior learning / Knowledge / skills required / assumed ",
        "Suitable for ",
        "Assessment - Format / Indicative Duration",
        "Assessment Method", "Assessment Detail", 
        "Learning Outcomes – on completion of unit learners will :"
    ];

    // we are not doing line returns after those
    const additionalInfoLabels = [
        'Additional information - ',
        'Total Qualification Time Calculations',
        'Theory GLH:',
        'Practical GLH:',
        'Total GLH =',
        'Assessment time:',
        'Total Assessment Time =',
        'Self-Study Time =',
        'Directed Study Time =',
        'Total qualification time:',
        'Credit value:',
        'Tutorial and feedback'
    ]
    //to delete
    const columnLabels = [ "Assessment Criteria The Learner can :", "Indicative Content :"]
    //if we have numbers next to letters with no space we add a space
    text = text?.replace(/([0-9])([A-Z])/g, '$1 $2') || ''
    //replace all " by \n
    text = text.replaceAll('"', '\n')
    //we'll add \n befor every 1.1
    text = text.replace(/[^.]([0-9]{1,2}\.[0-9]{1,2}\s[A-Z])/g, '\n$1')
    //we'll add a line return after every longHeader label
    text = text.replace(new RegExp(longHeaderLabels.join('|'), 'g'), '$&\n')
    // delete column labels
    text = text.replace(new RegExp(columnLabels.join('|'), 'g'), '')
    //we'll add "ASSESSMENT CRITERIA - The Learner can:\n" before every sentence starting by /^\s*1\.1^\s*/
    text = text.replace(/^\s*[0-9]{1,2}\.1\s/gm, '\nAssessment Criteria - The Learner can :\n$&')
    //we'll add "INDICATIVE CONTENT - The Learner will be able to:\n" before every sentence starting by /^\s*a\.\s*/
    text = text.replace(/^\s*a\.\s*/gm, '\nIndicative Content :\n$&')
    //delete all empty lines
    text = text.replace(/^\s*\n/gm, '')
    const sentences = text.split('\n')
    let indent = 0;
    return sentences?.flatMap((sentence, index) => {
        //check if it is second level item of list like 1.1, or 1) - not looking at whitespaces in the beginning
        const isFirstLevel = sentence.match(/^s*[0-9]{1,2}\)/) || sentence.match(/^s*LO[1-9]\s-\s/) 
            || sentence.match(/Indicative content|Assessment criteria/gi );// 1) or LO1 - or INDICATIVE CONTENT or ASSESSMENT CRITERIA
        const isSecondLevel = sentence.match(/^\s*[0-9]{1,2}\.[0-9]{1,2}/) || sentence.match(/^\s*[a-z]\./); //1.1 or a.
        const isThirdLevel =  sentence.match(/^\s*(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})\./i); //is a roman number followed by a dot
        const isFourthLevel = sentence.match(/^\s*(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})\.[a-z]\./i) || sentence.match(/^\s*[0-9].\s/); //i.a. or 1.
        const startsBold = sentence.match(/^--/); //if we have -- it is bold
        const level = isFourthLevel? 4 : isThirdLevel ? 3 : isSecondLevel ? 2 : isFirstLevel ? 1 : 0;
        let pTop=0;
        if (level <= indent && level >0) //if it is a lower level, we are closing all the previous ones
            indent = level-1; 
        if (level===0 && indent >= 1){
            indent = 0; //if we are on a simple text, we are closing all the previous ones to go back to indent 0 and adding ptop of 8px.
            pTop = 8
        }
        if (startsBold){//if it's a bold text, go back to indent 0
            indent = 0;
        }
        
        // if the sentence starts with a longerheader item, add margin top
        if (longHeaderLabels.some((label)=>sentence.startsWith(label))){
            pTop = 10;
        }

        //if we are on fistLevel (so on a 1. Blabla line), let make it semi bold
        //if we are on indent 1 with a level 0 (simple text). let's make it semibold with color gray
        const pClassname = isFirstLevel ? "font-medium" : indent === 1 && level === 0 ? "font-medium text-neutral-600" : "";
        const boldExpressions = [...shortHeaderLabels, ...longHeaderLabels, ...additionalInfoLabels];
        // add -- around all bold expressions in sentence
        sentence = boldExpressions.reduce((acc, cur) => acc.replaceAll(cur, '__'+cur+'__'), sentence)
        const line = <p style={{paddingLeft: 30*indent, paddingTop: pTop}} className={pClassname} key={index}> 
            {/* {sentence.split('--').flatMap((txt, idx)=>(<span className={isEven(idx)?"":"font-bold"} key={idx+':'+index}>{txt}</span>))} */}

            {sentence.split(/--|__/).flatMap((txt, idx)=>(<span className={isEven(idx)?"":"font-bold tracking-wide pt-1"} key={idx+':'+index}>{txt}</span>))}
            {/* -{level}/indent{indent} */}
        </p>
        if (level > indent) //if it is a higher level, we are increasing the indent
            indent = level;
        return line
    })
}


const UnitResult = ({comparisonMode=false}:{comparisonMode?:boolean}) => {
    // const medianScore = .5 //limit between matched and unmatched
    const unitName = useRecoilValue(recoilUnit);
    const comparisonInputText = useRecoilValue(recoilComparisonInputText);
    const inputTextLOMatch = useRecoilValue(recoilInputTextLOMatch);
    // const accessToken = useAccessToken()
    const unit = useGetUnitQuery({variables: {unit: unitName||'' }});
    // const [showInput, setShowInput] = useRecoilState(recoilShowInputs)
    const handleExport = () => {
        const report = new jsPDF('p','mm','a4', true);
        report.setProperties({
            title: unitName,
            subject: unitName,
            author: APP_TITLE,
            keywords: 'generated by '+APP_TITLE,
            creator: 'Oxford Data Technologies Ltd '
        });

        let pWidth = report.internal.pageSize.width;
        let margin = 14
        let scale = (pWidth - margin*2)/1000//srcWidth

        const element = document.querySelector('.exported');
        if (element!=null) {
            const root = createRoot(element)
            root.render(exportedHtml)
            report.html(element as HTMLElement, {margin:margin, autoPaging:'text', html2canvas: {scale:scale,}, callback: function(){
                window.open(report.output('bloburl'));
            }}).then(() => {
                report.save(unitName+'.pdf');
            });
        }
    }
    const exportedHtml= ( unit.data?.units?.[0] ? 
        <div className="flex flex-col">
            <span className='text-2xl font-bold tracking-wide unit-name pb-10'>{unit.data?.units?.[0].name}</span>
            {(unit.data?.units?.[0]?.units_learning_objectives.length>0) &&
            <div className="flex flex-col gap-3 pb-5">
                <span className='text-base font-bold'>Learning  Objectives</span>
                <ul className="flex flex-col list-disc pl-5">
                    {unit.data?.units?.[0]?.units_learning_objectives.map((lo, index) => (
                        <li className="" key={index}>{lo.name}</li>
                    ))}
                </ul>
            </div>}
            <div className="flex flex-col gap-2 max-w-3xl specification">
                {format(unit.data?.units?.[0]?.specification_txt||'')}
            </div>
        </div>
        :<></>)
    

    const displayedHtml = ( unit.data?.units?.[0] ?  
        <div className='w-full flex flex-col relative' >
            
            <div className="h-0 overflow-hidden"><div className="exported"></div></div>
            <div className="justify-start items-start gap-6 p-7 flex flex-col flex-grow self-stretch rounded-lg bg-custom-white">
                
                {/* Unit */}
                <span className='text-2xl font-bold tracking-wide unit-name'>{unit.data?.units?.[0]?.name}</span>
                {/* LO */}
                {/* only if we are not comparing and we actually have LOs*/}
                {((!comparisonMode) && (unit.data?.units?.[0]?.units_learning_objectives.length>0)) &&
                    <div 
                    className="flex flex-col gap-3">
                        <span className='text-base font-bold'>Learning Objectives</span>
                        <ul className="flex flex-col list-disc pl-5">
                            {unit.data?.units?.[0]?.units_learning_objectives.map((lo, index) => (
                                <li className="" key={index}>{lo.name}</li>
                            ))}
                        </ul>
                    </div>
                }

                <div className="flex flex-col gap-3 p-4 rounded-lg bg-neutral-80 self-stretch">
                    {/* Specification */}
                    <div className="flex flex-row justify-between gap-3">
                        <span className='font-bold text-lg '>Unit Specification</span>
                        <div>
                            <Button variant='squared' sx={{padding: '8px 0px'}} onClick={handleExport}>
                                <FileDownloadOutlinedIcon width={20} height={20}/>
                            </Button>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 max-w-4xl specification">
                        {format(unit.data?.units?.[0]?.specification_txt||'')}
                    </div>

                </div>
                {/* Comparison */}
                {/* will not be displayed if there is no matching LO */}
                {comparisonMode && 
                (inputTextLOMatch.length>0) &&
                    <div 
                    // ref={matchedRef}
                     className=" p-4 rounded-2xl flex-col self-stretch justify-center items-start gap-5 inline-flex bg-neutral-80 relative">
                        
                        <div className="self-stretch justify-start items-center inline-flex">
                            <div className="text-lg font-semibold">Matched and Unmatched Learning Objectives</div>
                        </div>
                        {[...inputTextLOMatch].sort((a,b)=>b.score-a.score).map((item, index)=>(
                                    <MatchedLearningObjectiveItem key={index} input_text={comparisonInputText} learning_objective={item.lo} score={scaleScoreLO(item.score)}/> ))
                                }
                        
                    </div>
                  } 

                 

                {/* Material */}
                {unit.data?.units?.[0].file_list?.includes('.') &&
                <div 
                 className="flex flex-col gap-3 p-4 rounded-lg bg-neutral-80 self-stretch relative">
                
                    <div className='flex flex-row justify-between gap-3 relative'>
                        <span className='font-bold'>View Unit Materials</span>
                        <a href={((process.env.REACT_APP_STORAGE_URL||'') + unitName?.split(' ')?.[0] + '.zip')}>
                            <Button variant='squared' sx={{padding: '8px 0px'}} >
                                <FileDownloadOutlinedIcon width={20} height={20}/>
                            </Button>
                        </a>
                    </div>
                    {/* <div className='bg-custom-white rounded-lg flex flex-col gap-3 p-4'> */}
                    <Accordion defaultExpanded>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                            <span className='font-bold'>Check {unitName?.split('-')[0]} - Course Materials</span>

                        </AccordionSummary>
                        <AccordionDetails>
                            <ul className="flex flex-col gap-1">
                                {unit.data?.units?.[0].file_list?.split('\n').filter(file=>file.includes('.')).map((filename, index) => {
                                    const fileext = filename.split('.').pop()
                                    let icon;
                                    switch (fileext) {
                                        case 'pdf':
                                            icon = PDFIcon
                                            break;
                                        case 'docx':
                                            icon = DOCXIcon
                                            break;
                                        case 'pptx':
                                            icon = PPTXIcon
                                            break;
                                        case 'xlsx':
                                            icon = XLSXIcon
                                            break;
                                        case 'jpg':
                                        case 'jpeg':
                                        case 'png':
                                        case 'gif':
                                        case 'bmp':
                                            icon = IMGIcon
                                            break;
                                        default:
                                            icon = DEFAULTIcon
                                    }
                                    
                                    return <li className="self-stretch flex flex-row gap-2 items-start" key={index}>
                                            <img src={icon} alt="file icon" width={24} height={24} /> 
                                            <a className="hover:text-brand" href={process.env.REACT_APP_STORAGE_URL+filename}>
                                                {filename}
                                            </a>
                                        </li>
                                })
                                }
                            </ul>

                        </AccordionDetails>
                    </Accordion>

                    {/* </div> */}
                    
                </div>
                }
            </div>
                <div className="relative h-0"></div>
        </div> :<></>
    );
    return displayedHtml;
}

const MatchedLearningObjectiveItem = ({input_text, learning_objective, score}:{input_text: string, learning_objective:string, score:number}) => {
    // const bestScore = .55;
    return (
        <div className={("flex flex-row items-start justify-between text-gray-950  gap-3 pb-2 w-full max-w-screen-md  "+(similarityBorder(score)))}>
            <div>{formatLO(learning_objective)}</div>
            <SimilarityDots score={score}/>
        </div>
    
      )
}


export default UnitResult