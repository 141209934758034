// import styles from '../../styles/components/SignIn.module.css';
import ClipLoader from 'react-spinners/ClipLoader';
import { FormEvent, useState } from 'react';
import Input from '../Input';
import { useSendVerificationEmail, useSignOut } from '@nhost/react';
import { SingleColumnContainer} from '../Containers';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import SupportEmailLink from 'components/SupportEmailLink';
import { text } from 'stream/consumers';
const Verify = () => {
  const { sendEmail, isLoading, isSent, isError, error } = useSendVerificationEmail();
  const [email, setEmail] = useState('');
  const [emailSentTo, setEmailSentTo] = useState('');
  const { signOut } = useSignOut();
  const navigate = useNavigate();

  const buttonLabel = isLoading?<ClipLoader/>:('Resend');
  const textOnLanding = "Please check your email inbox for a link to verify your email address. This might have been directed to your spam folder."
  const textOnSend = ("We have sent you an email to "+{emailSentTo}+". You might need to check your spam box if you don't see it.")
  const disableForm = isLoading ;
  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    sendEmail(email);
    setEmailSentTo(email);
    setEmail('');
  };

  return (
    <SingleColumnContainer title="Thank you for signing up to the Automotive Reskilling and Upskilling Platform pilot" max_width="md" descriptions={[]}>
        <ul className="text-sm font-normal list-disc">
          <li>
            {isSent?textOnSend:textOnLanding}
          </li>
          <li>
            Once you have verified your email address, an administrator will activate your account. Look out for an activation email to confirm that this process is complete. 
          </li>
          <li>
            Your email address should be verified within 24 hours.
          </li>
          <li>
            If you require any support, please send an email to <SupportEmailLink/>
          </li>
        </ul>
        <form onSubmit={handleOnSubmit} className="self-stretch flex-col justify-start items-center gap-5 flex pt-5">
          <div className="self-stretch h-16 flex-col justify-start items-start gap-1 flex">
            <Input
              type="email"
              label="Email address"
              placeholder="info@yourmail.com"
              value={email}
              onChange={(e: { target: { value: any; }; }) =>
                setEmail(e.target.value) 
              }
              required
              disabled={disableForm}
            />
            
          </div>
        <Button
          variant='contained'
          type="submit"
          disabled={disableForm}

        > {buttonLabel}
        </Button>
        {isError ? (
              <p className="text-warning text-base text-center self-stretch">{error?.message}</p>
            ) : null}
        </form>

        <Button variant="text" onClick={async()=>{await signOut(); navigate('/'); window.location.reload();}}>
        Go back and sign out</Button>
    </SingleColumnContainer>
  )
}

export default Verify