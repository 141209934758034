let APP_TITLE="PLATFORM_ID env var is missing";
const PLATFORM_ID = process.env.REACT_APP_PLATFORM_ID;
if (PLATFORM_ID==="AM"){
  APP_TITLE = 'Automotive Reskilling & Upskilling Platform Pilot';
}
else if (PLATFORM_ID==="EC"){
  APP_TITLE = 'Engineering Construction Reskilling & Upskilling Platform Pilot';
}


export {PLATFORM_ID, APP_TITLE};

export const license_terms_url =
  'https://oxdata-privacy.s3.eu-west-2.amazonaws.com/LicenceTerms.pdf';

export const privacyPolicyURL = "https://oxdata-privacy.s3.eu-west-2.amazonaws.com/PrivacyNotice.pdf";

export const cityList = ["London","Birmingham","Glasgow","Liverpool","Bristol","Manchester","Sheffield","Leeds","Edinburgh","Leicester","Coventry","Bradford","Cardiff","Belfast","Nottingham","Kingston upon Hull","Newcastle upon Tyne","Stoke-on-Trent","Southampton","Derby","Portsmouth","Brighton","Plymouth","Northampton","Reading","Luton","Wolverhampton","Bolton","Aberdeen","Bournemouth","Norwich","Swindon","Swansea","Milton Keynes","Southend-on-Sea","Middlesbrough","Peterborough","Sunderland","Warrington","Huddersfield","Slough","Oxford","York","Poole","Ipswich","Telford","Cambridge","Dundee","Gloucester","Blackpool","Birkenhead","Watford","Sale","Colchester","Newport","Solihull","High Wycombe","Exeter","Gateshead","Blackburn","Cheltenham","Maidstone","Chelmsford","Salford","Basildon","Doncaster","Basingstoke","Worthing","Eastbourne","Crawley","Rochdale","Rotherham","Stockport","Gillingham","Sutton Coldfield","Woking","Wigan","Lincoln","Oldham","Wakefield","St Helens","Worcester","Hemel Hempstead","Bath","Preston","Raleigh","Barnsley","Stevenage","Hastings","Southport","Darlington","Bedford","Halifax","Hartlepool","Chesterfield","Nuneaton","Grimsby","Weston-super-Mare","Chester","St Albans"];
//  ["London", "Manchester", "Birmingham", "Glasgow", "Leeds", "Liverpool", "Bristol", "Southampton/Portsmouth", "Newcastle upon Tyne", "Sheffield", "Belfast", "Brighton", "Leicester", "Edinburgh"]
// export const orderedCityList = ["Belfast", "Birmingham", "Bradford", "Brighton", "Bristol", "Edinburgh", "Glasgow", "Leeds", "Leicester", "Liverpool", "London", "Manchester", "Newcastle upon Tyne", "Sheffield", "Southampton/Portsmouth"]