import React, { ReactNode, useEffect, useState } from 'react'
// import CircleIcon from '@mui/icons-material/Circle';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import TableChartIcon from '@mui/icons-material/TableChart';
import { recoilComparisonInputText, recoilIsNavBarCollapsed, recoilResultMode, recoilSelectedQuery, recoilShowInputs, recoilUnit } from 'atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import Button from '@mui/material/Button';
import { useNhostClient } from '@nhost/react';
import { useDeleteUnitFromHistoryMutation, useGetHistoryQuery } from 'generated/graphql';
import IconLink from './IconLink';
import { recoilSelectedCourse } from 'atoms';
import { useGetUserQuery } from 'generated/graphql';
import { shortenText } from 'components/Utils';
import { queryOptionTree } from './Body';
import PercentIcon from '@mui/icons-material/Percent';
import CustomeHomeIcon from '../assets/icons/home.png';
import { privacyPolicyURL } from 'globalVariables';
import EnginuityIcon from '../assets/enginuity.png';
import WMCAIcon from '../assets/wmca.png';
import DeleteIcon from '../assets/icons/bin.png';
import { IconButton, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';

const NavBar = () => {
    const [, setSelectedQuery] = useRecoilState(recoilSelectedQuery);
    const [, setShowInputs] = useRecoilState(recoilShowInputs);
    const [, setExpandedCourse] = useRecoilState(recoilSelectedCourse);

    // const [isCollapsed, setCollapsed ]= useState(false);
    const [isCollapsed, setCollapsed ]= useRecoilState(recoilIsNavBarCollapsed);

    // load query history
    const {auth} = useNhostClient();
    const user = auth.getUser();
    const userQuery = useGetUserQuery({variables: {id: user?.id}});
    const [resultMode, setResultMode] = useRecoilState(recoilResultMode);

    const queryHistory = useGetHistoryQuery({variables: {user: user?.id??''}});
    const [deleteQueryHistory,] = useDeleteUnitFromHistoryMutation();
    const [, setComparisonInputText] = useRecoilState(recoilComparisonInputText);
    const [currentUnit, setSelectedUnit] = useRecoilState(recoilUnit);

    useEffect(() => {
        // wait for 1.5s
        setTimeout(() => {
            queryHistory.refetch();
        }, 1500);
    }, [currentUnit]);


    const handleHistorySelect = (unit: string, query_label: string|undefined|null, user_input: string|undefined|null) => {
        //find query option
        const queryOption = queryOptionTree['training provider'].find(q=>q.label === query_label);
        setSelectedQuery(queryOption);
        if (queryOption?.queryMethod === 'Search')
            setComparisonInputText(user_input??'')
        setSelectedUnit(unit);
        setExpandedCourse(undefined);
        setShowInputs(false);
        setResultMode('List');
    }

    const handleHistoryDelete = (index: number) => {
        deleteQueryHistory({variables: {index: index}}).then(()=>{
            queryHistory.refetch()
            toast.success('Query deleted from history');
        });
        
    }
  return (
    <div className="h-full p-3 bg-white border-r border-neutral-300 flex-col justify-start items-start gap-3 inline-flex">
        
        {/* Home */}
        <div className="self-stretch justify-between items-center gap-3 inline-flex text-brand">
            <Button  variant={isCollapsed?"naked":undefined} className="justify-start items-center gap-1 flex text-brand lowercase" 

                onClick={()=>{
                    setSelectedQuery(undefined);
                    setExpandedCourse(undefined);
                    setShowInputs(true); 
                    setResultMode('List');
                  }} 
                  startIcon={<img src={CustomeHomeIcon} alt="home" className="w-6 h-6"/>}>
                
                {!isCollapsed&&<div className="text-center text-brand text-lg font-medium leading-snug transf">Home</div>}
            </Button>
            <Button variant="naked" className="p-3 bg-white rounded-lg border text-brand border-brand justify-start items-start gap-1.5 flex"
                startIcon={isCollapsed?<LastPageIcon/>:<FirstPageIcon/>}
                onClick={()=>setCollapsed(!isCollapsed)}
            />
        </div>

        {/* <div className="self-stretch flex flex-1 flex-col justify-between items-center gap-3 "> */}
            
        {/* Query History */}
        <div className="self-stretch flex flex-grow  flex-col justify-start items-start gap-1 overflow-y-auto">
        {!isCollapsed && 
            <>
            <div className="w-52 pt-5 pb-2 border-t border-neutral-300 shrink basis-0 text-custom-black text-base font-semibold leading-tight">
                Query History
            </div>
            <div className="w-52  pb-2 border-b border-neutral-300 self-stretch flex-col justify-start items-start flex gap-2 relative">
                {queryHistory.data?.user_query_history.map(query => (
                    <div className="relative w-full" key={query.index} >
                        <Button variant="history" className="flex flex-col w-full" onClick={()=>handleHistorySelect(query.unit, query.query_label, query.user_input)}>
                            <div className='flex flex-col text-left items-start self-stretch'>
                                <span className='text-xs opacity-60'>{query.query_label}</span>
                                <div className="grow font-normal">
                                    {shortenText(query.unit,50)}
                                </div>
                                {(query.user_input?.length||0)>0&& 
                                <span className="opacity-80 text-xs font-normal">
                                    Input: {shortenText(query.user_input||'no input',50)}
                                </span>}

                            </div>
                        </Button>
                        <div className="absolute right-0 top-0">
                            <Tooltip title="Delete from history" placement="top">

                                <IconButton  onClick={()=>handleHistoryDelete(query.index)}>
                                    <img src={DeleteIcon} className='w-[15px] h-[15px]' alt="delete"/>
                                </IconButton>
                            </Tooltip>
                        </div>

                    </div>
                ))}

            </div> 
            </>
            }
        </div>


        {/* </div> */}
        {/* Links */}
        <div className={"self-stretch flex-col justify-end flex "+(isCollapsed?'items-start':'items-center')}>
            {/* database edit */}
            {( userQuery.data?.user?.users_user_data?.admin === true)?
                <>
                <IconLink collapsed={isCollapsed} label="Insert/update" icon={<StorageOutlinedIcon/>} to="/admin" />
                <IconLink collapsed={isCollapsed} label="DB Summary" icon={<TableChartIcon/>} to="/admin-table" />
                <IconLink collapsed={isCollapsed} label="Matches" icon={<PercentIcon/>} to="/admin-matches" />
                </>
                :null}
            
            <IconLink collapsed={isCollapsed} label="Help/FAQ" icon={<HelpOutlineOutlinedIcon/>} href="/faq"  />
            <IconLink collapsed={isCollapsed} label="Privacy policy" icon={<PolicyOutlinedIcon/>} href={privacyPolicyURL} />
            <IconLink collapsed={isCollapsed} label="Give us feedback" icon={<FeedbackOutlinedIcon/>} href="https://forms.gle/ic23FwSaMz5Z33pL9" />                
        </div>
        {/* credits */}
        {!isCollapsed&&
        <a className='flex flex-col text-base self-stretch pt-2 border-t border-neutral-300' href="https://www.oxdatatech.com/" rel="noreferrer" target='_blank'>
            <span className="font-normal text-neutral-600">Powered by:</span>
            <div className="">
            <span className="font-semibold text-custom-black mb-2">Oxford Data Technologies</span>
            {/* image proportional h 24px */}
            {/* <img src={EnginuityIcon} alt="Enginuity logo" className="h-5 aspect-auto mt-2" /> */}
            {/* <img src={WMCAIcon} alt="WMCA logo" className="h-7 w-auto my-2"/> */}

            </div>
        </a>
        }
    </div>
  )
}

export default NavBar
