import styles from '../../styles/components/SignIn.module.css';
import { FormEvent, useState } from 'react';
import Input from '../Input';
import { useResetPassword} from '@nhost/react';
import { SingleColumnContainer} from '../Containers';
import ClipLoader from 'react-spinners/ClipLoader';
import React from 'react';
import { Button } from '@mui/material';
const ForgotPassword = () => {
  const {resetPassword, isLoading, isSent, isError, error } = useResetPassword();
  const [email, setEmail] = useState('');
  const [emailSentTo, setEmailSentTo] = useState('');

  const buttonLabel = isLoading?<ClipLoader/>:(isSent?'Resend reset link':'Reset password');
  const titleOnLanding = 'Reset your password';
  const titleOnSend = 'Check your email';
  const textOnLanding = ['Just enter the email address you registered and we will send you a link to reset your password']
  const textOnSend = ['We sent an email to '+ emailSentTo,"If you don't get the email soon, check your spam folder"]
  const disableForm = isLoading;
  
  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await resetPassword(email, {redirectTo: 'https://auto-upskill.com/dashboard/change-password'});
    setEmailSentTo(email);
    setEmail('');
  };

  return (
    <SingleColumnContainer title={isSent?titleOnSend:titleOnLanding} descriptions={isSent?textOnSend:textOnLanding}>
            <form onSubmit={handleOnSubmit} className="self-stretch h-32 flex-col justify-start items-center gap-5 flex">
              <div className="self-stretch h-16 flex-col justify-start items-start gap-1 flex">
                <Input
                  type="email"
                  label="Email address"
                  placeholder="info@yourmail.com"
                  value={email}
                  onChange={(e: { target: { value: any; }; }) =>
                    setEmail(e.target.value) 
                  }
                  required
                  disabled={disableForm}
                />
                
              </div>
            <Button
              variant='contained'
              type="submit"
              disabled={disableForm}

            > {buttonLabel}
            </Button>
            {isError ? (
                  <p className="text-warning text-base text-center self-stretch">{error?.message}</p>
                ) : null}
            </form>
        </SingleColumnContainer>
  )
}

export default ForgotPassword