import React, {useEffect, useState} from 'react'
import {Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetFaqQueryResult, useCheckFaqQuery } from '../../generated/graphql';
import { useDeleteFaqMutation } from '../../generated/graphql';
type FaqTableProps = {
    onUpdate: () => void;
    Query: GetFaqQueryResult;
}

const FaqTable = ({onUpdate, Query}:FaqTableProps) => {
    const allFaqs = Query;

    const [hoveredFaq, setHoveredFaq] = useState('');
    const checkHoveredFaq = useCheckFaqQuery({variables: {question: hoveredFaq}})

    const [deleteFaq, setDeleteFaq] = useState('');
    const [deleteFaqMutation,] = useDeleteFaqMutation();

    useEffect(() => {
        if (deleteFaq !== ''){
            try{
                deleteFaqMutation({variables: {question: deleteFaq}}).then((res)=>{
                    onUpdate();
                    toast.success('Faq "'+deleteFaq+'" deleted')})
            } catch (e) {
                console.log('error', e)
                toast.error('Error deleting faq "'+deleteFaq+'"')
            }

            setDeleteFaq('');
        }
    }, [deleteFaq, deleteFaqMutation ])

    
    return (

    <div className="flex flex-col gap-2 bg-white rounded-lg p-5">
    <span className="text-base font-bold">Frequently Asked Questions</span>
    <Table>
        <TableHead>
            <TableRow>
                <TableCell>Question</TableCell>
                <TableCell>Answer</TableCell>
                <TableCell>Order</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {allFaqs.data?.faq?.map((v,i) => (
                <Tooltip key={i} title={(v.question+': '+checkHoveredFaq.data?.faq?.answer??'no content')}>
                    <TableRow key={i}>
                        <TableCell>{v.question}</TableCell>
                        <TableCell>{v.answer}</TableCell>
                        <TableCell>{v.order}</TableCell>
                        <TableCell>
                            <Button 
                            variant='naked' 
                            onClick={()=>setDeleteFaq(v.question)} startIcon={<DeleteIcon/>} />
                        </TableCell>
                    </TableRow>
                </Tooltip>
            ))}
        </TableBody>
    </Table>
</div>


    )
}

export default FaqTable