import React, {useEffect, useState} from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {Accordion, AccordionDetails, AccordionSummary, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import toast from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetAllProvidersQueryResult } from '../../generated/graphql';
import { useDeleteProviderMutation } from '../../generated/graphql';
import { Tab } from '@headlessui/react';
import { simplifyUrl } from './CoursesTable';
type ProvidersTableProps = {
    onUpdate: () => void;
    Query: GetAllProvidersQueryResult;
}

const ProvidersTable = ({onUpdate, Query}: ProvidersTableProps) => {
    const allProviders = Query;

    const [deleteProvider, setDeleteProvider] = useState('');
    const [deleteProviderMutation,] = useDeleteProviderMutation();

    useEffect(() => {
        if (deleteProvider !== ''){
            try{
                deleteProviderMutation({variables: {name: deleteProvider}}).then((res)=>{
                    onUpdate();
                    toast.success('Provider "'+deleteProvider+'" deleted')})
            } catch (e) {
                console.log('error', e)
                toast.error('Error deleting provider "'+deleteProvider+'"')
            }

            setDeleteProvider('');
        }
    }, [deleteProvider, deleteProviderMutation ])
  return (
    <Accordion>
        <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
            <span className="text-base font-bold">Providers ({allProviders.data?.providers_aggregate.aggregate?.count})</span>
        </AccordionSummary>
        <AccordionDetails>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>URL</TableCell>
                        <TableCell>APAR Listed</TableCell>
                        <TableCell>AEB Contract Holder</TableCell>
                        <TableCell>Ofsted rating</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {allProviders.data?.providers?.map((v,i) => (
                        <TableRow key={i}>
                            <TableCell>{v.name}</TableCell>
                            <TableCell>{simplifyUrl(v.url||'')}</TableCell>
                            <TableCell>{(v.apar===true)?'Yes':(v.apar===false)?'No':''}</TableCell>
                            <TableCell>{(v.aeb===true)?'Yes':(v.aeb===false)?'No':''}</TableCell>
                            <TableCell>{v.ofsted?.split(':')[1]||v.ofsted}</TableCell>
                            <TableCell>
                                <Button 
                                variant='naked' 
                                onClick={()=>setDeleteProvider(v.name)} startIcon={<DeleteIcon/>} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </AccordionDetails>
    </Accordion>
  )
}

export default ProvidersTable