import React, {useEffect, useState} from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {Accordion, AccordionDetails, AccordionSummary, Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetAllCoursesDescriptionsQueryResult, GetAllCoursesQuery, GetAllCoursesQueryResult, GetAllLearningObjectivesCoursesMatchesQuery, GetAllLearningObjectivesCoursesMatchesQueryResult, GetAllUnitsCoursesQueryResult, GetAllUnitsQueryResult, GetCourseCountQueryResult, InsertUnitCourseDocument, useCheckUnitQuery, useGetMatchedCoursesQuery, useInsertLearningObjectiveCourseMutation, useInsertUnitCourseMutation } from 'generated/graphql';
import { useDeleteUnitMutation } from 'generated/graphql';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { shortenText } from 'components/Utils';
import { escapingSpecialChars } from 'components/Utils';
type UnitsTableProps = {
  onUpdate: () => void;
  Query: GetAllUnitsQueryResult;
  CourseCountQuery: GetCourseCountQueryResult;
  AllCoursesDescQuery: GetAllCoursesDescriptionsQueryResult;
//   AllUnitsCourses: GetAllUnitsCoursesQueryResult;
    AllLOCoursesMatches: GetAllLearningObjectivesCoursesMatchesQueryResult;
}
type Unit = {name: string, learningObjectives: string[]};
type Courses = {name: string, summary: string, description_txt: string};
const UnitsTable = ({onUpdate, Query, CourseCountQuery, AllCoursesDescQuery, AllLOCoursesMatches}:UnitsTableProps) => {
    const allUnits = Query;
    const courseCount = CourseCountQuery;
    const allCourses = AllCoursesDescQuery;
    const allLearningObjectivesCoursesMatches = AllLOCoursesMatches;
    
    const [hoveredUnit, setHoveredUnit] = useState('');
    const checkHoveredUnit = useCheckUnitQuery({variables: {name: hoveredUnit}})
    const [insertUnitCourseMatch, ] = useInsertUnitCourseMutation();
    const [insertLearningObjectiveCourseMatch, ] = useInsertLearningObjectiveCourseMutation();
    const [comparedUnitForCourseMatch, setComparedUnitForCourseMatch] = useState({name: '', learningObjectives: []} as Unit);
    const [comparedUnitForLOMatch, setComparedUnitForLOMatch] = useState({name: '', learningObjectives: []} as Unit);
    const [deleteUnit, setDeleteUnit] = useState('');
    const [deleteUnitMutation,] = useDeleteUnitMutation();
    const matchedCourses = useGetMatchedCoursesQuery({variables: {unit: comparedUnitForLOMatch.name, limit: 5, threshold: 0.6}});

    const compareAlltoAllCourses = async () => {
        let APIRequestCount = 0;
        await Promise.all(allUnits.data?.units?.map(async (unit, index)=>{
            const text_a = escapingSpecialChars(unit.units_learning_objectives.map(lo=>lo.name).join('. '));
            await Promise.all( allCourses.data?.courses?.map(async (course, index)=>{
                const text_b = escapingSpecialChars(course.summary+( course.description_txt||''));
                try {
                    const res = await fetch('https://search.foresighter.ai/automotive-comparer', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        //TO DO: sometimes we can use the unit specification instead of the learning objectives if we don't have them
                        body: ('text_a='+text_a+'&text_b='+text_b)
                    })
                    ++APIRequestCount;
                    await res.json().then((res)=>{
                        console.log('response: ', res)
                        insertUnitCourseMatch({variables: {unit: unit.name, course: course.title, matching_quality: res}})
                    });
                    return 200;
                }
                catch (e) {
                    console.log('error', e)
                    toast.error('Error comparing unit "'+unit.name+'" to course "'+course.title+'"--text a:'+shortenText(text_a, 100)+'--text b:'+shortenText(text_b, 100)+' ')
                    return 0;
                }
            })??[])
        })??[])
        toast.success(APIRequestCount+' requests sent')
        console.log(' 🏆 APIRequestCount', APIRequestCount)
        matchedCourses.refetch();
        // console.log("🏆"+unit.+APIRequestCount+' requests sent')
    }
    const compareUnitToAllCourses = async (unit: Unit) => {
        let APIRequestCount = 0;
        const text_a = escapingSpecialChars(unit.learningObjectives.join('. '));
        await Promise.all( allCourses.data?.courses?.map(async (course, index)=>{
            const text_b = escapingSpecialChars(course.summary+( course.description_txt||''));
            try {
                const res = await fetch('https://search.foresighter.ai/automotive-comparer', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    //TO DO: sometimes we can use the unit specification instead of the learning objectives if we don't have them
                    body: ('text_a='+text_a+'&text_b='+text_b)
                })
                ++APIRequestCount;
                await res.json().then((res)=>{
                    console.log('response: ', res)
                    insertUnitCourseMatch({variables: {unit: unit.name, course: course.title, matching_quality: res}})
                });
                return 200;
            }
            catch (e) {
                console.log('error', e)
                console.log('texta: ', text_a)
                console.log('textb: ', text_b)
                // console.log('Unit: ', .)')
                toast.error('Error comparing unit "'+unit.name+'" to course "'+course.title+'"--text a:'+shortenText(text_a, 100)+'--text b:'+shortenText(text_b, 100)+' ')
                return 0;
            }
        })??[])
        toast.success(APIRequestCount+' requests sent')
        console.log(' 🏆 APIRequestCount', APIRequestCount)

        matchedCourses.refetch();
        
    }

    //once it's done, let's compare the 5 best courses with individual LOs
    const compareLOsWithCourses = async (unit: Unit, courses: Courses[]) => {
        let APIRequestCount = 0;
        console.log(unit, 'first 5 courses: ', courses.map((c, index)=>c.name))
        const top5courses = courses.map((c, index)=>{
            return {
                name: c.name, 
                text: escapingSpecialChars(c.summary+(c.description_txt||''))
        }
        })
        await Promise.all(unit.learningObjectives.map(async(lo, index)=>{
            const text_a2=escapingSpecialChars(lo);
            await Promise.all(top5courses?.map(async (course, index)=>{
                const text_b2 = course.text;
                try {
                    const res = await fetch('https://search.foresighter.ai/automotive-comparer', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: ('text_a='+text_a2+'&text_b='+text_b2)
                    })
                    ++APIRequestCount;
                    await res.json().then((res)=>{
                        console.log('response: ', res)
                        insertLearningObjectiveCourseMatch({variables: {learning_objective: lo, course: course.name, score: res}})
                    });
                    return 200;
                }
                catch (e) {
                    console.log('error', e)
                    toast.error('Error comparing LO "'+lo+'" to course "'+course.name+'"')
                    return 0;
                }
            })??[])

        })??[])
        await onUpdate();
        toast.success(APIRequestCount+' requests sent')
        console.log(' 🏆 APIRequestCount', APIRequestCount)

    }

    // const compareLOsWithAllCourses = async (unit: Unit, ) => {


    
useEffect(() => {
    if (comparedUnitForCourseMatch.name !== ''){
        toast.success('Comparing unit "'+comparedUnitForCourseMatch.name+'" to all courses')
        compareUnitToAllCourses({name: comparedUnitForCourseMatch.name, learningObjectives: comparedUnitForCourseMatch.learningObjectives})
        setComparedUnitForCourseMatch({name: '', learningObjectives: []})
    }
},[comparedUnitForCourseMatch])
useEffect(() => {
    if (comparedUnitForLOMatch.name !== ''){
        toast.success('Comparing LOs of unit "'+comparedUnitForLOMatch.name+'" to top 5 courses')
        console.log('compared unit', comparedUnitForLOMatch)
        console.log('matched course data', matchedCourses.data?.matched_courses)
        compareLOsWithCourses({name: comparedUnitForLOMatch.name, learningObjectives: comparedUnitForLOMatch.learningObjectives},
            matchedCourses.data?.matched_courses?.map((c)=>{return {name: c.course_data.title||'', summary: c.course_data.summary||'', description_txt: c.course_data.description_txt||''}})??[])
        // setLORate()
        setComparedUnitForLOMatch({name: '', learningObjectives: []})
    }
}, [comparedUnitForLOMatch])
useEffect(() => {
    toast.success('Matched courses updated')
}, [matchedCourses.data])

  useEffect(() => {
      if (deleteUnit !== ''){
          try{
              deleteUnitMutation({variables: {name: deleteUnit}}).then((res)=>{
                  onUpdate();
                  toast.success('Unit "'+deleteUnit+'" deleted')})
          } catch (e) {
              console.log('error', e)
              toast.error('Error deleting unit "'+deleteUnit+'"')
          }

          setDeleteUnit('');
      }
  }, [deleteUnit, deleteUnitMutation ])

  return (
    <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <span className="text-base font-bold">Units ({allUnits.data?.units?.length})</span>
        </AccordionSummary>
        <AccordionDetails>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Unit</TableCell>
                        <TableCell>Topic</TableCell>
                        <TableCell>Capab.</TableCell>
                        <TableCell>Learn. Obj.</TableCell>
                        <TableCell>Courses compared
                            <Button variant='naked' startIcon={<AutoFixHighIcon/>}
                            onClick={()=>compareAlltoAllCourses()}>
                            </Button>
                        </TableCell>
                        <TableCell>LOs compared</TableCell>
                        <TableCell>Files</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allUnits.data?.units?.map((v,i) => (
                        <Tooltip key={i} title={(v.name+': '+checkHoveredUnit.data?.check?.[0]?.specification_txt??'no content')}>
                            <TableRow onMouseOver={()=>setHoveredUnit(v.name)}>
                                <TableCell>{v.name}</TableCell>
                                <TableCell>{v.topic}</TableCell>
                                <TableCell>{v.units_capabilities.length}</TableCell>
                                <TableCell>{v.units_learning_objectives.length}</TableCell>
                                <TableCell>
                                    <div className="flex flex-row gap-2">
                                        <span>
                                            {v.units_units_courses_aggregate.aggregate?.count}/{courseCount.data?.courses_aggregate.aggregate?.count}
                                        </span>
                                        <Button variant='naked' startIcon={<AutoFixHighIcon/>} 
                                        onClick={()=>{setComparedUnitForCourseMatch({name:v.name, learningObjectives:v.units_learning_objectives.map(lo=>lo.name)})}}>
                                            {/* 1-Course Result */}
                                        </Button>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="flex flex-row gap-2">
                                        <span>
                                            {/* how many rows in learning obj course match are from v.units_learning_objectives / (v.units_learning_objectives * courseMatched.length) */}
                                            {
                                                allLearningObjectivesCoursesMatches.data?.learning_objectives_courses_matches.filter(lo_c=>v.units_learning_objectives.map(lo=>lo.name).includes(lo_c.learning_objective)).length
                                                    +'/ ~'+v.units_learning_objectives.length*5
                                                }
                                        </span>
                                        <Button variant='naked' startIcon={<AutoFixHighIcon/>} 
                                        onClick={()=>{setComparedUnitForLOMatch({name:v.name, learningObjectives:v.units_learning_objectives.map(lo=>lo.name)})}}>
                                            {/* 2-Matching LOs */}
                                        </Button>
                                    </div>

                                </TableCell>
                                <TableCell>
                                    {v.file_list?.split('\n').filter(f=>f.includes('.'))?.length||0}
                                </TableCell>
                                <TableCell>
                                    <Button 
                                    variant='naked' 
                                    onClick={()=>setDeleteUnit(v.name)} startIcon={<DeleteIcon/>} />
                                </TableCell>
                            </TableRow>
                        </Tooltip>
                    ))}
                    
                </TableBody>
            </Table>
        </AccordionDetails>

    </Accordion>
    )
}

export default UnitsTable