import styles from '../styles/pages/SignUp.module.css';
import SignIn from '../components/auth/SignIn';
import Verify from '../components/auth/Verify';
import SignUp from '../components/auth/SignUp';
import Activate from '../components/auth/Activate';
import ResetPwd from '../components/auth/ResetPwd';
import ForgotPassword from '../components/auth/ForgotPassword';
import { ReactNode } from 'react';
import { APP_TITLE } from 'globalVariables';
import {Link, useNavigate} from 'react-router-dom';
import { Button } from '@mui/material';

const NotSignedInContainer = ({children, logIn=false, signUp=false}: {children: ReactNode, logIn?: boolean, signUp?:boolean}) => {
  const navigate = useNavigate();

  return (<div className="layout min-h-screen justify-start pt-20 bg-gray-50 flex flex-col font-body text-custom-black overflow-auto">
  <header className="absolute top-0 w-full flex flex-row justify-between bg-brand">
    <Button onClick={()=>navigate('/')} variant='text'>
    <h1 className="title flex flex-col text-left pl-5 pt-3 pb-4 text-custom-white gap-0">
      <span className="text-lg font-semibold">{APP_TITLE}</span>
    </h1>
    </Button>
    <div className="flex flex-row gap-5 pt-3 pb-4 px-5 justify-end">
      {signUp && <Link to="/sign-up" className="text-custom-white font-semibold text-base  px-5 hover:text-white">Sign up</Link>}
      {logIn && <Link to="/sign-in" className="text-custom-white font-semibold text-base px-5 hover:text-white">Log in</Link>}
    </div>
  </header>
  {children}
  </div>)
}

    

export const SignInPage = () => {
  return (
      <NotSignedInContainer signUp>
        <div className="h-full flex flex-col justify-center py-1">
          <SignIn />
        </div>
      </NotSignedInContainer>
  );
};

export const VerifyPage = () => {
  return (
    <NotSignedInContainer>
      <div className="h-full flex flex-col justify-center py-1">
        <Verify/>
      </div>
    </NotSignedInContainer>

    );
};


export const ForgotPasswordPage = () => {
  return (
    <NotSignedInContainer logIn>
      <div className="h-full flex flex-col justify-center py-1">
        <ForgotPassword/>
      </div>
    </NotSignedInContainer>

    );
};

export const ChangePasswordPage = () => {
  return (
    <NotSignedInContainer logIn>
      <div className="h-full flex flex-col justify-center py-1">
        <ResetPwd/>
      </div>
    </NotSignedInContainer>

    );
}

export const SignUpPage = () => {
  return (
    <NotSignedInContainer logIn>

      <div className="h-full flex flex-col justify-center py-1">
        <SignUp />
      </div>
    </NotSignedInContainer>

  );
};

export const ActivatePage = () => {
  return (
    
    <NotSignedInContainer>
      <div className="h-full flex flex-col justify-center py-1">
        <Activate />
      </div>
    </NotSignedInContainer>
  );
}
