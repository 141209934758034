import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { formatLO, reverseScaleScoreLO, scaleScoreCourse, scaleScoreLO, shortenText, similarThreshold, verySimilarThreshold} from './Utils';
import { recoilSelectedCourse, recoilResultMode, recoilUnit } from 'atoms';
import {useGetCourseQuery } from 'generated/graphql';
import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil';
import { text } from 'stream/consumers';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { format } from './UnitResult';
import { APP_TITLE } from 'globalVariables';
import jsPDF from 'jspdf';
import { createRoot } from 'react-dom/client';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CheckIcon from 'assets/icons/check-circle-outline.png';
import ExclamationIcon from 'assets/icons/exclamation-thick.png';
import SimilarityDots, { similarityBorder } from './SimilarityDots';
const bestScore = .7;
// const weaklyScore = .4;
type StaticCourseObject = {
    title: string | undefined,
    provider: string | undefined |null,
    location: string | undefined |null,
    learning_objectives: Array<{learning_objective: string, score: number}> | undefined,
    summary: string | undefined |null,
    description_txt: string | undefined |null,
}

const CourseItem = ({course, ranking, matching_quality, mapMode=false, displayScore=false}:
                    {course:string, ranking?: number, matching_quality: number, mapMode?: boolean, displayScore?:boolean}) => {
    const selectedUnit = useRecoilValue(recoilUnit);
    const courseData = useGetCourseQuery({variables: {course: course, unit: selectedUnit||''}});
    const [, setSelectedCourse] = useRecoilState(recoilSelectedCourse);
    // const [zoomedIn, setZoomedIn] = useRecoilState(recoilZoomedIn);
    const [showMoreLOs, setShowMoreLOs] = useState(false);
    
    //delete all non-alphanumeric characters and make lowercase
    const identifier = course.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

    // all LOS should be all the learning obectives for the selected unit, we'll use the scores to determine the best matches

    const allLOs = courseData.data?.course[0]?.courses_learning_objectives_courses_matches ||[]
      

    const content = (
    <div className={"p-4 rounded justify-start items-start gap-5 flex flex-col border-brand w-100 max-w-[2400px] self-stretch relative " + ((!mapMode)?"bg-neutral-color-20":"bg-white ")} >
        <div className="h-0 overflow-hidden -mb-5"><div className={identifier+'-exported'}></div></div>
        <div className={("flex flex-col w-full gap-5 "+(!mapMode&&"md:flex md:flex-row md:justify-between"))}>
            <div className={`flex flex-col gap-5 ${mapMode?'w-full':'md:w-1/2'} max-w-3xl`}>
                {/* header */}
                <div className="flex flex-col gap-1 ">
                    <span className="font-semibold text-neutral-600 text-base">{ranking&&("#"+ranking)} Matched Course across all unit Learning Objectives <span className="text-blue-600">{displayScore&&(' - '+ Math.round(matching_quality*1000)/10+'%')}</span></span>
                    <span className=" text-lg font-bold">{course}</span>
                    <div className="flex flex-row gap-5 items-center">
                        <div className="flex flex-col gap-0 text-[15px]">
                            {(courseData.data?.course[0].courses_provider?.url) ?
                                    <Tooltip title={("Visit "+courseData.data?.course[0].provider+" website")} placement="left">
                                        <a className="flex flex-row items-center gap-1 text-base font-medium hover:text-brand hover:underline" href={courseData.data?.course[0].courses_provider?.url} target='_blank' rel="noreferrer">
                                            <span>{courseData.data?.course[0].provider}</span>
                                            <OpenInNewIcon width={20} height={20}/>
                                        </a>
                                    </Tooltip>
                                    :
                                    <div className="text-base font-medium leading-tight">
                                        {courseData.data?.course[0].provider}
                                    </div>
                                }
                            {(courseData.data?.course[0].location==="online")?
                                <span>Online</span>:
                                <div className='flex flex-row gap-1'>
                                    <span>{courseData.data?.course[0].location}</span>
                                    {courseData.data?.course[0].courses_location?.address &&
                                    <span>- {courseData.data?.course[0].courses_location?.address}</span>}
                                    {(!mapMode) &&
                                        <Tooltip title="Find course location on the map" placement="bottom">
                                            <Button variant="naked" onClick={()=>{
                                                setSelectedCourse({title: course, ranking: ranking});
                                                }} >
                                                <FmdGoodOutlinedIcon width={20} height={20}/>
                                            </Button>
                                        </Tooltip>
                                        }
                                </div>
                            }                            
                        </div>
                    </div>

                </div>
                
                {/* summary or shortened descriptio*/}
                <div className="flex flex-col self-stretch justify-start items-start gap-5 ">
                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                            
                            <div className="text-lg font-semibold">Course Summary</div>
                            <div className="text-base font-normal leading-snug">
                                {((courseData.data?.course[0].summary||'').length>1) ?
                                    formatText(courseData.data?.course[0].summary||'')
                                    : format(courseData.data?.course[0].description_txt||'')}
                            </div>                
                    </div>
                </div>

                {/* open url in a new browser */}
                {courseData.data?.course[0].url!== undefined &&
                    <div >
                        <Tooltip title="Open course provider website" placement="top">
                            <a href={courseData.data?.course[0].url as string} target='_blank' rel="noreferrer">
                                <Button variant="outlined" >
                                    <OpenInNewIcon width={20} height={20}/>
                                    <span className='pl-2' >Provider Course Details</span>
                                </Button>
                            </a>
                        </Tooltip>
                    </div>
                }
            </div>
            <div className={`flex flex-col items-start gap-2 ${mapMode?'w-full':'md:w-1/2'} max-w-3xl`}>
                {/* Learning objectives */}
                <div className="flex flex-col gap-2 border border-brand rounded-md bg-custom-white p-4 text-gray-950">
                    <span>{course} {" will meet the following training for: "} <b className='font-medium'>{"ESP Unit "}{selectedUnit}</b></span>
                    <div className="flex flex-row font-medium text-[15px] justify-between">
                        <span>Learning Objectives</span>
                        <span className="w-[66px] text-center">Similarity</span>
                    </div>
                    {allLOs
                    .slice(0,4)
                    .map((item, index)=>(
                        <MatchedLearningObjectiveItem key={index} course={course} learning_objective={item.learning_objective} score={scaleScoreLO(item.score)} displayScore={displayScore}/> ))
                    }
                    {(allLOs||[]).length>4 &&
                    <>{showMoreLOs && allLOs.slice(4).map((item, index)=>(
                        <MatchedLearningObjectiveItem key={index} course={course} learning_objective={item.learning_objective} score={scaleScoreLO(item.score)} displayScore={displayScore}/> ))
                    }
                    <div className="flex flex-row justify-start p-3 text-brand text-base">
                        <Button variant="text" onClick={()=>setShowMoreLOs(!showMoreLOs)}>{showMoreLOs?"Show less":"Show more"}</Button>

                    </div>
                    </>}

                </div>
            </div>
        </div>        

    </div>)
    // if all learning objeectives are disimilar, return nothing
    if (allLOs.filter((item)=>scaleScoreLO(item.score)>=similarThreshold).length===0) return <></>
    return content;
}


const formatText = (text: String) => {
    
    
    return text.split('\n').map((item, key) => <span key={key}>{item}<br/></span>)
}

const MatchedLearningObjectiveItem = ({course, learning_objective, score, displayScore=false}:{course: string, learning_objective:string, score:number, displayScore?: boolean}) => {
    
  return (
    <div className={("flex flex-row items-start justify-between text-gray-950  gap-3 pb-2 max-w-[800px]  "+(similarityBorder(score)))}>
        <div className=''>
            {formatLO(learning_objective)} {displayScore && <>
            <span className="text-red-300">- ({Math.round(reverseScaleScoreLO(score)*1000)/10})% {"--> "}</span>
            
            <span className="text-red-500">{Math.round(score*1000)/10}%</span> 
            </>}
        </div>
        <SimilarityDots score={score}/>

    </div>

  )
}


export const MatchingQuality = ({number}:{number: number}) => {
    let label= "Unmatched"
    let borderColor="border-unmatched";
    let tooltip = "0%-59%"
    if (number >=verySimilarThreshold) {
        label = "Best Matched"
        borderColor = "border-matched"//"matched"
        tooltip = "80%-100%"
    }
    else if (number >= similarThreshold) {
        label = "Weakly  Matched"
        borderColor = "border-weakly-matched"
        tooltip = "60%-79%"
    }
    return (
        <div className={"px-2 py-1 rounded border "+borderColor+" justify-start items-center gap-3 flex"}>
            <Tooltip  title={tooltip}>
                <div className=" text-base font-medium leading-tight">{label}</div>
            </Tooltip>
        </div>)

    }

export const CourseItemForExport = ({matchedCourseObject, includeLO}:{matchedCourseObject: StaticCourseObject, includeLO: boolean}) => {
    // const bestLOs = matchedCourseObject?.learning_objectives?.filter((item)=>scaleScoreLO(item.score)>=bestScore)||[];
    // const weakLOs = matchedCourseObject?.learning_objectives?.filter((item)=>((scaleScoreLO(item.score)<bestScore)&&(scaleScoreLO(item.score)>=weaklyScore)))||[];
    // const unmatchedLOs = matchedCourseObject?.learning_objectives?.filter((item)=>scaleScoreLO(item.score)<weaklyScore)||[];

    return (
        <div className="flex flex-col gap-5 mb-10 max-w-full">
            <div className="flex flex-col gap-1">
                <div className=" text-xl font-bold tracking-wide">{matchedCourseObject?.title}</div>
                <div>{matchedCourseObject?.provider}</div>
                <div>{matchedCourseObject?.location}</div>
            </div>
            {(matchedCourseObject?.summary||'').length>1 &&
            <div className="flex flex-col gap-5">
                <div className="text-lg font-bold tracking-wide">Course Summary</div>
                <div className="text-base font-normal ">
                    {formatText(matchedCourseObject?.summary||'')}
                </div>
            </div>
            }
            {(matchedCourseObject?.description_txt||'').length>1 &&
            <div className="flex flex-col gap-5">
                <div className=" text-lg font-bold tracking-wide">Course Description</div>
                <div className="text-base font-normal flex flex-col  ">
                    {formatText(matchedCourseObject?.description_txt||'')}
                </div>
            </div>
            }
            {(matchedCourseObject?.learning_objectives||[]).length>0 &&
            <div className="flex flex-col gap-5 max-w-full">
                <div className=" text-lg font-bold tracking-wide">Matched/Unmatched Learning objectives</div>
                <div className="text-base font-normal flex flex-col  max-w-full">
                    {matchedCourseObject?.learning_objectives?.map((item, index)=>(<MatchedLearningObjectiveItem key={index} course={matchedCourseObject.title||''} learning_objective={item.learning_objective} score={scaleScoreLO(item.score)}/> ))}
                </div>
            </div>}

        </div>
    )
}

export default CourseItem
