import styles from '../styles/pages/Dashboard.module.css';
import NavBar from '../components/NavBar';
import Body from '../components/Body';
import { useRecoilValue } from 'recoil';
import { recoilTourStep } from 'atoms';

const Dashboard = () => {
  const tourStep = useRecoilValue(recoilTourStep);
  return (
    <>
    <div className='flex flex-row w-full h-full overflow-clip '>
      <NavBar/>
      <Body/>
    </div>
    </>
  );
};

export default Dashboard;
