import styles from '../../styles/components/SignIn.module.css';
import React from 'react'
import { APP_TITLE } from '../../globalVariables';
import { useAuthenticationStatus, useUserId } from '@nhost/react';

import { FormEvent, useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import Input from '../Input';
import { useNhostClient } from '@nhost/react';
import { SingleColumnContainer } from 'components/Containers';
import { useSignOut } from '@nhost/react';
import ClipLoader from 'react-spinners/ClipLoader';

import Button from '@mui/material/Button';
const Activate = () => {
  const { isAuthenticated, isLoading } = useAuthenticationStatus();
  const location = useLocation();

  const { signOut } = useSignOut();

  if (isLoading) {
    return (
      <div className="h-full flex flex-col justify-center py-1">
        Loading activation...
        <ClipLoader />
      </div>
    );
  }
  if (isAuthenticated) {
    return (
      <SingleColumnContainer title="Activate your account" descriptions={["Your account is being reviewed and will be activated by your administrator."," You'll be notified by e-mail soon."]}>
      <div className='flex flex-col gap-5'>
        
        <Button onClick={signOut} className="font-semibold hover:text-brand text-center">
          Back to home page</Button>
        <Link to="/" className="font-semibold hover:text-brand text-center"> Reload page</Link>
        {/* sign out */}
      </div>
    </SingleColumnContainer>
  )
  }
  else {
    return <Navigate to="/sign-in" replace />;

  }
}

export default Activate;