import {useNhostClient} from '@nhost/react'
import { useGetUserQuery} from '../generated/graphql';

import DBDisplay from '../components/admin/DBDisplay';


const AdminTables = () => {
    const {auth} = useNhostClient();
    const user = auth.getUser();
    const userQuery = useGetUserQuery({variables: {id: user?.id}});

    if( userQuery.data?.user?.users_user_data?.admin === true) {
        return (
            <div className="absolute top-0 flex mx-auto w-full flex-row gap-5 h-screen overflow-auto py-16">

                <DBDisplay/>
            </div>


        )
    }
    else {
        return <div> You're not allowed to access this page</div> 
    }

}

export default AdminTables;