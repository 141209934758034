import React, { useEffect, useState } from 'react'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Modal, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { Switch } from '@headlessui/react';
import { Button } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { defaultViewport, recoilSelectedCourse, recoilResultMode, recoilUnit, recoilViewport, recoilZoomedIn, recoilShowInputs, recoilTourStep } from 'atoms';
import { useGetMatchedCoursesQuery, useGetUserQuery, useViewFindInfoMutation } from 'generated/graphql';
import CourseItem, { MatchingQuality, CourseItemForExport } from './CourseItem';
import CloseIcon from '@mui/icons-material/Close';
import Map from './Map';
import { APP_TITLE } from 'globalVariables';
import jsPDF from 'jspdf';
import { createRoot } from 'react-dom/client';
import { scaleScoreCourse, reverseScaleScoreCourse, scaleScoreLO, LoScaleFactor  } from './Utils';
import MapContents from './MapContents';
import FilteringModal from './FilteringModal';
import { useUserId } from '@nhost/react';
import SimilarityDots from './SimilarityDots';
import { GetMatchedCoursesQuery } from 'generated/graphql';
import { verySimilarThreshold, similarThreshold } from '../components/Utils'
import UnitResult from './UnitResult';


export const sortCoursesByAmounfOfVerySimilarLOs = (query : GetMatchedCoursesQuery | undefined) => {
  // return courses?.sort((a:any, b:any) => {
    if (query === undefined) return []
    //only if the course has at least one similar LO
    let result = [...query.matched_courses.filter(match=>match.course_data.courses_learning_objectives_courses_matches.filter(m=>m.score>=similarThreshold).length>0)]
    result.sort((a, b) => {
      return b.course_data.courses_learning_objectives_courses_matches.map(
        match=>loSimilarityScore(match.score)).reduce(
          (a,c)=>a+c, 0) - a.course_data.courses_learning_objectives_courses_matches.map(match=>loSimilarityScore(match.score) ).
      reduce((a,c)=>a+c, 0);
    });
    return result as GetMatchedCoursesQuery['matched_courses']

}

const loSimilarityScore = (rawScore:number) => {
  const score = scaleScoreLO(rawScore);
  return score>=verySimilarThreshold?10:score>=similarThreshold?1:0 as number;
}
const CourseResult = () => {
  const maxAmountOfResults = 5;
  const userId = useUserId();
  const userQuery = useGetUserQuery({variables: {id: userId??''}});
  const [viewFindInfo,] = useViewFindInfoMutation({variables: {id: userId??''}});
  const [openUnit, setOpenUnit] = useState(false);

  // const [openMatchingInfo, setOpenMatchingInfo] = useState(false);
  const [resultMode, setResultMode] = useRecoilState(recoilResultMode);
  const [openCompare, setOpenCompare] = useState(false);
  const [openInfo, setOpenInfo] = useState(userQuery.data?.user?.users_user_data?.has_viewed_find_info===false??true);

  const currentUnit = useRecoilValue(recoilUnit);
  const matchedCourses = useGetMatchedCoursesQuery({variables: {unit: currentUnit||'', limit: maxAmountOfResults, threshold: similarThreshold}}); 
  //sorted by LO, and only those with at least one similar LO
  const loSortedCourses = sortCoursesByAmounfOfVerySimilarLOs(matchedCourses.data);
  // const [, setViewport] = useRecoilState(recoilViewport);
  const [filterByLevel, setFilterByLevel] = useState([]as number[]);
  const [filterByDuration, setFilterByDuration] = useState([]as number[])
  const tourStep = useRecoilValue(recoilTourStep);
  const [selectedCourse, setSelectedCourse] = useRecoilState(recoilSelectedCourse);

  // bulkexport html has everything in it, but somestuff will be hidden
  const bulkExportHtml = (
    <div className='flex flex-col gap-20 max-w-[1000px]'>
      <div className="text-2xl font-bold tracking-wide">Results for Unit "{currentUnit}"</div>
      <div className="flex flex-col gap-0 max-w-full">
        {loSortedCourses?.map((matched_course, index)=>{
          return <CourseItemForExport key={index+'export'} 
            includeLO={true}
            matchedCourseObject={{title: matched_course.course_data?.title, 
                    provider: matched_course.course_data?.provider, 
                    location: matched_course.course_data?.location, 
                    summary: matched_course.course_data?.summary, 
                    description_txt: matched_course.course_data?.description_txt,
                    learning_objectives: matched_course.course_data?.courses_learning_objectives_courses_matches,
                  }} />
        }
        )}
      </div>
    </div>
  );
  
  const identifier = 'Results for Unit '+currentUnit?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
  const handleBulkExport = () => {
    const report = new jsPDF('p', 'mm', 'a4', true)
        report.setProperties({
            title: identifier,
            subject: currentUnit,
            author: APP_TITLE,
            keywords: 'generated by '+APP_TITLE,
            creator: 'Oxford Data Technologies Ltd '
        })
        let pWidth = report.internal.pageSize.width;
        // let srcWidth = document.querySelector('.specification')?.scrollWidth || 0;
        let margin = 14
        let scale = (pWidth - margin*2)/1000//srcWidth

        const element = document.querySelector('.bulk-export');
        if (element!=null) {
            const root = createRoot(element)
            //create page for each course
            root.render(bulkExportHtml)
            report.html(element as HTMLElement, {margin:margin, autoPaging: "text", html2canvas: {scale:scale,}, callback: function(){
                // window.open(report.output('bloburl'));
            }}).then(() => {
                report.save(identifier+'.pdf');
            });
        }
    }
  
  const closeFindInfo = async () => {
    setOpenInfo(false);
    await viewFindInfo();
    userQuery.refetch();
  }

  useEffect(() => {
    if (resultMode==='Map'){
      console.log('resultmode: map')
    } else if (resultMode==='List'){
      console.log('resultmode: list')
      setSelectedCourse(undefined)
    }
  }, [resultMode]);

  useEffect(() => { 
    if (selectedCourse!==undefined){
      setResultMode('Map')
    }
  }, [selectedCourse]);

  return (
    <div className="justify-start items-start gap-5 px-5 pb-5 flex flex-col flex-grow self-stretch relative">
      {/* Info modal on build, except when doing product tour */}
      
        <Modal open={openInfo&&(tourStep===-1)} onClose={()=>closeFindInfo()} className='justify-center items-center flex'>
            <div className="p-5 bg-white rounded-lg shadow flex-col justify-start items-start gap-1 inline-flex mx-auto">
                <Button sx={{justifyContent: 'flex-end'}} onClick={()=>closeFindInfo()}>
                    <CloseIcon/>
                </Button>
                <div className="text-lg font-semibold py-4 px-4 w-96 text-center">The courses displayed below have been matched against the learning objectives for the unit selected</div>
                <Button variant="contained" onClick={()=>closeFindInfo()}>OK</Button>
            </div>
        </Modal>
      <div className="h-0 overflow-hidden"><div className="bulk-export"></div></div>
 
      {/* top bar */}
      <div className={`justify-between items-center flex gap-5 flex-row self-stretch relative ${(resultMode==='List')&&'max-w-[2400px]'}`}>
        <div className="flex flex-col gap-5">
          {/* buttons and switches */}
          <div className="flex flex-row items-center justify-start gap-5 ">
            {/* list <-> map switch toggle */}
            {/* only if at least one course has location that's not 'online' and has one similar objective */}
            {(loSortedCourses?.filter(match=>match.course_data?.location!=='online').length||0) > 0 &&
            <Switch checked={resultMode==='List'} 
                onClick={()=>{
                  if (resultMode==='List'){
                    setResultMode('Map')
                  }
                  else {
                    setResultMode('List')
                  }
                }} 
                className="relative p-1 h-13 w-22 bg-white border-brand border rounded-lg justify-start items-start flex"
                >
                  <div className="grid grid-cols-2 w-60 px-2 py-2 gap-4 z-10 ">
                    <div className={((resultMode==='Map'?'text-brand':'text-custom-white')+" flex flex-row gap-1")}>
                      <ListAltOutlinedIcon sx={{color: resultMode==='Map'?'var(--color-brand)':'var(--color-custom-white)'}}/>
                      <span>List view</span>
                    </div>
                    <div className={((resultMode==='List'?'text-brand':'text-custom-white')+" flex flex-row gap-1")}>
                      <FmdGoodOutlinedIcon sx={{color: resultMode==='List'?'var(--color-brand)':'var(--color-custom-white)'}}/>
                      <span>Map view</span>
                    </div>

                  </div>
              <span className="sr-only">Result mode: list/map</span>

              <span aria-hidden="true" className={`${resultMode==='Map'? 'translate-x-full' : 'translate-x-0'}
                          absolute pointer-events-none inline-block h-[40px] w-[calc(50%-4px)] transform rounded-lg bg-brand transition duration-200 ease-in-out`}/>            
            </Switch>}
            <Tooltip title="Export results as PDF" placement="top">

              <Button variant='outlined' onClick={handleBulkExport} >
                <div className="flex flex-row gap-2">
                  <FileDownloadOutlinedIcon width={20} height={20}/>
                  <span className='hidden xl:inline'>Export results</span>

                </div>
              </Button>
            </Tooltip>
            <Tooltip title="Compare results" placement="top">
            <Button variant='outlined' onClick={()=>setOpenCompare(true)} >
                <div className="flex flex-row gap-2">
                  <CompareArrowsOutlinedIcon width={20} height={20}/>
                  <span className='hidden xl:inline'>
                    Compare results
                  </span>
                </div>
            </Button>
            </Tooltip>

          </div>
          {/* Title */}
          <div className={`flex flex-row w-full gap-5 justify-between `}>
            <div className="flex flex-col flex-grow  justify-start items-start gap-2 text-gray-950 font-medium">
                <div className="flex flex-row w-full gap-5 justify-between ">
                    <span className="text-md font-medium ">Short Courses that match to the Learning Objectives of:  </span>
                    
                </div>
                  <span className="text-gray-950 text-lg font-bold">
                    ESP Unit {currentUnit} 
                    {/* add view unit details button */}
                    <span className="ml-5">
                      <Button onClick={(()=>setOpenUnit(true))} className="justify-start items-end pl-4 gap-1" >
                        <InfoOutlinedIcon/>
                        <span className="text-base text-left pt-1 font-medium flex-grow underline">{`View unit details`}</span>
                      </Button>

                    </span>
                  </span>
                  <Modal
                  open={openUnit}
                  onClose={(()=>setOpenUnit(false))}
                  aria-labelledby="specification"
                  className='justify-center items-center flex overflow-y-auto'
                >
                  <div className="p-5 bg-white rounded-lg shadow flex-col justify-start items-start gap-2 inline-flex mx-auto max-w-[1000px] max-h-[calc(100%-40px)] overflow-clip">
                  <Button sx={{justifyContent: 'flex-end'}} onClick={()=>setOpenUnit(false)}>
                    <CloseIcon/>
                  </Button>
                    <div className="text-custom-black text-lg font-semibold">Unit Specification</div>
                    <div className="text-custom-black text-base font-normal leading-snug flex flex-col gap-2 specification px-4 overflow-y-auto">
                      <UnitResult/>
                      {/* {format(unitDetails.data?.units?.[0]?.specification_txt||'')} */}
                    </div>
                  </div>
                </Modal>
                <div className="text-md ">Matched courses: {loSortedCourses?.length+' '} <span className='opacity-50 font-normal'>/ Maximum of 5 short courses displayed</span>
                  {(userQuery.data?.user?.users_user_data?.admin === true) && 
                    <div className="text-red-500 flex flex-row gap-5 ">
                      Learning Objective scaling factor: {LoScaleFactor}. Thresholds:  {"    <"}{similarThreshold*100}% <SimilarityDots score={similarThreshold}/> {"<"} {verySimilarThreshold*100}% <SimilarityDots score={verySimilarThreshold}/>
                    </div>
                  }
                </div>
            </div>


          </div>

        </div>
         
        {/* Match infobox */}
        <div className="bg-zinc-100 rounded-lg flex flex-col gap-5 p-2 max-w-48 lg:max-w-52 xl:max-w-64 " > 
          <div className="flex flex-row gap-2">
                
            <InfoOutlinedIcon width={20} height={20} className='text-inherit'/>
            <div className="flex flex-col gap-2 text-[15px] ">
              <span className="text-custom-black font-medium">
              Similarity between Learning Objectives and Course Content
              </span>
              <div className="flex flex-col items-stretch w-fit">
                <div className="flex flex-row gap-3 justify-between">
                  <span>Very Similar</span>
                  <SimilarityDots score={verySimilarThreshold}/>
                </div>
                <div className="flex flex-row gap-3 justify-between">
                  <span>Similar</span>
                  <SimilarityDots score={similarThreshold}/>
                </div>
                <div className="flex flex-row gap-3 justify-between">
                  <span>Dissimilar</span>
                  <SimilarityDots score={0.4}/>
                </div>
              </div>

            </div>
            {/* for class generation */}
            <span className="hidden bg-very-similar bg-similar bg-dissimilar border-similar border-dissimilar border-very-similar"/>
          </div>
        </div>
      </div>


      {/* List view */}
      {resultMode==='List' &&
      <div className='flex flex-col gap-5 self-stretch'>
        {/* reorder the courses by number of "very simialr learning objectives */}
        {loSortedCourses?.map((matched_course, index)=>{
          return <div key={index}><CourseItem ranking={index+1} course={matched_course.course} 
          matching_quality={matched_course.matching_quality} 
          displayScore={(userQuery.data?.user?.users_user_data?.admin === true)}/>
           </div>;
        }
        )}
      </div>
      }

      {/* Map view */}
      {resultMode==='Map' &&
      <Map courseList={loSortedCourses}>
        <MapContents courseList={loSortedCourses}/>
      </Map>
      }
      
      {/* compare */}
      <Modal
        open={openCompare}
        onClose={(()=>setOpenCompare(false))}
        aria-labelledby="learning-objectives"
        className='justify-center items-center flex'
      >
        <div className="p-10 bg-white rounded-lg shadow flex-col justify-start items-end gap-1 inline-flex mx-auto">
          <Button sx={{justifyContent: 'flex-end'}} onClick={()=>setOpenCompare(false)}>
            <CloseIcon/>
          </Button>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ranking</TableCell>
                <TableCell>Matched Course</TableCell>
                <TableCell>Course Type</TableCell>
                <TableCell>Training Provider</TableCell>
                <TableCell>Location</TableCell>
                <TableCell className='relative'>Qualification Level
                  <FilteringModal 
                  options={loSortedCourses?.map(c=>c.course_data.qualification_level)||[]} 
                  prefix="Level "
                  selected={filterByLevel} 
                  setter={setFilterByLevel}/>
                </TableCell>
                <TableCell className='relative'>
                  Guided Learning Hours
                  <FilteringModal
                    options={loSortedCourses?.map(c=>c.course_data.guided_learning_hours||0)||[]}
                    selected={filterByDuration}
                    setter={setFilterByDuration}/>
                  </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loSortedCourses?.filter(c=>(filterByLevel.includes(c.course_data.qualification_level)||filterByLevel.length===0))
              .filter(c=>(filterByDuration.includes(c.course_data.guided_learning_hours||0)||filterByDuration.length===0))
              .map((matched_course, index)=>(
                <TableRow key={index}>
                  <TableCell>#{index+1}</TableCell>
                  <TableCell>
                    <div className='max-w-[218px]'>
                      {matched_course.course_data.title}
                    </div>
                  </TableCell>
                  <TableCell>{matched_course.course_data.type}</TableCell>
                  <TableCell>{matched_course.course_data.provider}</TableCell>
                  <TableCell>{matched_course.course_data.location}</TableCell>
                  <TableCell>{matched_course.course_data.qualification_level?"Level "+matched_course.course_data.qualification_level:''}</TableCell>
                  <TableCell align="center">{matched_course.course_data.guided_learning_hours?matched_course.course_data.guided_learning_hours:''}</TableCell>
                </TableRow>
                ))}
            </TableBody>
          </Table>
          <div className="inner">


          </div>
        </div>
      </Modal>
    
    
    </div>
  )
}



export default CourseResult