import React, {useEffect, useState} from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {Accordion, AccordionDetails, AccordionSummary, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import toast from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetAllProvidersQueryResult, useGetAllUsersQuery } from '../../generated/graphql';
import { useDeleteProviderMutation } from '../../generated/graphql';
import { Tab } from '@headlessui/react';
import { simplifyUrl } from './CoursesTable';

const UsersTable = () => {
    const allUsers = useGetAllUsersQuery();

  return (
    <Accordion>
    <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
        <span className="text-base font-bold">Users</span>
    </AccordionSummary>
    <AccordionDetails>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>Verified</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Admin</TableCell>
                    <TableCell>Enginuity</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Last seen</TableCell>
                </TableRow>
               
            </TableHead>
            <TableBody>
                {allUsers.data?.users?.map((v,i) => (
                    <TableRow key={i}>

                        <TableCell key={i}>{v.email}</TableCell>
                        <TableCell>{(v.emailVerified===true)?'Verified':(v.emailVerified===false)?'':''}</TableCell>
                        <TableCell>{(v.users_user_data?.activated_arup===true)?'Activated':(v.users_user_data?.activated_arup===false)?'':''}</TableCell>
                        <TableCell>{(v.users_user_data?.admin===true)?'Admin':(v.users_user_data?.admin===false)?'':''}</TableCell>
                        <TableCell>{(v.users_user_data?.enginuity===true)?'Enginuity':(v.users_user_data?.enginuity===false)?'':''}</TableCell>
                        <TableCell>{v.users_user_data?.role}</TableCell>
                        <TableCell>{v.lastSeen}</TableCell>

                    </TableRow>
                ))}    
            </TableBody>
        </Table>
    </AccordionDetails>
    </Accordion>
                  )
}

export default UsersTable