import { FormEvent, useEffect, useState, useRef } from 'react'
import {useFileUpload, useMultipleFilesUpload, useNhostClient} from '@nhost/react'
import { useCheckLocationQuery, useDeleteLearningObjectivesUnitsMutation, useDeleteLearningObjectiveMutation, useDeleteLocationMutation, 
    useDeleteMaterialMutation, useGetFaqLikeQuery, useGetLearningObjectivesLikeQuery, useGetLocationsLikeQuery, useGetUserQuery, useInsertLearningObjectiveMutation, useInsertLearningObjectiveUnitMutation, useInsertLocationMutation, 
    useUpdateLocationMutation} from '../generated/graphql';
import {useGetProvidersLikeQuery, useCheckProviderQuery, useInsertProviderMutation, useUpdateProviderMutation, useDeleteProviderMutation} from '../generated/graphql';
import { useGetCapabilityLikeQuery, useGetTopicsLikeQuery, useCheckCapabilityQuery, useInsertCapabilityMutation, useUpdateCapabilityMutation, useDeleteCapabilityMutation} from '../generated/graphql';
import { useGetUnitsLikeQuery, useCheckUnitQuery, useInsertUnitMutation, useUpdateUnitMutation, useDeleteUnitMutation } from '../generated/graphql';
import { useGetCoursesLikeQuery, useCheckCourseQuery, useInsertCourseMutation, useUpdateCourseMutation, useDeleteCourseMutation } from '../generated/graphql';
import { useCheckUnitCourseMatchQuery, useInsertUnitCourseMutation, useUpdateUnitCourseMutation, useDeleteUnitCourseMutation } from '../generated/graphql';
import { useCheckLearningObjectiveCourseMatchQuery, useInsertLearningObjectiveCourseMutation, useUpdateLearningObjectiveCourseMutation, useDeleteLearningObjectiveCourseMutation } from '../generated/graphql';
import { useGetMaterialsByUnitQuery,  useInsertMaterialMutation } from '../generated/graphql';
import { useUpdateFaqMutation, useInsertFaqMutation, useDeleteFaqMutation, useCheckFaqQuery } from '../generated/graphql';
import Input, {BooleanCheckbox, BinaryCheckbox, TextAreaInput } from '../components/Input';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, TextField, Tooltip} from '@mui/material';
import toast from 'react-hot-toast';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DeleteIcon from '@mui/icons-material/Delete';
import ClipLoader from 'react-spinners/ClipLoader';
import {format} from '../components/UnitResult'
import { shortenText } from 'components/Utils';

const coordToLatLng = (coord:String) => {
    const [lat, lng] = coord.split(',');
    return {lat: parseFloat(lat), lng: parseFloat(lng)}
}
const jsonToText = (json:JSON) => {
    const text = JSON.stringify(json);
    return text;
}
const textToJson = (text:string) => {
    text = text.replace(/(\r\n|\n|\r)/gm, "");
    const json = JSON.parse(text);
    console.log('json', json);
    // return {"example":"test"};
    return json;
}

const inputToNotNullInt = (param: string )=>{
    if (param==='') return 0;
    const value = parseFloat(param)
    return (value===null?0:value);
}

const AdminDashboard = () => {
    const {auth} = useNhostClient();
    const user = auth.getUser();
    const userQuery = useGetUserQuery({variables: {id: user?.id}});

    //FAQ
    const [, setFaq_question] = useState('');
    const [faq_question_Search, setFaq_question_Search] = useState('');
    const faq_question_options = useGetFaqLikeQuery({variables: {question:( '%'+faq_question_Search+'%')}});
    const checkFaq = useCheckFaqQuery({variables: {question: faq_question_Search}});
    const [faq_answer, setFaq_answer] = useState('');
    const [faq_visible, setFaq_visible] = useState(true);
    const [faq_order, setFaq_order] = useState('');

    const [updateFaq, updateFaqMutation] = useUpdateFaqMutation();
    const [insertFaq, insertFaqMutation] = useInsertFaqMutation();
    const [deleteFaq, deleteFaqMutation] = useDeleteFaqMutation();
    const isInsertFaq = (checkFaq.data?.faq === null );
    console.log('check faq data', checkFaq.data?.faq)

    const isUpdateFaq = !isInsertFaq && (checkFaq.data?.faq?.answer!==faq_answer || checkFaq?.data?.faq?.visible!==faq_visible || checkFaq.data?.faq?.order!==faq_order);
    useEffect(() => {
        if (!checkFaq.loading && checkFaq?.data?.faq !== undefined && checkFaq?.data?.faq !== null) {
            setFaq_answer(checkFaq?.data?.faq?.answer||'');
            setFaq_order(checkFaq?.data?.faq?.order+'');
        }
}, [checkFaq.data?.faq, checkFaq.loading])

    const handleUpdateFaq = async (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try{
            if (isUpdateFaq) {
                console.log("is update faq")
                await updateFaq({variables: {question: faq_question_Search, answer: faq_answer, visible: faq_visible, order: faq_order}});
                console.log('data updated', updateFaqMutation.data);
                toast.success('FAQ updated: \nFAQ:'+(faq_question_Search)+' \nanswer:'+faq_answer+' \nvisible:'+faq_visible+' \norder:'+faq_order)
            }
            else if (isInsertFaq){
                await insertFaq({variables: {question: faq_question_Search, answer: faq_answer, visible: faq_visible, order: faq_order}});
                console.log('data inserted', insertFaqMutation.data);
                toast.success('FAQ inserted: \nFAQ:'+(faq_question_Search)+' \nanswer:'+faq_answer+' \nvisible:'+faq_visible+' \norder:'+faq_order)
            }
        }
        catch (e) {
            console.log('error', e)
            toast.error('Error: '+e)
        }
        checkFaq.refetch();
        faq_question_options.refetch();
    }

    const handleDeleteFaq = async () => {
        try {
            await deleteFaq({variables: {question: faq_question_Search}});
            console.log('data deleted', deleteFaqMutation.data);
            toast.success('FAQ deleted: \nFAQ:'+faq_question_Search)
        }
        catch (e) {
            console.log('error', e)
            toast.error('Error: '+e)
        }
        faq_question_options.refetch();
    }


    const [learningObjMultiMode, setLearningObjMultiMode] = useState(false);
    const [, setLearningObj_name] = useState('');
    const [learningObj_name_Search, setlearningObj_name_Search] = useState('');
    const learningObj_name_options = useGetLearningObjectivesLikeQuery({variables: {expr: '%'+learningObj_name_Search+'%'}});
    const [learningObj_unit, setLearningObj_unit] = useState('');
    const [learningObj_unit_Search, setlearningObj_unit_Search] = useState('');
    const learningObj_unit_options = useGetUnitsLikeQuery({variables: {expr: '%'+learningObj_unit_Search+'%'}});
    const [insertLearningObj, insertLearningObjMutation] = useInsertLearningObjectiveMutation();
    const [insertLearningObjUnit, insertLearningObjUnitMutation] = useInsertLearningObjectiveUnitMutation();
    const [deleteLearningObj, deleteLearningObjMutation] = useDeleteLearningObjectivesUnitsMutation();
    const isInsertLearningObjective = true;
    const isUpdateLearningObjective = false;
    const handleDeleteLearningObj = async () => {
        try {
            await deleteLearningObj({variables: {name: learningObj_name_Search, unit: learningObj_unit_Search}});
            console.log('data deleted', deleteLearningObjMutation.data);
            toast.success('Learning objective deleted: \nLO:'+learningObj_name_Search)
        }
        catch (e) {
            console.log('error', e)
            toast.error('Error: '+e)
        }
        // allLearningObjectives.refetch();
        // allLearningObjectivesCoursesMatches.refetch();
        learningObj_name_options.refetch();
    }
    const handleInsertLearningObj = async (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try{
            await insertLearningObj({variables: {name: (learningObj_name_Search)}});
            console.log('data inserted', insertLearningObjMutation.data);
        }
        catch(e){
            toast.success('Learning objective already exists: \nLO:'+(learningObj_name_Search))
        }
        try {
            await  insertLearningObjUnit({variables: {name: (learningObj_name_Search), unit: learningObj_unit}});
            console.log('data inserted', insertLearningObjUnitMutation.data);
            toast.success('Learning objective inserted: \nLO:'+(learningObj_name_Search)+' \nunit:'+learningObj_unit)
        }
        catch (e) {
            console.log('error', e)
            console.log('data', learningObj_name_Search, learningObj_unit);
            toast.error('Error: '+e)
        }
        // allLearningObjectives.refetch();
        // allLearningObjectivesCoursesMatches.refetch();
        learningObj_name_options.refetch();
    }

    // add mulitple learning objectives
    const [learningObj_multiple, setLearningObj_multiple] = useState('');
    const handleInsertMultipleLearningObj = async (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        learningObj_multiple.replaceAll(';','').split('\n').map(async (lo)=>{
            if (lo.length > 2){
                try{
                    // FIRST INSERT THE LO in case it's not there 
                    await insertLearningObj({variables: {name: (lo)}});
                    console.log('data inserted', insertLearningObjMutation.data);
                    
                }
                catch(e){
                    console.log('error', e)
                    toast.error('Error: '+e)
                }
                try{
                    await  insertLearningObjUnit({variables: {name: (lo), unit: learningObj_unit}});
                    console.log('data inserted', insertLearningObjUnitMutation.data);
                    toast.success('Learning objective inserted: \nLO:'+(lo)+' \nunit:'+learningObj_unit)
                }
                catch (e) {
                    console.log('error', e)
                    console.log('data', lo, learningObj_unit);
                    toast.error('Error: '+e)
                }

            }
            
        });
        // checkLearningObjective.refetch();        

    }

    //locations
    const [, setLocation_name] = useState('');
    const [location_name_Search, setlocation_name_Search] = useState('');
    const location_name_options = useGetLocationsLikeQuery({variables: {expr: '%'+location_name_Search+'%'}});
    // address, lat, lng, url. free Input. inputToNotNullInt
    const [updateLocation, updateLocationMutation] = useUpdateLocationMutation();
    const [insertLocation, insertLocationMutation] = useInsertLocationMutation();
    const [deleteLocation, deleteLocationMutation] = useDeleteLocationMutation();
    const checkLocation = useCheckLocationQuery({variables: {name: location_name_Search}});
    const [location_address, setLocation_address] = useState('');
    const [location_coordinates, setLocation_coordinates] = useState('');
    const [location_url, setLocation_url] = useState('');
    const regionOptions = ['West Midlands','North East','North West','East Midlands','East of England','Greater London','South East','South West','Yorkshire and the Humber','Scotland','Wales','Northern Ireland']
    const [location_region, setLocation_region] = useState('');
    const isInsertLocation = !((checkLocation.data?.check?.length??0) > 0);
    const isUpdateLocation = !isInsertLocation && (checkLocation.data?.check?.[0].address!==location_address || checkLocation.data?.check?.[0].lat!==coordToLatLng(location_coordinates).lat || checkLocation.data?.check?.[0].lng!==coordToLatLng(location_coordinates).lng || checkLocation.data?.check?.[0].url!==location_url);
    useEffect(() => {
        if (!checkLocation.loading && checkLocation?.data?.check !== undefined && checkLocation?.data?.check?.length > 0) {
            setLocation_address(checkLocation.data?.check?.[0].address??'');
            setLocation_coordinates((checkLocation.data?.check?.[0].lat??'')+', '+(checkLocation.data?.check?.[0].lng??''));
            setLocation_url(checkLocation.data?.check?.[0].url??'');
            setLocation_region(checkLocation.data?.check?.[0].region??'');
        }
        // console.log('checkLocation', checkLocation.data?.check)
    }, [checkLocation.data?.check, checkLocation.data?.check?.length, checkLocation.loading])
    const handleUpdateLocation = async (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (isUpdateLocation) {
                await updateLocation({variables: {name: (location_name_Search), address: location_address, lat: coordToLatLng(location_coordinates).lat, lng: coordToLatLng(location_coordinates).lng, url: location_url, region: location_region}});
                console.log('data updated', updateLocationMutation.data);
                toast.success('Location updated: \nLocation:'+(location_name_Search)+' \naddress:'+location_address+' \ncoordinates:'+location_coordinates+' \nurl:'+location_url)
            }
            else if (isInsertLocation){
                await insertLocation({variables: {name: (location_name_Search), address: location_address, lat: coordToLatLng(location_coordinates).lat, lng: coordToLatLng(location_coordinates).lng, url: location_url, region: location_region}});
                console.log('data inserted', insertLocationMutation.data);
                toast.success('Location inserted: \nLocation:'+(location_name_Search)+' \naddress:'+location_address+' \ncoordinates:'+location_coordinates+' \nurl:'+location_url)
            }
        }
        catch (e) {
            console.log('error', e)
            toast.error('Error: '+e)
        }
        // allLocations.refetch();
        checkLocation.refetch();
        location_name_options.refetch();
        course_location_options.refetch();
    }
    const handleDeleteLocation = async () => {
        try {
            await deleteLocation({variables: {name: location_name_Search}});
            console.log('data deleted', deleteLocationMutation.data);
            toast.success('Location deleted: \nLocation:'+location_name_Search)
        }
        catch (e) {
            console.log('error', e)
            toast.error('Error: '+e)
        }
        // allLocations.refetch();
        location_name_options.refetch();
        course_location_options.refetch();
    }

    const ofstedRatingOptions = ['Grade 1: Outstanding', 'Grade 2: Good', 'Grade 3: Requires Improvement', 'Grade 4: Inadequate'];    
    const [, setProvider_name] = useState('');
    const [provider_name_Search, setprovider_name_Search] = useState(''); 
    const provider_name_options = useGetProvidersLikeQuery({variables: {expr: '%'+provider_name_Search+'%'}});
    const [provider_ofsted, setProvider_ofsted] = useState('');
    const [provider_apar, setProvider_apar] = useState(null as boolean|null);
    const [provider_aeb, setProvider_aeb] = useState(null as boolean|null);
    const [updateProvider, updateProviderMutation] = useUpdateProviderMutation();
    const [insertProvider, insertProviderMutation] = useInsertProviderMutation();
    const [deleteProvider, deleteProviderMutation] = useDeleteProviderMutation();
    const checkProvider = useCheckProviderQuery({variables: {name: provider_name_Search}});
    const [provider_url, setProvider_url] = useState('');
    const isInsertProvider = !((checkProvider.data?.check?.length??0) > 0);
    const isUpdateProvider = !isInsertProvider && (checkProvider.data?.check?.[0].url!==provider_url || checkProvider.data?.check?.[0].ofsted!==provider_ofsted || checkProvider.data?.check?.[0].apar!==provider_apar || checkProvider.data?.check?.[0].aeb!==provider_aeb);
    useEffect(() => {
        if (!checkProvider.loading && checkProvider?.data?.check !== undefined && checkProvider?.data?.check?.length > 0) {
            setProvider_url(checkProvider.data?.check?.[0].url??'');
            setProvider_ofsted(checkProvider.data?.check?.[0].ofsted||'');
            setProvider_apar(checkProvider.data?.check?.[0].apar??null);
            setProvider_aeb(checkProvider.data?.check?.[0].aeb??null);
        }
        // console.log('checkProvider', checkProvider.data?.check)
    }, [checkProvider.data?.check, checkProvider.data?.check?.length, checkProvider.loading])
    const handleUpdateProvider = async (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (isUpdateProvider) {
                await updateProvider({variables: {name: (provider_name_Search), url: provider_url, ofsted: provider_ofsted, apar: provider_apar, aeb: provider_aeb}});
                console.log('data updated', updateProviderMutation.data);
                toast.success('Provider updated: \nProvider:'+(provider_name_Search)+' \nurl:'+provider_url+' \nofsted:'+provider_ofsted+' \napar:'+provider_apar+' \naeb:'+provider_aeb)
            }
            else if (isInsertProvider){
                console.log('insert provider', provider_name_Search, provider_url, provider_ofsted, provider_apar, provider_aeb)
                await insertProvider({variables: {name: (provider_name_Search), url: provider_url, ofsted: provider_ofsted, apar: provider_apar, aeb: provider_aeb}});
                console.log('data inserted', insertProviderMutation.data);
                toast.success('Provider inserted: \nProvider:'+(provider_name_Search)+' \nurl:'+provider_url+' \nofsted:'+provider_ofsted+' \napar:'+provider_apar+' \naeb:'+provider_aeb)
            }
        }
        catch (e) {
            console.log('error', e)
            toast.error('Error: '+e)
        }
        // allProviders.refetch();  
        checkProvider.refetch();
        provider_name_options.refetch();
        course_provider_options.refetch();
    }
    const handleDeleteProvider = async () => {
        try {
            await deleteProvider({variables: {name: provider_name_Search}});
            console.log('data deleted', deleteProviderMutation.data);
            toast.success('Provider deleted: \nProvider:'+provider_name_Search)
        }
        catch (e) {
            console.log('error', e)
            toast.error('Error: '+e)
        }
        // allProviders.refetch();
        provider_name_options.refetch();
        course_provider_options.refetch();
    }

    //Capabilities
    const [capabilityMultiMode, setCapabilityMultiMode] = useState(false);
    const [, setCapability_name] = useState('');
    const [capability_name_Search, setcapability_name_Search] = useState('');
    const capability_name_options = useGetCapabilityLikeQuery({variables: {expr: '%'+capability_name_Search+'%'}});
    const [, setCapability_unit] = useState('');
    const [capabilityUnit_Search, setCapabilityUnit_Search] = useState('');
    const capabilityUnit_options = useGetUnitsLikeQuery({variables: {expr: '%'+capabilityUnit_Search+'%'}});
    
    const [updateCapability, updateCapabilityMutation] = useUpdateCapabilityMutation();
    const [insertCapability, insertCapabilityMutation] = useInsertCapabilityMutation();
    const [deleteCapability, deleteCapabilityMutation] = useDeleteCapabilityMutation();
    const checkCapability = useCheckCapabilityQuery({variables: {name: capability_name_Search, unit: capabilityUnit_Search}});
    const isInsertCapability = capabilityMultiMode || !(checkCapability.data?.check);
    const isUpdateCapability = false //Capability table has only primary keys

    const handleUpdateCapability = async (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (isUpdateCapability) {
                await updateCapability({variables: {name: (capability_name_Search), unit: capabilityUnit_Search}});
                console.log('data updated', updateCapabilityMutation.data);
                toast.success('Capability updated: \nCapability:'+(capability_name_Search)+' \nunit:'+capabilityUnit_Search)
            }
            else if (isInsertCapability){
                await insertCapability({variables: {name: (capability_name_Search), unit: capabilityUnit_Search}});
                console.log('data inserted', insertCapabilityMutation.data);
                toast.success('Capability inserted: \nCapability:'+(capability_name_Search)+' \nunit:'+capabilityUnit_Search)
            }
        }
        catch (e) {
            console.log('error', e)
            toast.error('Error: '+e)
        }
        // allUnits.refetch();
        checkCapability.refetch();
    }
    const handleDeleteCapability = async () => {
        try {
            await deleteCapability({variables: {name: capability_name_Search, unit: capabilityUnit_Search}});
            console.log('data deleted', deleteCapabilityMutation.data);
            toast.success('Capability deleted: \nCapability:'+capability_name_Search)
        }
        catch (e) {
            console.log('error', e)
            toast.error('Error: '+e)
        }
        // allUnits.refetch();
        capability_name_options.refetch();
    }

    // add multiple capabilities
    const [capability_multiple, setCapability_multiple] = useState('');
    const handleInsertMultipleCapability = async (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log('insert  multiple', capability_multiple)
        capability_multiple.split('\n').map(async (cap)=>{
            if (cap.length > 2){
                try{
                    await  insertCapability({variables: {name: cap, unit: capabilityUnit_Search}});
                    console.log('data inserted', insertCapabilityMutation.data);
                    toast.success('Capability inserted: \nCapability:'+(cap)+' \nunit:'+capabilityUnit_Search)
                }
                catch(e){
                    console.log('error', e)
                    toast.error('Error: '+e)
                }

            }
        });
        checkCapability.refetch();
    }

    // Units
    const [showPreview, setShowPreview] = useState(false)
    const [, setUnit_name] = useState('');
    const [unit_name_Search, setunit_name_Search] = useState('');
    const unit_name_options = useGetUnitsLikeQuery({variables: {expr: '%'+unit_name_Search+'%'}});
    // capability, specification
    const [updateUnit, updateUnitMutation] = useUpdateUnitMutation();
    const [insertUnit, insertUnitMutation] = useInsertUnitMutation();
    const [deleteUnit, deleteUnitMutation] = useDeleteUnitMutation();
    const checkUnit = useCheckUnitQuery({variables: {name: unit_name_Search}});
    // const [unit_topic, setUnit_topic] = useState('');
    const [unit_topic_Search, setUnit_topic_Search] = useState('');
    const unit_topic_options = useGetTopicsLikeQuery({variables: {expr: '%'+unit_topic_Search+'%'}});
    const [unit_specification, setUnit_specification] = useState('');
    const isInsertUnit = !((checkUnit.data?.check?.length??0) > 0);
    const isUpdateUnit = !isInsertUnit; // TODO: compare now that it's raw txt
    useEffect(() => {
        if (!checkUnit.loading && checkUnit?.data?.check !== undefined && checkUnit?.data?.check?.length > 0) {
            setUnit_topic_Search(checkUnit.data?.check?.[0].topic??'');
            // setUnit_topic(checkUnit.data?.check?.[0].topic??'');
            setUnit_specification(checkUnit.data?.check?.[0].specification_txt??'');
        }
        // console.log('checkUnit', checkUnit.data?.check)
    }, [checkUnit.data?.check, checkUnit.data?.check?.length, checkUnit.loading])
    const preFormatUnitSpecification = (specification:string) => {
        return specification.split('\n')
            .map((sentence) => {
                // if number of " is odd in a line, remove them
                const doubleQuotesCount = sentence.split('"').length - 1;
                return (doubleQuotesCount % 2 === 1)?
                    sentence.replaceAll('"', '')
                    :sentence;
            }
            ).join('\n');
    }
    const handleUpdateUnit = async (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const processedUnitSpecification = preFormatUnitSpecification(unit_specification)
        console.log('unit_specification', unit_specification);
        try {
            if (isUpdateUnit) {
                await updateUnit({variables: {name: (unit_name_Search), topic: unit_topic_Search, specification: processedUnitSpecification}});
                console.log('data updated', updateUnitMutation.data);
                toast.success('Unit updated: \nUnit:'+(unit_name_Search)+' \ntopic:'+unit_topic_Search+' \nspecification:'+shortenText(unit_specification, 50))
            }
            else if (isInsertUnit){
                await insertUnit({variables: {name: (unit_name_Search), topic: unit_topic_Search, specification: processedUnitSpecification}});
                console.log('data inserted', insertUnitMutation.data);
                toast.success('Unit inserted: \nUnit:'+(unit_name_Search)+' \ntopic:'+unit_topic_Search+' \nspecification:'+shortenText(unit_specification, 50))
            }
        }
        catch (e) {
            console.log('error', e)
            toast.error('Error: '+e)
        }
        // allUnits.refetch();
        checkUnit.refetch();
        // learningObj_unit_options.refetch();//LUCKY DEBUG
        unit_name_options.refetch();
    }
    const handleDeleteUnit = async () => {
        try {
            await deleteUnit({variables: {name: unit_name_Search}});
            console.log('data deleted', deleteUnitMutation.data);
            toast.success('Unit deleted: \nUnit:'+unit_name_Search)
        }
        catch (e) {
            console.log('error', e)
            toast.error('Error: '+e)
        }
        unit_name_options.refetch();
        // learningObj_unit_options.refetch();//LUCKY DEBUG
    }

    // Courses
    const [course_name, setCourse_name] = useState('');
    const [course_name_Search, setcourse_name_Search] = useState('');
    const course_name_options = useGetCoursesLikeQuery({variables: {expr: '%'+course_name_Search+'%'}});
    // summary, description, type, url, qualification level
    // autocomplete: location, provider
    const [updateCourse, updateCourseMutation] = useUpdateCourseMutation();
    const [insertCourse, insertCourseMutation] = useInsertCourseMutation();
    const [deleteCourse, deleteCourseMutation] = useDeleteCourseMutation();
    const checkCourse = useCheckCourseQuery({variables: {title: course_name}});
    const [course_summary, setCourse_summary] = useState('');
    const [course_description, setCourse_description] = useState('');
    const [course_type, setCourse_type] = useState('');
    const [course_glh, setCourse_glh] = useState('');
    const [course_url, setCourse_url] = useState('');
    const [course_qualification_level, setCourse_qualification_level] = useState('');
    const [course_location, setCourse_location] = useState('');
    const [course_provider, setCourse_provider] = useState('');
    const [course_location_Search, setCourse_location_Search] = useState('');
    const [course_provider_Search, setCourse_provider_Search] = useState('');
    const course_location_options = useGetLocationsLikeQuery({variables: {expr: '%'+course_location_Search+'%'}});
    const course_provider_options = useGetProvidersLikeQuery({variables: {expr: '%'+course_provider_Search+'%'}});
    const isInsertCourse = !((checkCourse.data?.check?.length??0) > 0 && course_name_Search.length > 0);
    const isUpdateCourse = !isInsertCourse// && (checkCourse.data?.check?.[0].summary!==course_summary || checkCourse.data?.check?.[0].description!==course_description || checkCourse.data?.check?.[0].type!==course_type || checkCourse.data?.check?.[0].url!==course_url || checkCourse.data?.check?.[0].qualification_level!==inputToNotNullInt(course_qualification_level) || checkCourse.data?.check?.[0].location!==course_location || checkCourse.data?.check?.[0].provider!==course_provider);
    useEffect(() => {
        if (!checkCourse.loading && checkCourse?.data?.check !== undefined && checkCourse?.data?.check?.length > 0) {
            setCourse_summary(checkCourse.data?.check?.[0].summary??'');
            setCourse_description(checkCourse.data?.check?.[0].description_txt??'');
            setCourse_type(checkCourse.data?.check?.[0].type??'');
            setCourse_url(checkCourse.data?.check?.[0].url??'');
            setCourse_qualification_level((checkCourse.data?.check?.[0].qualification_level+'')??'');
            setCourse_glh((checkCourse.data?.check?.[0].guided_learning_hours+'')??'')
            setCourse_location(checkCourse.data?.check?.[0].location??'');
            setCourse_provider(checkCourse.data?.check?.[0].provider??'');
            setCourse_location_Search(checkCourse.data?.check?.[0].location??'');
            setCourse_provider_Search(checkCourse.data?.check?.[0].provider??'');
        }
        // console.log('checkCourse', checkCourse.data?.check)
    }, [checkCourse.data?.check, checkCourse.data?.check?.length, checkCourse.loading])
    const handleUpdateCourse = async (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log('course_description', course_description);

        try {
            if (isUpdateCourse) {
                await updateCourse({variables: {title: (course_name_Search), summary: course_summary, description: course_description, type: course_type, glh: inputToNotNullInt(course_glh), url: course_url, qualification_level:inputToNotNullInt(course_qualification_level), location: course_location, provider: course_provider}});
                console.log('data updated', updateCourseMutation.data);
                toast.success('Course updated: \nCourse:'+(course_name_Search)+' \nsummary:'+shortenText(course_summary, 50)+' \ndescription:'+shortenText(course_description, 50)+' \ntype:'+course_type+' \nurl:'+course_url+' \nqualification level:'+course_qualification_level+' \nlocation:'+course_location+' \nprovider:'+course_provider)
            }
            else if (isInsertCourse){
                await insertCourse({variables: {title: (course_name_Search), summary: course_summary, description: course_description, type: course_type, glh: inputToNotNullInt(course_glh),  url: course_url, qualification_level: inputToNotNullInt(course_qualification_level), location: course_location, provider: course_provider}});
                console.log('data inserted', insertCourseMutation.data);
                toast.success('Course inserted: \nCourse:'+(course_name_Search)+' \nsummary:'+shortenText(course_summary, 50)+' \ndescription:'+shortenText(course_description, 50)+' \ntype:'+course_type+' \nurl:'+course_url+' \nqualification level:'+course_qualification_level+' \nlocation:'+course_location+' \nprovider:'+course_provider)
            }
        }
        catch (e){
            console.log('error', e)
            toast.error('Error: '+e)
        }
        // allCourses.refetch();
        checkCourse.refetch();
    }
    const handleDeleteCourse = async () => {
        try {
            await deleteCourse({variables: {title: course_name_Search}});
            console.log('data deleted', deleteCourseMutation.data);
            toast.success('Course deleted: \nCourse:'+course_name_Search)
        }
        catch (e) {
            console.log('error', e)
            toast.error('Error: '+e)
        }
        course_name_options.refetch();
    }

    // UnitsCourses
    const [, setUnitCourse_unit] = useState('');
    const [, setUnitCourse_course] = useState('');
    const [unitCourse_unit_Search, setunitCourse_unit_Search] = useState('');
    const [unitCourse_course_Search, setunitCourse_course_Search] = useState('');
    const unitCourse_unit_options = useGetUnitsLikeQuery({variables: {expr: '%'+unitCourse_unit_Search+'%'}});
    const unitCourse_course_options = useGetCoursesLikeQuery({variables: {expr: '%'+unitCourse_course_Search+'%'}});
    const [unitCourseScore, setUnitCourseScore] = useState('0');
    const [insertUnitCourse, insertUnitCourseMutation] = useInsertUnitCourseMutation();
    const [updateUnitCourse, updateUnitCourseMutation] = useUpdateUnitCourseMutation();
    const [deleteUnitCourse, deleteUnitCourseMutation] = useDeleteUnitCourseMutation();
    const checkUnitCourse = useCheckUnitCourseMatchQuery({variables: {unit: unitCourse_unit_Search, course: unitCourse_course_Search}});
    const isInsertUnitCourse = !((checkUnitCourse.data?.check?.length??0) > 0);
    const isUpdateUnitCourse = !isInsertUnitCourse && (checkUnitCourse.data?.check?.[0].matching_quality!==inputToNotNullInt(unitCourseScore));
    useEffect(() => {
        if (!checkUnitCourse.loading && checkUnitCourse?.data?.check !== undefined && checkUnitCourse?.data?.check?.length > 0) {
            setUnitCourseScore((checkUnitCourse.data?.check?.[0].matching_quality+'')??'');
        }
        // console.log('checkUnitCourse', checkUnitCourse.data?.check)
    }, [checkUnitCourse.data?.check, checkUnitCourse.data?.check?.length, checkUnitCourse.loading])
    const handleUpdateUnitCourse = async (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (isUpdateUnitCourse) {
                await updateUnitCourse({variables: {unit: unitCourse_unit_Search, course: unitCourse_course_Search, matching_quality: inputToNotNullInt(unitCourseScore)}});
                console.log('data updated', updateUnitCourseMutation.data);
                toast.success('UnitCourse updated: \nUnit:'+(unitCourse_unit_Search)+' \nCourse:'+unitCourse_course_Search+' \nmatching quality:'+unitCourseScore)
            }
            else if (isInsertUnitCourse){
                await insertUnitCourse({variables: {unit: unitCourse_unit_Search, course: unitCourse_course_Search, matching_quality: inputToNotNullInt(unitCourseScore)}});
                console.log('data inserted', insertUnitCourseMutation.data);
                toast.success('UnitCourse inserted: \nUnit:'+(unitCourse_unit_Search)+' \nCourse:'+unitCourse_course_Search+' \nmatching quality:'+unitCourseScore)
            }
        }
        catch (e){
            console.log('error', e)
            toast.error('Error: '+e)
        }
        // allUnitsCourses.refetch();
        checkUnitCourse.refetch();
    }
    const handleDeleteUnitCourse = async () => {
        try {
            await deleteUnitCourse({variables: {unit: unitCourse_unit_Search, course: unitCourse_course_Search}});
            console.log('data deleted', deleteUnitCourseMutation.data);
            toast.success('UnitCourse deleted: \nUnit:'+unitCourse_unit_Search+' \nCourse:'+unitCourse_course_Search)
        }
        catch (e) {
            console.log('error', e)
            toast.error('Error: '+e)
        }
        // allUnitsCourses.refetch();
        unitCourse_unit_options.refetch();
        unitCourse_course_options.refetch();
    }

    // LearningObjectivesCoursesMatches
    const [, setLearningObjCourse_learningObj] = useState('');
    const [, setLearningObjCourse_course] = useState('');
    const [learningObjCourse_learningObj_Search, setlearningObjCourse_learningObj_Search] = useState('');
    const [learningObjCourse_course_Search, setlearningObjCourse_course_Search] = useState('');
    const learningObjCourse_learningObj_options = useGetLearningObjectivesLikeQuery({variables: {expr: '%'+learningObjCourse_learningObj_Search+'%'}});
    const learningObjCourse_course_options = useGetCoursesLikeQuery({variables: {expr: '%'+learningObjCourse_course_Search+'%'}});
    const [learningObjCourseScore, setLearningObjCourseScore] = useState('0');
    const [insertLearningObjCourse, insertLearningObjCourseMutation] = useInsertLearningObjectiveCourseMutation();
    const [updateLearningObjCourse, updateLearningObjCourseMutation] = useUpdateLearningObjectiveCourseMutation();
    const [deleteLearningObjCourse, deleteLearningObjCourseMutation] = useDeleteLearningObjectiveCourseMutation();
    const checkLearningObjCourse = useCheckLearningObjectiveCourseMatchQuery({variables: {learning_objective: learningObjCourse_learningObj_Search, course: learningObjCourse_course_Search}});
    const isInsertLearningObjCourse = !((checkLearningObjCourse.data?.check?.length??0) > 0);
    const isUpdateLearningObjCourse = !isInsertLearningObjCourse && (checkLearningObjCourse.data?.check?.[0].score!==inputToNotNullInt(learningObjCourseScore));
    useEffect(() => {
        if (!checkLearningObjCourse.loading && checkLearningObjCourse?.data?.check !== undefined && checkLearningObjCourse?.data?.check?.length > 0) {
            setLearningObjCourseScore((checkLearningObjCourse.data?.check?.[0].score+'')??'');
        }
        // console.log('checkLearningObjCourse', checkLearningObjCourse.data?.check)
    }, [checkLearningObjCourse.data?.check, checkLearningObjCourse.data?.check?.length, checkLearningObjCourse.loading])
    const handleUpdateLearningObjCourse = async (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (isUpdateLearningObjCourse) {
                await updateLearningObjCourse({variables: {learning_objective: learningObjCourse_learningObj_Search, course: learningObjCourse_course_Search, score: inputToNotNullInt(learningObjCourseScore)}});
                console.log('data updated', updateLearningObjCourseMutation.data);
                toast.success('LearningObjCourse updated: \nLearning objective:'+(learningObjCourse_learningObj_Search)+' \nCourse:'+learningObjCourse_course_Search+' \nscore:'+learningObjCourseScore)
            }
            else if (isInsertLearningObjCourse){
                await insertLearningObjCourse({variables: {learning_objective: learningObjCourse_learningObj_Search, course: learningObjCourse_course_Search, score: inputToNotNullInt(learningObjCourseScore)}});
                console.log('data inserted', insertLearningObjCourseMutation.data);
                toast.success('LearningObjCourse inserted: \nLearning objective:'+(learningObjCourse_learningObj_Search)+' \nCourse:'+learningObjCourse_course_Search+' \nscore:'+learningObjCourseScore)
            }
        }
        catch (e){
            console.log('error', e)
            toast.error('Error: '+e)
        }
        // allLearningObjectivesCoursesMatches.refetch();
        checkLearningObjCourse.refetch();
    }
    const handleDeleteLearningObjCourse = async () => {
        try {
            await deleteLearningObjCourse({variables: {learning_objective: learningObjCourse_learningObj_Search, course: learningObjCourse_course_Search}});
            console.log('data deleted', deleteLearningObjCourseMutation.data);
            toast.success('LearningObjCourse deleted: \nLearning objective:'+learningObjCourse_learningObj_Search+' \nCourse:'+learningObjCourse_course_Search)
        }
        catch (e) {
            console.log('error', e)
            toast.error('Error: '+e)
        }
        // allLearningObjectivesCoursesMatches.refetch();
        learningObjCourse_learningObj_options.refetch();
        learningObjCourse_course_options.refetch();
    }

    
    


    if( userQuery.data?.user?.users_user_data?.admin === true) {
        return (
        <div className="absolute top-0 flex mx-auto w-full flex-row gap-5 h-screen overflow-hidden p-5 pt-24">

            <div className={(showPreview?'w-1/2 ':'w-full max-w-5xl ')+' flex flex-col gap-5 p-5 overflow-auto'}>
                {/* Tabs for each table */}
                
                {/* Locations */}
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <span>Locations</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={handleUpdateLocation} className='gap-5 p-5 flex flex-col w-full'>
                        <span className="text-custom-black text-base font-medium leading-snug">Location</span>
                        <div className="flex flex-row gap-5 items-center ">
                            <Autocomplete
                                className='grow'
                                id="location name"
                                freeSolo
                                options={location_name_options.data?.locations?.map(v=>v.name)||[]}
                                loading={location_name_options.loading}
                                onChange={(event, newValue) => {setLocation_name(newValue??'');setlocation_name_Search(newValue??'')}}
                                renderInput={(params) =>
                                    <TextField {...params} label="Location name"
                                    required
                                    className='text-sm'
                                    sx={{fontSize: '10px'}}
                                            placeholder="Location name"
                                            value={location_name_Search}
                                            onChange={(e)=>{setlocation_name_Search((e.target as HTMLTextAreaElement).value); console.log((e.target as HTMLTextAreaElement).value)}} 
                                    />}
                                
                                />
                                <Button variant='naked' onClick={handleDeleteLocation} startIcon={<DeleteIcon/>} />
                        
                        </div>
                        <Input label='Address' value={location_address} onChange={(e:FormEvent)=>setLocation_address((e.target as HTMLTextAreaElement).value)} />
                        <Input label='Coordinates' placeholder="latitude, longitude" required value={location_coordinates} onChange={(e:FormEvent)=>setLocation_coordinates((e.target as HTMLTextAreaElement).value)} />
                        <Input label='URL' value={location_url} onChange={(e:FormEvent)=>setLocation_url((e.target as HTMLTextAreaElement).value)} />
                        <Autocomplete
                            id="location region"
                            options={regionOptions}
                            value={location_region}
                            onChange={(e,v)=>setLocation_region(v??'')}
                            renderInput={(params) => <TextField {...params} label="Region" />}
                        />
                        <Button type="submit" disabled={!(isInsertLocation||isUpdateLocation)} variant='contained'>{isUpdateLocation?"Update":(isInsertLocation?"Insert":"unchanged")}</Button>

                    </form>

                    </AccordionDetails>
                </Accordion>

                {/* Providers */}
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <span>Providers</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={handleUpdateProvider} className='gap-5 p-5 flex flex-col w-full'>
                            <span className="text-custom-black text-base font-medium leading-snug">Provider</span>
                            <div className="flex flex-row gap-5 items-center ">
                                <Autocomplete
                                    className='grow'
                                    id="provider name"
                                    freeSolo
                                    options={provider_name_options.data?.providers?.map(v=>v.name)||[]}
                                    loading={provider_name_options.loading}
                                    onChange={(event, newValue) => {setProvider_name(newValue??'');setprovider_name_Search(newValue??'')}}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Provider name"
                                        className='text-sm'
                                        sx={{fontSize: '10px'}}
                                                placeholder="Provider name"
                                                required
                                                value={provider_name_Search}
                                                onChange={(e)=>{setprovider_name_Search((e.target as HTMLTextAreaElement).value); console.log((e.target as HTMLTextAreaElement).value)}} 
                                        />}
                                    
                                    />
                                    <Button variant='naked' onClick={handleDeleteProvider} startIcon={<DeleteIcon/>} />
                            
                            </div>
                            <Input label='URL' value={provider_url} onChange={(e:FormEvent)=>setProvider_url((e.target as HTMLTextAreaElement).value)} />
                            <BooleanCheckbox label="APAR listed" value={provider_apar} onChange={(e:boolean|null)=>setProvider_apar(e)} />
                            <BooleanCheckbox label="AEB Contract Holder" value={provider_aeb} onChange={(e:boolean|null)=>setProvider_aeb(e)} />
                            <Autocomplete
                                value={provider_ofsted}
                                options={ofstedRatingOptions}
                                id="ofsted"
                                onChange={(e,v)=>setProvider_ofsted(v??'')}
                                renderInput={(params) => <TextField {...params} label="Ofsted rating" />}
                            />                        
                            <Button type="submit" disabled={!(isInsertProvider||isUpdateProvider)} variant='contained'>{isUpdateProvider?"Update":(isInsertProvider?"Insert":"unchanged")}</Button>
                        
                        </form>
                        
                    </AccordionDetails>

                </Accordion>
                {/* Courses */}
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <span>Courses</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={handleUpdateCourse} className='gap-5 p-5 flex flex-col w-full'>
                            <span className="text-custom-black text-base font-medium leading-snug">Course</span>
                            <div className="flex flex-row gap-5 items-center ">
                                <Autocomplete
                                    className='grow'
                                    id="course name"
                                    freeSolo
                                    options={course_name_options.data?.courses?.map(v=>v.title)||[]}
                                    loading={course_name_options.loading}
                                    onChange={(event, newValue) => {setCourse_name(newValue??''); setcourse_name_Search(newValue??'')}}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Course name"
                                        className='text-sm'
                                        required
                                        sx={{fontSize: '10px'}}
                                                placeholder="Course name"
                                                value={course_name_Search}
                                                onChange={(e)=>{setcourse_name_Search((e.target as HTMLTextAreaElement).value); console.log((e.target as HTMLTextAreaElement).value)}} 
                                        />}
                                    
                                    />
                                    <Button variant='naked' onClick={handleDeleteCourse} startIcon={<DeleteIcon/>} />
                            </div>
                            <div className="flex flex-row gap-5">
                                <Input label='Type' value={course_type} placeholder="Long course or Short course" onChange={(e:FormEvent)=>setCourse_type((e.target as HTMLTextAreaElement).value)} />
                                <Input label='Qualification level' value={course_qualification_level} onChange={(e:FormEvent)=>setCourse_qualification_level((e.target as HTMLTextAreaElement).value)} />
                                <Input label="Guided Learning Hours " value={course_glh} onChange={(e:FormEvent)=>setCourse_glh((e.target as HTMLTextAreaElement).value)} />
                            </div>
                            {/* location selection */}
                            <Autocomplete
                                className='grow'
                                id="course location name"
                                freeSolo
                                options={course_location_options.data?.locations?.map(v=>v.name)||[]}
                                loading={course_location_options.loading}
                                onChange={(event, newValue) => {setCourse_location(newValue??'');setCourse_location_Search(newValue??'')}}
                                value={course_location_Search}
                                renderInput={(params) =>
                                    <TextField {...params} label="Location name"
                                    className='text-sm'
                                    required
                                    sx={{fontSize: '10px'}}
                                            placeholder="Location name"
                                            value={course_location_Search}
                                            onChange={(e)=>{setCourse_location_Search((e.target as HTMLTextAreaElement).value); console.log((e.target as HTMLTextAreaElement).value)}} 
                                    />}
                                    />
                            {/* provider selection */}
                            <Autocomplete
                                className='grow'
                                id="course provider name"
                                freeSolo
                                options={course_provider_options.data?.providers?.map(v=>v.name)||[]}
                                loading={course_provider_options.loading}
                                onChange={(event, newValue) => {setCourse_provider(newValue??'');setCourse_provider_Search(newValue??'')}}
                                value={course_provider_Search}
                                renderInput={(params) =>
                                    <TextField {...params} label="Provider name"
                                    className='text-sm'
                                    required
                                    sx={{fontSize: '10px'}}
                                            placeholder="Provider name"
                                            value={course_provider_Search}
                                            onChange={(e)=>{setCourse_provider_Search((e.target as HTMLTextAreaElement).value); console.log((e.target as HTMLTextAreaElement).value)}} 
                                    />}
                                    />
                            <TextAreaInput rows={12} label='Summary' value={course_summary} onChange={(e:FormEvent)=>setCourse_summary((e.target as HTMLTextAreaElement).value)} />
                            <TextAreaInput rows={12} label='Description' value={course_description} onChange={(e:FormEvent)=>setCourse_description((e.target as HTMLTextAreaElement).value)} />
                            <Input label='URL' value={course_url} onChange={(e:FormEvent)=>setCourse_url((e.target as HTMLTextAreaElement).value)} />
                            <Button type="submit" disabled={!(isInsertCourse||isUpdateCourse)} variant='contained'>{isUpdateCourse?"Update":(isInsertCourse?"Insert":"unchanged")}</Button>
                            
                        </form>
                    </AccordionDetails>
                </Accordion>

                {/* Units */}
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <span>Units</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={handleUpdateUnit} className='gap-5 p-5 flex flex-col w-full'>
                            <span className="text-custom-black text-base font-medium leading-snug">Unit</span>
                            <div className="flex flex-row gap-5 items-center ">
                                <Autocomplete
                                    className='grow'
                                    id="unit name"
                                    freeSolo
                                    options={unit_name_options.data?.units?.map(v=>v.name)||[]}
                                    loading={unit_name_options.loading}
                                    onChange={(event, newValue) => {setUnit_name(newValue??'');setunit_name_Search(newValue??'');}}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Unit name"
                                        className='text-sm'
                                        required
                                        sx={{fontSize: '10px'}}
                                                placeholder="Unit name"
                                                value={unit_name_Search}
                                                onChange={(e)=>{setunit_name_Search((e.target as HTMLTextAreaElement).value); console.log((e.target as HTMLTextAreaElement).value)}} 
                                        />}
                                    
                                    />
                                    <Button variant='naked' onClick={handleDeleteUnit} startIcon={<DeleteIcon/>} />
                            </div>
                            <div className="flex flex-row gap-5 items-center ">
                                <Autocomplete
                                    className='grow'
                                    id="topic name"
                                    freeSolo
                                    options={unit_topic_options.data?.units?.map(v=>v.topic)||[]}
                                    loading={unit_topic_options.loading}
                                    onChange={(event, newValue) => {setUnit_topic_Search(newValue??'')}}
                                    value={unit_topic_Search}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Topic name"
                                        className='text-sm'
                                        required
                                        sx={{fontSize: '10px'}}
                                                placeholder="Topic name"
                                                value={unit_topic_Search}
                                                onChange={(e)=>{setUnit_topic_Search((e.target as HTMLTextAreaElement).value); console.log((e.target as HTMLTextAreaElement).value)}} 
                                        />}
                                    
                                    />
                            </div>
                            <Tooltip title="You can put text between '--' to make it bold" placement="right">
                                <TextAreaInput rows={12} label='Specification' value={unit_specification} onChange={(e:FormEvent)=>setUnit_specification((e.target as HTMLTextAreaElement).value)} />
                            </Tooltip>  
                            <Button variant='outlined' onClick={()=>{setShowPreview(!showPreview)}}>{showPreview?'Close':'Open'} preview</Button>
                            

                            <Button type="submit" disabled={!(isInsertUnit||isUpdateUnit)} variant='contained'>{isUpdateUnit?"Update":(isInsertUnit?"Insert":"unchanged")}</Button>
                        </form>
                        
                    </AccordionDetails>
                </Accordion>

                {/* Learning objectives TO REDO */}
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <span>Learning objectives</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={learningObjMultiMode?handleInsertMultipleLearningObj:handleInsertLearningObj} className='gap-5 p-5 flex flex-col w-full'>
                            <div className="flex flex-row justify-between items-center">
                                <span className="text-custom-black text-base font-medium leading-snug">Learning objective{learningObjMultiMode?' (paste multiple rows)':'(edit/insert/delete row)'}</span>
                                <Button variant='outlined' onClick={()=>setLearningObjMultiMode(!learningObjMultiMode)}>{learningObjMultiMode?'Switch to single mode':'Switch to multi-mode'}</Button>
                            </div>
                        
                            <div className="flex flex-row gap-5 items-center ">
                            <Autocomplete
                                id="learning obj unit"
                                className='grow'
                                options={learningObj_unit_options.data?.units?.map(v=>v.name)||[]}
                                loading={learningObj_unit_options.loading}
                                // getOptionLabel={(option) => (option.name+' ('+option.capability.topic+' > '+option.capability.name+')')}
                                onChange={(event, newValue) => {setLearningObj_unit(newValue??'');}}
                                value={learningObj_unit}
                                // onChange={(event, newValue) => {setLearningObj_unit(newValue?.name??'');}}
                                style={{ width: 300 }}
                                renderInput={(params) => 
                                    <TextField {...params} label="Unit"
                                            required
                                            value={learningObj_unit_Search} 
                                            onChange={(e)=>setlearningObj_unit_Search((e.target as HTMLTextAreaElement).value)} />}
                            />
                            </div>

                            {learningObjMultiMode?
                                <TextAreaInput rows={12} placeholder="Learning Objectives names (row by row)" value={learningObj_multiple} onChange={(e:FormEvent)=>setLearningObj_multiple((e.target as HTMLTextAreaElement).value)}/>
                            :<div className="flex flex-row gap-5 items-center ">
                                <Autocomplete
                                    className='grow'
                                    id="learning obj name"
                                    // value={learningObj_name}//maybe
                                    freeSolo
                                    options={learningObj_name_options.data?.learning_objectives?.map(v=>v.name)||[]}
                                    loading={learningObj_name_options.loading}
                                    onChange={(event, newValue) => {setLearningObj_name(newValue??'');setlearningObj_name_Search(newValue??'');}}
                                    renderInput={(params) => 
                                        <TextField {...params} label="Learning objective name"
                                        className='text-sm'
                                        required
                                        sx={{fontSize: '10px'}}
                                                placeholder="Learning objective name"
                                                value={learningObj_name_Search}
                                                onChange={(e)=>setlearningObj_name_Search((e.target as HTMLTextAreaElement).value)} />}
                                    />
                                <Button variant='naked' onClick={handleDeleteLearningObj} startIcon={<DeleteIcon/>} />

                            </div>
                            

                            }

                            <div className='text-sm text-gray-500 flex flex-col'>
                                    <span className='font-bold'>Preview</span>
                                    <span>Learning objective: {(learningObj_name_Search)}</span>
                                    <span>Unit: {learningObj_unit} </span>
                            </div>
                            <Button type="submit" disabled={!(isInsertLearningObjective||isUpdateLearningObjective)} variant='contained'>{isUpdateLearningObjective?"Update":(isInsertLearningObjective?"Insert":"unchanged")}</Button>
                        </form>
                    </AccordionDetails>
                </Accordion>

                {/* Capabilities */}
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <span>Capabilities</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={capabilityMultiMode?handleInsertMultipleCapability:handleUpdateCapability} className='gap-5 p-5 flex flex-col w-full'>
                            <div className="flex flex-row justify-between items-center">
                                <span className="text-custom-black text-base font-medium leading-snug">Capability{capabilityMultiMode?' (paste multiple rows)':'(edit/insert/delete row)'}</span>
                                <Button variant='outlined' onClick={()=>setCapabilityMultiMode(!capabilityMultiMode)}>{capabilityMultiMode?'Switch to single mode':'Switch to multi-mode'}</Button>
                            </div>
                            {capabilityMultiMode?
                                <TextAreaInput rows={12} placeholder="Capabilities names (row by row)" value={capability_multiple} onChange={(e:FormEvent)=>setCapability_multiple((e.target as HTMLTextAreaElement).value)}/>
                                :
                            <div className="flex flex-row gap-5 items-center ">
                                <Autocomplete
                                    className='grow'
                                    id="capability name"
                                    freeSolo
                                    options={capability_name_options.data?.capabilities?.map(v=>v.name)||[]}
                                    loading={capability_name_options.loading}
                                    onChange={(event, newValue) => {setCapability_name(newValue??'');setcapability_name_Search(newValue??'')}}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Capability name"
                                        className='text-sm'
                                        required
                                        sx={{fontSize: '10px'}}
                                                placeholder="Capability name"
                                                value={capability_name_Search}
                                                onChange={(e)=>{setcapability_name_Search((e.target as HTMLTextAreaElement).value); console.log((e.target as HTMLTextAreaElement).value)}} 
                                        />}
                                    
                                    />
                                    <Button variant='naked' onClick={handleDeleteCapability} startIcon={<DeleteIcon/>} />
                                
                            </div>}

                            <div className="flex flex-row gap-5 items-center ">
                                <Autocomplete
                                    className='grow'
                                    id="Unit name"
                                    freeSolo
                                    // value={capability_topic}
                                    options={capabilityUnit_options.data?.units?.map(v=>v.name)||[]}
                                    loading={capabilityUnit_options.loading}
                                    onChange={(event, newValue) => {setCapability_unit(newValue??'');setCapabilityUnit_Search(newValue??'') }}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Unit name"
                                        className='text-sm'
                                        required
                                        sx={{fontSize: '10px'}}
                                                placeholder="Unit name"
                                                value={capabilityUnit_Search}
                                                onChange={(e)=>{setCapabilityUnit_Search((e.target as HTMLTextAreaElement).value); console.log((e.target as HTMLTextAreaElement).value)}} 
                                        />}
                                    
                                    />
                            </div>
                            <Button type="submit" disabled={!(isInsertCapability||isUpdateCapability)} variant='contained'>{isUpdateCapability?"Update":(isInsertCapability?"Insert":"unchanged")}</Button>

                        </form>
                    </AccordionDetails>
                </Accordion>

                {/* Unit/Courses Matches */}
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <span>Unit / Course Match</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={handleUpdateUnitCourse} className='gap-5 p-5 flex flex-col w-full'>
                            <span className="text-custom-black text-base font-medium leading-snug">Unit/Course Match</span>
                            <Autocomplete
                                className='grow'
                                id="unit name"
                                freeSolo
                                options={unitCourse_unit_options.data?.units?.map(v=>v.name)||[]}
                                loading={unitCourse_unit_options.loading}
                                onChange={(event, newValue) => {setUnitCourse_unit(newValue??'');setunitCourse_unit_Search(newValue??'')}}
                                renderInput={(params) =>
                                    <TextField {...params} label="Unit name"
                                    className='text-sm'
                                    required
                                    sx={{fontSize: '10px'}}
                                            placeholder="Unit name"
                                            value={unitCourse_unit_Search}
                                            onChange={(e)=>{setunitCourse_unit_Search((e.target as HTMLTextAreaElement).value); console.log((e.target as HTMLTextAreaElement).value)}} 
                                    />}
                            />
                            <Autocomplete
                                className='grow'
                                id="course name"
                                freeSolo
                                options={unitCourse_course_options.data?.courses?.map(v=>v.title)||[]}
                                loading={unitCourse_course_options.loading}
                                onChange={(event, newValue) => {setUnitCourse_course(newValue??'');setunitCourse_course_Search(newValue??'')}}
                                renderInput={(params) =>
                                    <TextField {...params} label="Course name"
                                    className='text-sm'
                                    required
                                    sx={{fontSize: '10px'}}
                                            placeholder="Course name"
                                            value={unitCourse_course_Search}
                                            onChange={(e)=>{setunitCourse_course_Search((e.target as HTMLTextAreaElement).value); console.log((e.target as HTMLTextAreaElement).value)}} 
                                    />}
                            />

                            <div className="flex flex-row gap-5 items-center">
                                <Input label='Matching quality [0...1]' placeholder="between 0 and 1" value={unitCourseScore} onChange={(e:FormEvent)=>setUnitCourseScore((e.target as HTMLTextAreaElement).value)} />
                                <Button variant='naked' onClick={handleDeleteUnitCourse} startIcon={<DeleteIcon/>} />
                            </div>
                            <Button type="submit" disabled={!(isInsertUnitCourse||isUpdateUnitCourse)} variant='contained'>{isUpdateUnitCourse?"Update":(isInsertUnitCourse?"Insert":"unchanged")}</Button>
                        </form>

                    </AccordionDetails>
                </Accordion>


                {/* LearningObjectives/Courses Matches */}
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <span>LO / Course Match</span>
                    </AccordionSummary>
                    <AccordionDetails>

                        <form onSubmit={handleUpdateLearningObjCourse} className='gap-5 p-5 flex flex-col w-full'>
                            <span className="text-custom-black tesxt-base font-medium leading-snug">Learning objective/Course Match</span>
                            <Autocomplete
                                className='grow'
                                id="learning objective name"
                                freeSolo
                                options={learningObjCourse_learningObj_options.data?.learning_objectives?.map(v=>v.name)||[]}
                                loading={learningObjCourse_learningObj_options.loading}
                                onChange={(event, newValue) => {setLearningObjCourse_learningObj(newValue??'');setlearningObjCourse_learningObj_Search(newValue??'')}}
                                renderInput={(params) =>
                                    <TextField {...params} label="Learning objective name"
                                    className='text-sm'
                                    required
                                    sx={{fontSize: '10px'}}
                                            placeholder="Learning objective name"
                                            value={learningObjCourse_learningObj_Search}
                                            onChange={(e)=>{setlearningObjCourse_learningObj_Search((e.target as HTMLTextAreaElement).value); console.log((e.target as HTMLTextAreaElement).value)}} 
                                    />}
                            />
                            <Autocomplete
                                className='grow'
                                id="course name"
                                freeSolo
                                options={learningObjCourse_course_options.data?.courses?.map(v=>v.title)||[]}
                                loading={learningObjCourse_course_options.loading}
                                onChange={(event, newValue) => {setLearningObjCourse_course(newValue??'');setlearningObjCourse_course_Search(newValue??'')}}
                                renderInput={(params) =>
                                    <TextField {...params} label="Course name"
                                    className='text-sm'
                                    required
                                    sx={{fontSize: '10px'}}
                                            placeholder="Course name"
                                            value={learningObjCourse_course_Search}
                                            onChange={(e)=>{setlearningObjCourse_course_Search((e.target as HTMLTextAreaElement).value); console.log((e.target as HTMLTextAreaElement).value)}} 
                                    />}
                            />
                            <div className="flex flex-row gap-5 items-center">
                                <Input label='Score [0...1]' placeholder="between 0 and 1" value={learningObjCourseScore} onChange={(e:FormEvent)=>setLearningObjCourseScore((e.target as HTMLTextAreaElement).value)} />
                                <Button variant='naked' onClick={handleDeleteLearningObjCourse} startIcon={<DeleteIcon/>} />
                            </div>
                            <Button type="submit" disabled={!(isInsertLearningObjCourse||isUpdateLearningObjCourse)} variant='contained'>{isUpdateLearningObjCourse?"Update":(isInsertLearningObjCourse?"Insert":"unchanged")}</Button>
                        </form>
                    </AccordionDetails>
                </Accordion>

                {/* FAQ */}
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <span>FAQ</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={handleUpdateFaq} className='gap-5 p-5 flex flex-col w-full'>
                            <span className="text-custom-black tesxt-base font-medium leading-snug">FAQ</span>
                            <div className="flex flex-row gap-5 items-center ">

                                <Autocomplete
                                    className='grow'
                                    id="faq question"
                                    freeSolo
                                    options={faq_question_options.data?.faq?.map(v=>v.question)||[]}
                                    loading={faq_question_options.loading}
                                    onChange={(event, newValue) => {setFaq_question(newValue??'');setFaq_question_Search(newValue??'')}}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Question"
                                        className='text-sm'
                                        required
                                        sx={{fontSize: '10px'}}
                                                placeholder="Question"
                                                value={faq_question_Search}
                                                onChange={(e)=>{setFaq_question_Search((e.target as HTMLTextAreaElement).value); console.log((e.target as HTMLTextAreaElement).value)}} 
                                                />}/>
                                <Button variant='naked' onClick={handleDeleteFaq} startIcon={<DeleteIcon/>} />
                            
                            </div>
                            <Input label='Answer' value={faq_answer} onChange={(e:FormEvent)=>setFaq_answer((e.target as HTMLTextAreaElement).value)} />
                            <Input label="Order"  value={parseInt(faq_order)||0}  onChange={(e:FormEvent)=>setFaq_order((e.target as HTMLTextAreaElement).value) } />
                            
                            <Button type="submit" disabled={!(isInsertFaq||isUpdateFaq)} variant='contained'>{isUpdateFaq?"Update":(isInsertFaq?"Insert":"unchanged")}</Button>
                        </form>
                    </AccordionDetails>
                </Accordion>
            </div>
            {/* <DBDisplay ref={dbDisplayRef}/> */}

            {/* Unit preview */}
            <div className={showPreview?'visible pl-5':'hidden'}>
                <div className="absolute flex flex-col left-1/2 w-[calc(50%-20px)] h-screen rounded-lg p-5 pb-48 bg-white shadow-lg overflow-scroll">
                    {/* <div className=""> */}
                        {format(unit_specification)}

                    {/* </div> */}
                </div>
                
            </div>
        </div>

        )
    }
    else {
        return <div> You're not allowed to access this page</div> 
    }

}

export default AdminDashboard;