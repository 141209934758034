import React, {useEffect, useState} from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import toast from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetAllLocationsQueryResult } from '../../generated/graphql';
import { useDeleteLocationMutation } from '../../generated/graphql';
import { simplifyUrl } from './CoursesTable';
type LocationsTableProps = {
    onUpdate: () => void;
    Query: GetAllLocationsQueryResult;
}

const LocationsTable = ({onUpdate, Query}: LocationsTableProps) => {
  const allLocations = Query;

    const [deleteLocation, setDeleteLocation] = useState('');
    const [deleteLocationMutation,] = useDeleteLocationMutation();

    useEffect(() => {
        if (deleteLocation !== ''){
            try{
                deleteLocationMutation({variables: {name: deleteLocation}}).then((res)=>{
                    onUpdate();
                    toast.success('Location "'+deleteLocation+'" deleted')})
            } catch (e) {
                console.log('error', e)
                toast.error('Error deleting location "'+deleteLocation+'"')
            }

            setDeleteLocation('');
        }
    }, [deleteLocation, deleteLocationMutation ])
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>  
                <span className="text-base font-bold">Locations ({allLocations.data?.locations_aggregate.aggregate?.count})</span>
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Coordinates</TableCell>
                            <TableCell>URL</TableCell>
                            <TableCell>Region</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allLocations.data?.locations?.map((v,i) => (
                            <TableRow key={i}>
                                <TableCell>{v.name}</TableCell>
                                <TableCell>{v.address}</TableCell>
                                <TableCell>{v.lat}, {v.lng}</TableCell>
                                <TableCell>{simplifyUrl(v.url||'')}</TableCell>
                                <TableCell>{v.region}</TableCell>
                                <TableCell>
                                    <Button 
                                    variant='naked' 
                                    onClick={()=>setDeleteLocation(v.name)} startIcon={<DeleteIcon/>} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </AccordionDetails>
        </Accordion>  )
}

export default LocationsTable