import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material'
import React, { ReactNode, useEffect, useState } from 'react'
// import Placeholder from '../assets/img-placeholders.png'
import LandingImage from '../assets/Landing page image.png'
// import SupportEmailLink from '../components/SupportEmailLink'
import ODTLogo from '../assets/odt.png'
import EnginuityLogo from '../assets/Enginuity-Logo-1536x336.png'
import WMCALogo from '../assets/wmca.png'
import DBTLogo from '../assets/DBT_White.png'
import NAALogo from '../assets/NAA-Logo-Nov15-Secondary-RGB-Transparent-Small.png'
import AutocouncilLogo from '../assets/AutocouncilLogo.png'
import NEAALogo from '../assets/NEAALogo.png'
import ReactPlayer from 'react-player'
// import Video from '../assets/A'
// import { position } from 'html2canvas/dist/types/css/property-descriptors/position'
import { APP_TITLE, license_terms_url, privacyPolicyURL } from 'globalVariables'
import { useNavigate } from 'react-router-dom';
import FAQComponent from '../components/FAQComponent'
import { useAuthenticationStatus } from '@nhost/react'

const Home = () => {
  const { isAuthenticated, } = useAuthenticationStatus();
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const [top, setTop] = useState(true);

  useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  const navigate = useNavigate();
  return (
    <div className='flex flex-col w-100 h- min-h-screen  items-stretch scroll-smooth'>

      {/* responsive navbar */}
      <nav className={("flex flex-col w-full px-5 py-3 bg-white  fixed h-18 lg:px-16 z-40 " + (!top && "drop-shadow-md shadow-gray-100 "))}>
        <div className="flex flex-row items-center justify-between w-full">
          <Button onClick={() => navigate('/')} variant='text'>
            <h1 className="title flex flex-col text-left pl-5 pt-3 pb-4 gap-0 text-brand">
              <span className="text-lg font-semibold">{APP_TITLE}</span>
            </h1>
          </Button>



          {/* desktop */}
          <div className=" flex-row gap-5 xl:gap-8 items-center lg:flex hidden">
            <a href="#video" className="hover:font-semibold">Video Demo</a>
            <a href="#partners" className="hover:font-semibold">Partners</a>
            <a href="#faq" className="hover:font-semibold">FAQs</a>
            <div className="flex flex-row gap-4">
              {isAuthenticated?
                <Button variant='outlined'  onClick={() => navigate('/dashboard')}>Go to dashboard</Button>
              :<>
                <Button variant='outlined'  onClick={() => navigate('/dashboard')}>Log in</Button>
                <Button variant='contained' onClick={() => navigate('/sign-up')}>Sign Up</Button>
                </>
              }
            </div>
          </div>

          {/* hamburger */}
          <div className="lg:hidden">
            <button onClick={toggleMenu} className="block px-2 text-gray-500 hover:text-black focus:outline-none focus:text-black">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button>
          </div>
        </div>
        {/* mobile */}
        <div className={`lg:hidden ${isOpen ? 'block' : 'hidden'}`}>
          <div className="flex flex-row gap-4 justify-between px-5 py-4 items-center">
            <div className="flex flex-col min-[500px]:flex-row gap-4 items-start">
              <a href="#video" className="hover:font-semibold">Video Demo</a>
              <a href="#partners" className="hover:font-semibold">Partners</a>
              <a href="#faq" className="hover:font-semibold">FAQs</a>

            </div>
            <div className="flex flex-col gap-4 min-[580px]:flex-row ">
            {isAuthenticated?
                <Button variant='outlined'  onClick={() => navigate('/dashboard')}>Go to dashboard</Button>
              :<>
                <Button variant='outlined'  onClick={() => navigate('/dashboard')}>Log in</Button>
                <Button variant='contained' onClick={() => navigate('/sign-up')}>Sign Up</Button>
                </>
              }
            </div>
          </div>
        </div>
      </nav>
      <div className="flex flex-col max-w-[1440px] mx-auto items-center mt-16">
        <div id="hero" className="grid w-full items-center
                          grid-cols-1 gap-12 px-5 py-16
                          md:grid-cols-2 md:gap-20 md:px-16 md:pt-28 md:pb-20 ">
          <div className="flex flex-col gap-5 ">
            <h2 className="text-[56px] font-bold leading-[67px]">
              Welcome to the Automotive Reskilling and Upskilling Platform
            </h2>
            <p className="text-base font-normal">
              The place to go to start exploring digitalisation and electrification skills training. Use the platform to help upskill your workforce or to access nationally relevant learning content to tailor your training provision.
            </p>
            <div className="flex flex-row justify-start gap-4">
              {isAuthenticated?
                <Button variant='outlined'  onClick={() => navigate('/dashboard')}>Go to dashboard</Button>
              :<>
                <Button variant='outlined'  onClick={() => navigate('/dashboard')}>Log in</Button>
                <Button variant='contained' onClick={() => navigate('/sign-up')}>Sign Up</Button>
                </>
              }
            </div>
          </div>
          <img src={LandingImage} alt="hero" className="w-full h-auto" />
        </div>
        <div id="video" className="flex flex-col w-full align-center text-center gap-12 py-16 px-5 md:gap-20 md:pt-20 md:pb-20 bg-gray-100">
          <div className="flex flex-col gap-6 mx-auto max-w-4xl items-center">
            {/* <h2 className="text-5xl font-bold">Short headline about the benefit of using your product or service</h2> */}
            <p className="text-2xl">This intelligent online platform matches learning content, developed by employers working with the
              Workforce Foresighting Hub, with other employers' needs.</p><p className='text-2xl'>Watch
                this video to learn more about the concept behind this innovative online tool and the benefits to
                users.</p>
          
          </div>
          <div className="w-full md:w-3/4 mx-auto">
            <ReactPlayer url="VideoV2.mp4" controls={true} width="100%" height="100%" />
          </div>
          {/* <img src={Placeholder} alt="main feature" className="w-full h-auto" /> */}
        </div>
        <div id="partners" className="flex flex-col gap-6 w-full max-w-screen-lg px-5 items-center md:pt-28 md:pb-0">
          <h2 className="text-[18px] w-full text-center font-bold">Our Partners</h2>
          <div className="flex flex-col">
            <div className="flex flex-col lg:flex-row justify-between gap-7 items-center py-5 w-full max-w-screen-lg ">
              <img src={EnginuityLogo} alt="Enginuity" className="h-9" />
              <img src={WMCALogo} alt="WMCA" className="h-11" />
              <img src={DBTLogo} alt="DBT" className="h-24 bg-black" />

            </div>
            <div className="flex flex-col lg:flex-row justify-between gap-7 items-center py-5 w-full max-w-screen-lg ">
            
              <img src={NAALogo} alt="NAA" className="h-16" />
              <img src={AutocouncilLogo} alt="Autocouncil" className="h-14" />
              <img src={NEAALogo} alt="NEAA" className="h-16" />
          </div>
        </div>
        </div>

        <FAQComponent />

        <div id="footer" className="flex flex-col gap-5 text-center px-5 mt-16 w-full">
          <div className="border-t border-black py-6 flex flex-col items-start text-left gap-5 md:flex-row md:justify-between md:items-center">
            <a className="flex flex-col justify-start items-start gap-1" href="https://www.oxdatatech.com/" rel="noreferrer" target='_blank'>
              <span className="text-neutral-600 text-sm">Powered by:</span>
              <img src={ODTLogo} alt="ODT" className="" />
            </a>
            <div className="text-sm flex flex-col gap-1 md:flex-row">
              <span>© 2024 Automotive Upskilling & Reskilling Platform. </span>
              <span>
                All rights reserved.
              </span>
            </div>
            <div className="flex flex-row gap-8 text-sm ">
              <a href={license_terms_url} rel="noreferrer" target='_blank' className="hover:font-semibold">Terms</a>
              <a href={privacyPolicyURL} rel="noreferrer" target='_blank' className="hover:font-semibold">Privacy</a>
            </div>
          </div>
        </div>

      </div>

    </div>

  )
}

export default Home